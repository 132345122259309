import React, { FC } from "react";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import CreateTranslationView from "@/views/admin/translation/CreateTranslationView";
import ManageTranslationsView from "@/views/admin/translation/ManageTranslationsView";
import { Box } from "@mui/material";

export const TranslationView: FC = () => {
  //const { pathname } = useLocation();

  return (
    <Box height={"100%"}>
      <Switch>
        <Route path={"/create"} element={<CreateTranslationView />} />
        <Route path={"/"} element={<ManageTranslationsView />} />
      </Switch>
    </Box>
  );
};

export default TranslationView;
