/*
 * Form related utilities
 * */

import { JSONSchema7, JSONSchema7Definition } from "json-schema";
import { UiSchema, utils } from "@rjsf/core";
import { cloneElement, ReactElement } from "react";
import { getValue, isObject, objectFromPath } from "@/utils/obj";

export function canFieldGoToHeader(name: string, schema?: JSONSchema7Definition): boolean {
  if (
    typeof schema === "object" &&
    !Array.isArray(schema) &&
    schema.properties != null &&
    typeof schema.properties === "object" &&
    isObject(getValue(schema.properties, name))
  ) {
    const fieldSchema = getValue(schema.properties, name) as JSONSchema7;
    // enum-selects can go to in the header
    if (Array.isArray(fieldSchema["enum"])) return true;
    if (name == "content.properties.cellSize") return true;

    // todo: add other fields here if the widget is adapted to be displayed in the header
    //  (e.g. a color-picker, or ratio-selector)
  }

  return false;
}

/**
 * Splits an array of named elements into "header" (from uiSchema -> ui:settings) and "body".
 * If a field name is listed in "uiSchema['ui:settings']", it should be moved to "header". Otherwise "body".
 *
 * @param elements
 * @param schema
 * @param uiSchema
 */
export function splitHeaderAndBodyContent<T extends { name: string }>(
  elements: T[],
  schema?: JSONSchema7Definition,
  uiSchema?: UiSchema
): { header: T[]; body: T[] } {
  const fieldsToMoveToHeader = uiSchema?.["ui:settings"];

  return elements.reduce(
    (acc, v: T) => {
      if (
        Array.isArray(fieldsToMoveToHeader) &&
        fieldsToMoveToHeader.includes(v.name) &&
        canFieldGoToHeader(v.name, schema)
      ) {
        return {
          header: [
            ...acc.header,
            utils.mergeObjects(v, {
              content: { props: { uiSchema: { "ui:options": { isHeaderVariant: true } } } },
            }) as T,
          ],
          body: acc.body,
        };
      }
      return {
        header: acc.header,
        body: [...acc.body, v],
      };
    },
    { header: [] as T[], body: [] as T[] }
  );
}

/**
 * Takes in an object element (from ObjectField) and sets "ui:widget": "hidden" for the properties that
 * are displayed in the header.
 *
 * @see src/framework/KioForm/templates/array/SettingsFields.tsx
 */
export function hideSettingsWidgets(el: ReactElement): ReactElement {
  const { schema, uiSchema } = el.props;

  if (schema.type !== "object" || !isObject(schema.properties)) {
    return el;
  }

  if (!uiSchema?.["ui:settings"] || !Array.isArray(uiSchema["ui:settings"])) {
    return el;
  }

  const widgetsToHide = uiSchema["ui:settings"]
    .filter(
      (field) => canFieldGoToHeader(field, schema) || canFieldGoToHeader(field.split(".").join(".properties."), schema)
    )
    .reduce((acc, v) => {
      const [propName, ...restOfPath] = v.split(".");
      return {
        ...acc,
        [propName]: objectFromPath(restOfPath.concat("ui:widget"), "hidden"),
      };
    }, {} as { [f: string]: any });

  return cloneElement(el, {
    uiSchema: utils.mergeObjects(uiSchema, widgetsToHide),
  });
}

export function hasAnyErrors(errorSchema?: { [key: string]: any }): boolean {
  return (
    !!errorSchema &&
    Object.entries(errorSchema).some(([field, val]) => {
      if (isObject(val)) {
        return hasAnyErrors(val);
      }
      if (field === "__errors" && Array.isArray(val)) {
        return !!val.length;
      }
      return false;
    })
  );
}
