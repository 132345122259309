import { Box, Button, ButtonProps, styled } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

export interface FormButtonsProps extends Omit<BoxProps, "onSubmit"> {
  disableCancelButton?: boolean;
  disableSubmitButton?: boolean;
  onCancel?: (event: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (event: MouseEvent<HTMLButtonElement>) => void;
  cancelButtonLabel?: string;
  submitButtonLabel?: string;
  cancelButtonType?: ButtonProps["type"];
  submitButtonType?: ButtonProps["type"];
  cancelButtonVariant?: ButtonProps["variant"];
  submitButtonVariant?: ButtonProps["variant"];
  cancelButtonColor?: ButtonProps["color"];
  submitButtonColor?: ButtonProps["color"];
}

const ButtonContainer = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  width: fit-content;
  gap: 16px;
  margin: auto 0 0 auto;
`;

export const FormButtons: FC<FormButtonsProps> = ({
  children,
  disableCancelButton = false,
  disableSubmitButton = false,
  onCancel,
  onSubmit,
  cancelButtonLabel = "generic.cancel",
  submitButtonLabel = "generic.save",
  cancelButtonType = "button",
  submitButtonType = "submit",
  cancelButtonVariant = "text",
  submitButtonVariant = "outlined",
  cancelButtonColor = "inherit",
  submitButtonColor = "secondary",
  ...BoxProps
}) => {
  const { t } = useTranslation("common");
  return (
    <ButtonContainer {...BoxProps}>
      {children}
      {!!onCancel && (
        <Button
          type={cancelButtonType}
          color={cancelButtonColor}
          variant={cancelButtonVariant}
          onClick={onCancel}
          disabled={disableCancelButton}
        >
          {t(cancelButtonLabel)}
        </Button>
      )}
      {!!onSubmit && (
        <Button
          type={submitButtonType}
          color={submitButtonColor}
          variant={submitButtonVariant}
          onClick={onSubmit}
          disabled={disableSubmitButton}
        >
          {t(submitButtonLabel)}
        </Button>
      )}
    </ButtonContainer>
  );
};

export default FormButtons;
