import React, { FC, ReactNode } from "react";
import Layout from "./Layout";
import Header from "./Header";
import ApplicationBreadcrumbs from "./ApplicationBreadcrumbs";
import Styles from "../assets/styles/Styles";
import PersistentDrawer from "@/components/PersistentDrawer";

export interface MainLayoutProps {
  children?: React.ReactNode;
  menuContent?: ReactNode;
  hideBreadcrumbs?: boolean;
  menuOpen: boolean;
  setMenuOpen: (open: boolean | ((prev: boolean) => boolean)) => void;
}

export const MainLayout: FC<MainLayoutProps> = ({
  children,
  menuOpen,
  setMenuOpen,
  menuContent,
  hideBreadcrumbs = false,
}) => {
  return (
    <Layout header={<Header onToggleMenu={setMenuOpen} showMenuButton={!!menuContent} />}>
      <PersistentDrawer
        open={menuOpen && !!menuContent}
        width={Styles.Dimensions.MENU_WIDTH}
        drawerContent={menuContent}
      >
        {!hideBreadcrumbs && <ApplicationBreadcrumbs />}
        {children}
      </PersistentDrawer>
    </Layout>
  );
};

export default MainLayout;
