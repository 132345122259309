import React, { FC, useEffect, useState } from "react";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import { DataListProps } from "@/components/DataList/DataList";
import ApiKey from "@/declarations/models/ApiKey";
import { DataListRow } from "@/components/DataList/DataListRow";
import Api from "@/services/Api";
import CreateOrEditRoutes from "../CreateOrEditRoutes";
import { schema } from "@/declarations/schemas/apiKey/schema";
import { uiSchema } from "@/declarations/schemas/apiKey/uiSchema";
import SingleApiKeyModal from "./SingleApiKeyModal";
import { useTranslation } from "react-i18next";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { useDebounce } from "@/hooks/useDebounce";

const apiKeyToDataListRow = (apiKey: ApiKey): DataListRow => ({
  key: apiKey.key!,
  title: `${apiKey.name} (${apiKey.application_name})`,
  subTitle: apiKey.key,
  infoText: `ID: ${apiKey.id}`,
  updatedAt: apiKey.updated_at,
  updatedBy: apiKey.updated_by,
});

const deleteApiKey: DataListProps<ApiKey>["onDeleteItem"] = async (item: ApiKey) => {
  await Api.deleteApiKey(item.id || 0).fetch();
};

const undeleteApiKey = async (item: ApiKey) => {
  await Api.undeleteApiKey(item.id!).fetch();
};

export const ApiKeyView: FC = () => {
  const { t } = useTranslation("common");
  const [selectedApiKeyId, setSelectedApiKeyId] = useState<number | null>(null);

  const [sortBy, setSortBy] = useState<string>("name");
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [searchInput]);

  const getApiKeys: DataListProps<ApiKey>["getItems"] = (page, page_size) =>
    Api.getAllApiKeys({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
    }).fetchDirect({
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    });

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    if (sortProp && sortProp !== sortBy) setSortBy(sortProp);
    if (sortDirection) setSortAscending(sortDirection === "asc");

    setLastFetchedTimestamp(Date.now());
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.API_KEY}`} />
      <CreateOrEditRoutes
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit
        createFormData={Api.createApiKey}
        updateFormData={Api.updateApiKey}
        getFormData={Api.getOneApiKey}
        listProps={{
          listTitle: t("schemaTypes.api-key_plural"),
          mapperFn: apiKeyToDataListRow,
          getItems: getApiKeys,
          onDeleteItem: deleteApiKey,
          onDeleteItemForever: deleteApiKey,
          onUndeleteItem: undeleteApiKey,
          onEditItem: (i) => setSelectedApiKeyId(i?.id || null),
          hideImage: true,
          defaultViewType: ViewType.LIST,
          disableViewTypeSelection: true,
          handleOnItemsChanged: handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
      <SingleApiKeyModal apiKeyId={selectedApiKeyId} onClose={() => setSelectedApiKeyId(null)} />
    </>
  );
};

export default ApiKeyView;
