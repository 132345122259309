import { ReactComponent as DM } from "@/assets/img/custom-icons/icon_dm.svg";
import { ReactComponent as Minne } from "@/assets/img/custom-icons/icon_minne.svg";
import { MuiIcon } from "@/components/MuiIcon";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import { CustomArrayFieldTemplateProps } from "@/framework/KioForm/templates/array/ArrayFieldTemplate";
import ArrayItemSettingsFields from "@/framework/KioForm/templates/array/ArrayItemSettingsFields";
import { useDraggables } from "@/hooks/useDraggables";
import { useLookup } from "@/hooks/useLookup";
import Settings from "@/Settings";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import styled from "@emotion/styled";
import {
  AccountBox,
  Anchor,
  AttachFile,
  Code,
  Description as DescriptionIcon,
  FormatQuote,
  Lightbulb,
  Link,
  MusicNote,
  People,
  PermMedia,
  Subject,
  ThreeDRotation,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { FC, ReactNode, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  > button {
    margin: 16px 16px 0 0;
    .MuiButton-label {
      text-transform: none;
      font-weight: 700;
    }
  }
`;

interface BlockButtonProps {
  label?: string;
  blockType?: string;
  icon?: string | ReactNode;
}

const getIconNameByBlockType = (blockType: string): BlockButtonProps["icon"] => {
  switch (blockType) {
    case "3d":
      return <ThreeDRotation />;
    case "rotating_object":
      return <ThreeDRotation />;
    case "article":
      return <Subject />;
    case "text":
      return <Subject />;
    case "widget":
      return <Code />;
    case "media":
      return <PermMedia />;
    case "all_media":
      return <PermMedia />;
    case "links":
      return <Link />;
    case "attachment":
      return <AttachFile />;
    case "quote":
      return <FormatQuote />;
    case "dm":
      return (
        <MuiIcon>
          <DM />
        </MuiIcon>
      );
    case "audio":
      return <MusicNote />;
    case "documents":
      return <DescriptionIcon />;
    case "anchor":
      return <Anchor />;
    case "tasks":
      return <Lightbulb />;
    case "byline":
      return <AccountBox />;
    case "minne":
      return (
        <MuiIcon>
          <Minne />
        </MuiIcon>
      );
    default:
      return <People />;
  }
};

const BlocksArrayTemplate: FC<CustomArrayFieldTemplateProps> = ({
  registry,
  formData,
  items,
  canAdd,
  onAddClick,
  className,
}) => {
  const { t } = useTranslation("common");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemsExpandedState = useLookup<boolean>();
  const getPotentiallyLocalizedString = resolvePotentiallyLocalizedString(
    registry.formContext.selectedLocale || Settings.DEFAULT_LOCALE
  );

  useDraggables(containerRef, items);

  const buttons: BlockButtonProps[] = useMemo(() => {
    try {
      let _enum = registry?.definitions?.block?.properties?.blockType?.enum;
      let _enumNames = registry?.definitions?.block?.properties?.blockType?.enumNames;
      let _arr = [];
      for (let i = 0; i < _enum.length; i++) {
        _arr.push({
          label: _enumNames[i],
          blockType: _enum[i],
          icon: getIconNameByBlockType(_enum[i]),
        });
      }
      return _arr;
    } catch (err) {
      return [];
    }
  }, [registry]);

  const buttonClickHandler = (btn: BlockButtonProps) => {
    registry.definitions.block.properties.blockType.default = btn.blockType;
    onAddClick && onAddClick();
  };

  const getTitleFromFormData = (item: any): string => {
    const title = getPotentiallyLocalizedString(item.title) || "";
    const blockType = !!item.blockType ? t("blocks." + item.blockType) : "";
    if (!!blockType && !!title) return blockType + ": " + title;
    return blockType || title || "empty block title?";
  };

  const onDelete = (element: any) => {
    if (window.confirm(t("components.list.confirmDelete"))) {
      return element.onDropIndexClick(element.index)();
    }
  };

  const getTitleAddon = (formData: any) => {
    const items = Object.values(formData).find((k) => Array.isArray(k));
    return Array.isArray(items) ? `(${items.length})` : undefined;
  };

  return (
    <Container className={className + " blocks-array-template"} ref={containerRef}>
      {(items || []).map((element) => {
        return (
          <CollapseBar
            key={element.key}
            title={getTitleFromFormData(formData[element.index])}
            titleAddon={getTitleAddon(element.children?.props?.formData)}
            expanded={!!itemsExpandedState.getItem(element.key)}
            setExpanded={(e) => itemsExpandedState.setItem(element.key, e)}
            onDelete={() => onDelete(element)}
            onMoveUp={element.onReorderClick(element.index, element.index - 1)}
            onMoveDown={element.onReorderClick(element.index, element.index + 1)}
            enableDelete={element.hasRemove}
            enableMovable={element.hasMoveUp || element.hasMoveDown}
            moveUpButtonDisabled={!element.hasMoveUp}
            moveDownButtonDisabled={!element.hasMoveDown}
            settingsContent={<ArrayItemSettingsFields element={element.children} />}
          >
            {element.children}
          </CollapseBar>
        );
      })}
      {canAdd &&
        (buttons || []).map((btn: any) => (
          <Button
            title={btn.label}
            key={btn.label}
            color="secondary"
            variant="outlined"
            onClick={() => {
              buttonClickHandler(btn);
            }}
            startIcon={btn?.icon && <MuiIcon>{btn.icon}</MuiIcon>}
          >
            {t("blocks." + btn.blockType)}
          </Button>
        ))}
    </Container>
  );
};

export default BlocksArrayTemplate;
