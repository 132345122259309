import Styles from "@/assets/styles/Styles";
import LocaleSelectorButton from "@/components/LocaleSelectorButton";
import SplitButton from "@/components/SplitButton";
import TimeDiffLabel from "@/components/TimeDiffLabel";

import { Breadcrumb } from "@/declarations/Breadcrumb";
import DocumentStatus from "@/declarations/models/DocumentStatus";
import ApplicationBreadcrumbs from "@/framework/ApplicationBreadcrumbs";
import { useStore } from "@/Store";
import { ArrowBack, Reorder, VerticalSplit } from "@mui/icons-material";
import { AppBar, Box, Chip, Hidden, IconButton, styled, Tooltip } from "@mui/material";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const DROPDOWN_OPTIONS = ["generic.save_published", "generic.save_draft"];
const DROPDOWN_OPTIONS_UNLISTED = ["generic.save_published", "generic.save_published_not_listed", "generic.save_draft"];

export interface EditorHeaderProps {
  previewOpen: boolean;
  onPreviewOpenChanged: (open: boolean) => void;
  onSubmit: (selectedOption?: string) => void;
  formIsDirty?: boolean;
  currentStatus?: DocumentStatus;
  lastModified?: string | number | Date;
  lastModifiedBy?: string;
  setSelectedLocale: (locale: string) => void;
  selectedLocale: string;
  disablePreview?: boolean;
  modalEditor?: boolean;
  canPublishUnlisted?: boolean;
  alternativeAvailableLocales?: string[];
}

const Container = styled(AppBar)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: theme.spacing(Styles.Dimensions.HEADER_HEIGHT),
  display: "flex",
  boxShadow: theme.shadows[1],
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.paper,
}));

const StartContentWrapper = styled("span")({
  display: "flex",
  flexFlow: "row nowrap",
  maxWidth: "calc(100% - 556px)",
  alignItems: "center",
});

const EndContentWrapper = styled("span")({
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  gap: "8px",
  width: "392px",
  justifyContent: "flex-end",
  "& .MuiTypography-root": {
    justifyContent: "center",
  },
});

const StyledChip = styled(Chip)`
  max-width: 270px;
  min-width: 90px;
  font-weight: 700;
  > .MuiChip-label {
    padding: 0 4px;
  }
`;

export const EditorHeader: FC<EditorHeaderProps> = memo(
  ({
    previewOpen,
    onPreviewOpenChanged,
    onSubmit,
    formIsDirty = false,
    currentStatus,
    lastModified,
    lastModifiedBy,
    setSelectedLocale,
    selectedLocale,
    disablePreview = false,
    modalEditor = false,
    canPublishUnlisted = false,
    alternativeAvailableLocales = undefined,
  }) => {
    const { t } = useTranslation("common");
    const history = useNavigate();
    const location = useLocation();
    const { state } = useStore();
    const availableLocales = (state.cmsContextInstance?.available_locales || []).map((al) => al.id!);

    const setDisabledButtonValues = (): string[] => {
      if (!formIsDirty && !!currentStatus) return [`generic.save_${currentStatus}`];
      return [];
    };

    const historyGoBack = () => {
      const breadcrumbs: Array<Breadcrumb> = state.breadcrumbs || [];
      const parentBreadCrumb = [...breadcrumbs].slice(-2, -1).pop();

      if (location?.key) {
        history(-1);
      } else if (!!parentBreadCrumb) {
        history(parentBreadCrumb.path);
      } else {
        history("/dashboard");
      }
    };

    return (
      <Container position="relative">
        <StartContentWrapper>
          {!modalEditor && (
            <>
              <IconButton edge="start" color="primary" onClick={historyGoBack}>
                <ArrowBack />
              </IconButton>
              <Hidden xsDown>
                <ApplicationBreadcrumbs />
              </Hidden>
              <Box marginRight={"8px"} />
            </>
          )}
          <LocaleSelectorButton
            value={selectedLocale}
            onSelectionChanged={(locale) => locale != null && setSelectedLocale?.(locale.language_code)}
            filterAvailableLocales={alternativeAvailableLocales ?? availableLocales}
          />
        </StartContentWrapper>

        <EndContentWrapper>
          {!!currentStatus && <StyledChip variant="outlined" color="secondary" label={t(`status.${currentStatus}`)} />}

          <Box display={"flex"} maxWidth={"172px"} gap={"8px"}>
            {!!lastModified && (
              <TimeDiffLabel
                datetime={new Date(lastModified)}
                name={lastModifiedBy || ""}
                timePartColor="textPrimary"
              />
            )}

            {!disablePreview && (
              <Tooltip
                title={t(previewOpen ? "headerButtons.togglePreviewOff" : "headerButtons.togglePreviewOn") || ""}
                placement="bottom"
                arrow
              >
                <IconButton
                  color="primary"
                  onClick={() => onPreviewOpenChanged(!previewOpen)}
                  sx={{ padding: 0, margin: "8px 0" }}
                >
                  {previewOpen ? <Reorder /> : <VerticalSplit />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <SplitButton
            leftDisabled={!formIsDirty}
            label="generic.save"
            primaryClickHandler={onSubmit}
            dropdownClickHandler={onSubmit}
            rightDisabled={!currentStatus}
            options={canPublishUnlisted ? DROPDOWN_OPTIONS_UNLISTED : DROPDOWN_OPTIONS}
            disabledMenuValues={setDisabledButtonValues()}
          />
        </EndContentWrapper>
      </Container>
    );
  }
);

export default EditorHeader;
