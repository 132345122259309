import { ChangeEvent, FC } from "react";
import { FieldProps } from "@rjsf/core";
import KioTextField from "../common/KioTextField";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { KioFormContext } from "../KioForm";
import { getSchemaTitle } from "@/utils/schema";

const LocalizedStringField: FC<FieldProps> = ({
  schema,
  uiSchema,
  errorSchema,
  formData,
  name,
  onChange,
  formContext,
  required,
  disabled,
  readonly,
}) => {
  const { t } = useTranslation("common");
  const selectedLocale = formContext?.selectedLocale;
  const { variant }: KioFormContext = formContext || {};
  const label = getSchemaTitle(formContext, schema, uiSchema, formData);
  const description = uiSchema["ui:description"] || schema.description;

  const update = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    onChange({
      ...formData,
      [selectedLocale]: e.target.value,
    });
  };

  if (schema.type !== "object") {
    return (
      <Typography color="error">
        {t("kioForm.fields.LocalizedStringField.invalidSchemaType", { fieldType: "object" })}
      </Typography>
    );
  }

  return (
    <>
      {!!selectedLocale ? (
        <KioTextField
          variant={variant}
          label={label}
          description={description}
          id={`${name}:${selectedLocale}`}
          name={`${name}:${selectedLocale}`}
          error={!!errorSchema[name]}
          value={formData?.[selectedLocale] ?? ""}
          onChange={update}
          required={required}
          disabled={disabled}
          readonly={readonly}
          multiline={uiSchema?.["ui:widget"] === "textarea"}
        />
      ) : (
        <Typography color="error">{t("components.localizedStringField.missingLanguage")}</Typography>
      )}
    </>
  );
};

export default LocalizedStringField;
