import React, { createContext, FC, useCallback, useContext, useMemo, useReducer } from "react";
import KioUser from "./declarations/models/KioUser";
import pkg from "../package.json";
import { Breadcrumb } from "./declarations/Breadcrumb";
import ApplicationInstance from "./declarations/models/ApplicationInstance";
import { JSONSchema7 } from "json-schema";

/**
 * Defines the possible values for the application state
 */
interface ApplicationState {
  user: KioUser | null;
  appVersion: string;
  breadcrumbs: Array<Breadcrumb>;
  cmsContextInstance: ApplicationInstance | null;
  globalSchemaDefinitions: any;
}

/**
 * The different action-types that can be executed.
 * Internal use only.
 */
enum ActionType {
  SET_USER = "set_user",
  PUSH_BREADCRUMB = "push_breadcrumb",
  POP_BREADCRUMB = "pop_breadcrumb",
  SET_CMS_CONTEXT_INSTANCE = "set_cms_context_instance",
  SET_GLOBAL_SCHEMA_DEFINITIONS = "set_global_schema_definitions",
}

/**
 * Defines an action to execute
 * Internal use only.
 */
interface Action {
  type: ActionType;
  payload: any;
}

/**
 * All actual reducers that are available.
 * These methods should only be called from the "useReducer"-hook in "Store.Provider".
 * Internal use only.
 */
abstract class Reducers {
  /**
   * @param state
   * @param user
   */
  public static setUser(state: ApplicationState, user: KioUser): ApplicationState {
    return { ...state, user };
  }

  /**
   *
   * @param state
   * @param breadcrumb
   */
  public static pushBreadcrumb(state: ApplicationState, breadcrumb: Breadcrumb): ApplicationState {
    if (state.breadcrumbs.some((bc) => bc.path === breadcrumb.path && bc.label === breadcrumb.label)) {
      // Avoid pushing the same state twice (circular routing), or when hot reloading
      return state;
    }
    return { ...state, breadcrumbs: [...state.breadcrumbs, breadcrumb] };
  }

  /**
   *
   * @param state
   */
  public static popBreadcrumb(state: ApplicationState): ApplicationState {
    return { ...state, breadcrumbs: [...state.breadcrumbs.slice(0, -1)] };
  }

  /**
   *
   * @param state
   * @param instance
   */
  public static setCmsContextInstance(state: ApplicationState, instance: ApplicationInstance | null): ApplicationState {
    return { ...state, cmsContextInstance: instance };
  }

  /**
   *
   * @param state
   * @param globalSchemaDefinitions
   */
  public static setGlobalSchemaDefinitions(state: ApplicationState, schema: JSONSchema7): ApplicationState {
    return { ...state, globalSchemaDefinitions: schema };
  }
}

/**
 * Defines the actual value of the StoreContext
 */
type StateValue = [state: ApplicationState, dispatch: (action: Action) => void];

/**
 * Defines the initial state of the application.
 * Persisted data (localStorage) should be loaded here
 */
const initialState: ApplicationState = {
  user: null,
  appVersion: pkg.version,
  breadcrumbs: [],
  cmsContextInstance: null,
  globalSchemaDefinitions: {},
};

export const Store = createContext<StateValue>([initialState, () => {}]);

export const useStore = () => {
  const [state, dispatch] = useContext(Store);
  const setUser = useCallback(
    (user: KioUser | null) => {
      dispatch({
        type: ActionType.SET_USER,
        payload: user,
      });
    },
    [dispatch]
  );
  const pushBreadcrumb = useCallback(
    (breadcrumb: Breadcrumb) => {
      dispatch({
        type: ActionType.PUSH_BREADCRUMB,
        payload: breadcrumb,
      });
    },
    [dispatch]
  );
  const popBreadcrumb = useCallback(() => {
    dispatch({
      type: ActionType.POP_BREADCRUMB,
      payload: null,
    });
  }, [dispatch]);
  const setCmsContextInstance = useCallback(
    (instance: ApplicationInstance | null) => {
      dispatch({
        type: ActionType.SET_CMS_CONTEXT_INSTANCE,
        payload: instance,
      });
    },
    [dispatch]
  );

  const setGlobalSchemaDefinitions = useCallback(
    (schema: JSONSchema7 | null) => {
      dispatch({
        type: ActionType.SET_GLOBAL_SCHEMA_DEFINITIONS,
        payload: schema,
      });
    },
    [dispatch]
  );
  return useMemo(
    () => ({
      state,
      setUser,
      pushBreadcrumb,
      popBreadcrumb,
      setCmsContextInstance,
      setGlobalSchemaDefinitions,
    }),
    [state, setUser, pushBreadcrumb, popBreadcrumb, setCmsContextInstance, setGlobalSchemaDefinitions]
  );
};

interface StoreProviderProps {
  children?: React.ReactNode;
  initialStateForTesting?: ApplicationState;
}

/**
 * Example usage, given that this provider is provided higher in the DOM.
 * All "setters" are wrapped in a useCallback, so they are safe to put in deps-arrays and as props to children.
 * <pre>
 *     const {state, setSomeStateValue} = useStore();
 *     const [localSomeStateValue, setLocalSomeStateValue] = useState();
 *
 *     useEffect(() => {
 *         setSomeStateValue(localSomeStateValue);
 *     }, [localSomeStateValue, setSomeStateValue]);
 * </pre>
 * @param children
 * @constructor
 */
const StoreProvider: FC<StoreProviderProps> = ({ children, initialStateForTesting = null }) => {
  const state = useReducer(
    (state: ApplicationState, action: Action) => {
      switch (action.type) {
        case ActionType.SET_USER:
          return Reducers.setUser(state, action.payload);
        case ActionType.PUSH_BREADCRUMB:
          return Reducers.pushBreadcrumb(state, action.payload);
        case ActionType.POP_BREADCRUMB:
          return Reducers.popBreadcrumb(state);
        case ActionType.SET_CMS_CONTEXT_INSTANCE:
          return Reducers.setCmsContextInstance(state, action.payload);
        case ActionType.SET_GLOBAL_SCHEMA_DEFINITIONS:
          return Reducers.setGlobalSchemaDefinitions(state, action.payload);
      }
      return state;
    },
    !!initialStateForTesting ? initialStateForTesting : initialState
  ); // hack for being able to set initialstate for tests

  return <Store.Provider value={state}>{children}</Store.Provider>;
};

export default StoreProvider;
