import { Chip, styled } from "@mui/material";
import React, { FC } from "react";

export interface DataListChipProps {
  chipContent?: string | string[];
  onTagClicked?: (value: string) => void;
}

export const ChipContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 4px;
  .notClickable {
    pointer-events: none;
  }
  .clickable {
    :hover {
      border-width: 2px;
      font-weight: 700;
      .MuiChip-label {
        margin: 0 -1px;
      }
    }
  }
  .array {
    height: 20px;
    line-height: 1;
    .MuiChip-label {
      padding: 0 6px;
      font-size: 12px;
    }
  }
`;

export const DataListChip: FC<DataListChipProps> = ({ chipContent, onTagClicked }) => {
  if (!chipContent || !chipContent.length) return null;

  const handleTagClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
    event.preventDefault();
    event.stopPropagation();
    if (onTagClicked) {
      onTagClicked(value);
    }
  };

  return (
    <ChipContainer>
      {Array.isArray(chipContent) ? (
        chipContent.map((chip, i) => (
          <Chip
            key={i}
            className={onTagClicked ? "clickable array" : "notClickable array"}
            onClick={(event) => handleTagClicked(event, chip)}
            label={chip}
            variant="outlined"
            color="primary"
          />
        ))
      ) : (
        <Chip
          className={onTagClicked ? "clickable" : "notClickable"}
          onClick={(event) => handleTagClicked(event, chipContent)}
          label={chipContent}
          variant="outlined"
          color="primary"
        />
      )}
    </ChipContainer>
  );
};

export default DataListChip;
