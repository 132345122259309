import React, { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import Layout from "../framework/Layout";
import Header from "../framework/Header";
import { Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ContentContainer = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(8),
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));

const ErrorMessageWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 2, 2),
  padding: theme.spacing(2),
  background: theme.palette.background.default,
  width: "70vw",
  overflow: "auto",
  maxHeight: "200px",
  textAlign: "center",
}));

const ErrorView: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  return (
    <Layout header={<Header />}>
      <ContentContainer>
        <Typography variant="h4" component="h1">
          {t("views.error.somethingWentWrong")}
        </Typography>

        {!!error?.message && (
          <ErrorMessageWrapper>
            <Typography color="error">{t(error.message)}</Typography>
          </ErrorMessageWrapper>
        )}
        <ButtonContainer>
          <Button type="button" variant="outlined" role="navigation" onClick={() => resetErrorBoundary()}>
            {t("views.error.tryAgain")}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            role="navigation"
            onClick={() => history("/dashboard")}
          >
            {t("generic.toDashboard")}
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Layout>
  );
};

export default ErrorView;
