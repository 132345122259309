import { ViewComfy, ViewList } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { FC, memo, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export enum ViewType {
  LIST = "list",
  GRID = "grid_row",
}

export interface ViewTypeSelectorProps {
  selectedViewType?: ViewType;
  setSelectedViewType: (viewType: ViewType) => void;
}

const viewTypes: Array<{ viewType: ViewType; icon: ReactNode }> = [
  { viewType: ViewType.LIST, icon: <ViewList /> },
  { viewType: ViewType.GRID, icon: <ViewComfy /> },
];

export const ViewTypeSelector: FC<ViewTypeSelectorProps> = ({
  selectedViewType = ViewType.LIST,
  setSelectedViewType,
}) => {
  const { t } = useTranslation("common");
  return (
    <ToggleButtonGroup
      aria-label="Select view type"
      color="primary"
      size="small"
      value={selectedViewType}
      onChange={(_, newValue) => setSelectedViewType?.(newValue)}
      exclusive
    >
      {viewTypes.map((type) => (
        <ToggleButton
          key={type.viewType}
          color="secondary"
          value={type.viewType}
          arial-label={t(`viewType.${type.viewType}`)}
        >
          {type.icon}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default memo(ViewTypeSelector);
