import { LocalizedString } from "@/components/DataList/DataListRow";
import DMSMediaPlayer from "@/components/DMSMediaPlayer";
import { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import React, { FC, useEffect, useState } from "react";

export interface VideoPreviewProps extends AudioPreviewProps {
  subtitles?: LocalizedString;
  selectedLocale?: string;
}

export const VideoPreview: FC<VideoPreviewProps> = ({ identifier, title, subtitles, selectedLocale }) => {
  const [captionSrc, setCaptionSrc] = useState<string>();

  useEffect(() => {
    if (selectedLocale && subtitles) {
      const vttSubs = resolvePotentiallyLocalizedString(selectedLocale)(subtitles);
      if (vttSubs) setCaptionSrc(`data:text/vtt,${vttSubs}`);
      else if (captionSrc !== undefined) setCaptionSrc(undefined);
    }
  }, [selectedLocale, subtitles]);

  return <DMSMediaPlayer identifier={identifier} title={title || "Video player"} captionSrc={captionSrc} />;
};

export default VideoPreview;
