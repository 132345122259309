import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enCommon from "./en/common.json";
import svCommon from "./sv/common.json";
import noCommon from "./no/common.json";
import deCommon from "./de/common.json";
import esCommon from "./es/common.json";
import frCommon from "./fr/common.json";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import Settings from "../Settings";
import { getLanguageCode } from "@/utils/obj";

export const resources = {
  en: {
    common: enCommon,
  },
  no: {
    common: noCommon,
  },
  sv: {
    common: svCommon,
  },
  // fr: {
  //   common: frCommon,
  // },
  // de: {
  //   common: deCommon,
  // },
  // es: {
  //   common: esCommon,
  // },
};

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: (code) => getLanguageCode(code),
    ns: ["common", "db"],
    defaultNS: "common",
    lowerCaseLng: true,
    cleanCode: true,
    debug: Settings.DEBUG_I18NEXT,
    resources,
    backend: {
      loadPath: `${Settings.API_BASE_HOST}/admin/api/translations/{{lng}}.json`,
    },
  })
  // reloadResources must be called for HttpBackend to fetch the db translations
  .then(() => i18n.reloadResources(null, ["db"], () => {}));

export default i18n;
