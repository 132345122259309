import Styles from "@/assets/styles/Styles";
import Image from "@/components/Image";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { clsx } from "@/utils/styles";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  DragHandle,
  Edit,
  ExpandLess,
  ExpandMore,
  Language,
  RestoreFromTrash,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Box, ButtonBase, Collapse, Divider, IconButton, styled, Tooltip } from "@mui/material";
import React, {
  createContext,
  Dispatch,
  FC,
  MouseEvent,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

export interface CollapseBarProps {
  children?: any[] | any;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;

  title?: string;
  description?: string;
  titleAddon?: string;

  enableDelete?: boolean;
  deleteButtonDisabled?: boolean;
  isDeleted?: boolean;
  onDelete?: () => void;

  enableToggleVisibility?: boolean;
  toggleVisibilityButtonDisabled?: boolean;
  isVisible?: boolean;
  onToggleVisibility?: () => void;

  enableSettings?: boolean;
  onSettingsClick?: () => void;

  enableMovable?: boolean;
  moveUpButtonDisabled?: boolean;
  moveDownButtonDisabled?: boolean;
  onMoveUp?: () => void;
  onMoveDown?: () => void;

  showDragHandle?: boolean;
  disableExpansion?: boolean;
  settingsContent?: ReactElement;
  thumbnailImageSrc?: string | Promise<string | undefined>;

  isLocalized?: boolean;
}
const Container = styled("div")(() => ({
  flex: "1 auto",
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  width: "100%",
  "&:not(.has-open-child).is-active": {
    borderLeft: `${Styles.Dimensions.EDITOR_SECTION_ACTIVE_BORDER_WIDTH} solid #1976d2`,
  },
  "&.has-open-child": {
    paddingLeft: `${Styles.Dimensions.EDITOR_SECTION_ACTIVE_BORDER_WIDTH}`,
  },
  "&.nested": {
    border: `${Styles.Dimensions.EDITOR_SECTION_NESTED_BORDER_WIDTH} solid rgba(0,0,0, 0.12)`,
  },
  "&.draggable": {
    cursor: "grab",
  },
}));

const StartRow = styled(ButtonBase)(({}) => ({
  flex: "auto",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: `calc(16px - ${Styles.Dimensions.EDITOR_SECTION_ACTIVE_BORDER_WIDTH})`,
  paddingLeft: "16px",
  "&.is-active, &.has-open-child": {
    paddingLeft: `calc(16px - ${Styles.Dimensions.EDITOR_SECTION_ACTIVE_BORDER_WIDTH})`,
  },
}));

const StyledImage = styled(Image)(({}) => ({
  minWidth: "40px",
  "& img": {
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
}));

const EndRow = styled("div")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-end",
  alignItems: "center",
});

const CollapseHeader = styled("div")(({ open }: { open: boolean }) => ({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  height: "64px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: open ? "inherit" : Styles.Colors.THEME_PRIMARY_HOVER_COLOR,
  },
}));

const CollapsableContent = styled(Collapse)(({}) => ({
  background: "#fff",
  width: `calc(100% - 32px)`,
  margin: "0 16px",
  "&.open": {
    marginBottom: "16px",
  },
  "& .MuiFilledInput-root": {
    background: "#0000000D",
    borderRadius: "4px 4px 0 0",
  },
  "& .MuiInputLabel-shrink": {
    fontWeight: 700,
  },
}));

const ItemDivider = styled(Divider)({
  width: "100%",
});
export const SettingsContentContainer = styled("div")({
  display: "flex",
  gap: Styles.Dimensions.SETTINGS_BAR_GAP,
});

const depthContext = createContext<number>(0);
const notifyOpenContext = createContext<Dispatch<SetStateAction<boolean>> | null>(null);

// TODO: Make draggable
// Implement settings

const CollapseBar: FC<CollapseBarProps> = ({
  children,
  expanded = false,
  setExpanded,
  title = "",
  description = "",
  titleAddon = "",
  enableSettings = false,
  enableDelete = false,
  enableToggleVisibility = false,
  enableMovable = false,
  showDragHandle = false,
  isDeleted = false,
  isVisible = true,
  toggleVisibilityButtonDisabled = false,
  deleteButtonDisabled = false,
  moveUpButtonDisabled = false,
  moveDownButtonDisabled = false,
  onDelete,
  onToggleVisibility,
  onMoveUp,
  onMoveDown,
  onSettingsClick,
  disableExpansion = false,
  settingsContent,
  thumbnailImageSrc,
  isLocalized,
}) => {
  const { t } = useTranslation("common");
  const notifyParentHasExpandedChild = useContext(notifyOpenContext);
  const parentDepth = useContext(depthContext) ?? 0;
  const currentDepth = parentDepth + 1;

  const [hasOpenChild, setHasOpenChild] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);

  const expandedIcon = expanded ? (
    <ExpandLess htmlColor={Styles.Colors.GREY_600} />
  ) : (
    <ExpandMore htmlColor={Styles.Colors.GREY_600} />
  );
  const visibilityIcon = isVisible ? <Visibility /> : <VisibilityOff />;
  const deletedIcon = isDeleted ? <RestoreFromTrash /> : <Delete />;

  const ref = useRef<HTMLDivElement | null>(null);
  // useOnClickOutside(ref, () => setIsActive(false));

  const stopEvent = (handler?: () => void) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handler?.();
  };

  useEffect(() => {
    if (!!notifyParentHasExpandedChild) {
      notifyParentHasExpandedChild(expanded && isActive);
    }
  }, [isActive, expanded, notifyParentHasExpandedChild]);

  return (
    <depthContext.Provider value={currentDepth}>
      <notifyOpenContext.Provider value={setHasOpenChild}>
        <Container
          ref={ref}
          className={clsx({
            nested: currentDepth > 1,
            open: expanded,
            "has-open-child": hasOpenChild,
            draggable: showDragHandle,
            "is-active": expanded && isActive,
            "is-active-nested": currentDepth > 1 && isActive,
          })}
          onClick={() => {
            setIsActive(true);
          }}
        >
          <CollapseHeader
            open={disableExpansion || expanded}
            onClick={() => !disableExpansion && setExpanded?.(!expanded)}
          >
            <StartRow
              className={clsx({
                open: expanded,
                "has-open-child": hasOpenChild,
                "is-active": expanded && isActive,
              })}
              disabled={disableExpansion}
            >
              {!disableExpansion && expandedIcon}
              {thumbnailImageSrc && <StyledImage src={thumbnailImageSrc} alt={title} width="48px" height="48px" />}
              <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} p={"8px 0"}>
                <KioTitle title={title || t("generic.noTitle")} description={titleAddon} level={currentDepth} isRow />
                <KioTitle description={description} />
              </Box>
            </StartRow>

            <EndRow>
              <SettingsContentContainer>{settingsContent}</SettingsContentContainer>
              {enableSettings && (
                <Tooltip title={t("arrayButtons.editElement") || "Edit"}>
                  <IconButton onClick={stopEvent(onSettingsClick)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
              {enableToggleVisibility && (
                <Tooltip
                  title={t("arrayButtons." + (isVisible ? "showElement" : "hideElement")) || "Toggle visibility on/off"}
                >
                  <span>
                    <IconButton disabled={toggleVisibilityButtonDisabled} onClick={stopEvent(onToggleVisibility)}>
                      {visibilityIcon}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {enableDelete && (
                <Tooltip
                  title={t("arrayButtons." + (isDeleted ? "restoreElement" : "removeElement")) || "Remove/restore"}
                >
                  <span>
                    <IconButton disabled={deleteButtonDisabled} onClick={stopEvent(onDelete)}>
                      {deletedIcon}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {enableMovable && (
                <Tooltip title={t("arrayButtons.moveUp") || "Up"}>
                  <span>
                    <IconButton disabled={moveUpButtonDisabled} onClick={stopEvent(onMoveUp)}>
                      <ArrowUpward />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {enableMovable && (
                <Tooltip title={t("arrayButtons.moveDown") || "Down"}>
                  <span>
                    <IconButton disabled={moveDownButtonDisabled} onClick={stopEvent(onMoveDown)}>
                      <ArrowDownward />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {showDragHandle && (
                <Tooltip title={t("arrayButtons.dragElement") || "Drag"}>
                  <span>
                    <IconButton disabled>
                      <DragHandle />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {isLocalized && (
                <Tooltip title={t("kioForm.fields.LocalizedField.localizedContent")}>
                  <Box display={"flex"} padding={1} marginRight={"20px"} color={"rgba(0, 0, 0, 0.54)"}>
                    <Language />
                  </Box>
                </Tooltip>
              )}
            </EndRow>
          </CollapseHeader>
          <CollapsableContent
            className={clsx({
              open: expanded,
              "is-active": expanded && isActive,
            })}
            in={expanded}
            timeout={0}
          >
            {children}
          </CollapsableContent>
        </Container>
        {currentDepth === 1 && <ItemDivider />}
      </notifyOpenContext.Provider>
    </depthContext.Provider>
  );
};

export default CollapseBar;
