import { useEffect, useState } from "react";
import Api from "@/services/Api";
import { JSONSchema7 } from "json-schema";

const useGlobalDefs = () => {
  const [globalDefs, setGlobalDefs] = useState<JSONSchema7>({});
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    Api.getGlobalDefinitions()
      .fetchDirect({})
      .then((res) => {
        setGlobalDefs(res);
        setFetching(false);
      });
  }, []);
  return { globalDefs, fetching };
};

export default useGlobalDefs;
