import AudioPreview from "@/components/MediaPreview/AudioPreview";
import DocumentPreview from "@/components/MediaPreview/DocumentPreview";
import ImagePreview from "@/components/MediaPreview/ImagePreview";
import SketchfabPreview from "@/components/MediaPreview/SketchfabPreview";
import VideoPreview from "@/components/MediaPreview/VideoPreview";
import VimeoPreview from "@/components/MediaPreview/VimeoPreview";
import YoutubePreview from "@/components/MediaPreview/YoutubePreview";
import { FilterProps } from "@/components/MediaSelector/ColorFiltersModal";
import Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import { MediaSelectorFieldsetImageDimensions } from "@/framework/KioForm/fields/MediaSelectorField";
import { Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface MediaPreviewProps {
  media: Media;
  setImageDimensions?: MediaSelectorFieldsetImageDimensions;
  width?: string | number;
  height?: string | number;
  filterProps?: FilterProps;
  selectedLocale?: string;
}

export const MediaPreview: FC<MediaPreviewProps> = ({
  media,
  setImageDimensions,
  height,
  width,
  filterProps,
  selectedLocale,
}) => {
  const { t } = useTranslation("common");

  switch (media.media_type) {
    case MediaType.IMAGE:
    case MediaType.DM:
      return (
        <ImagePreview
          url={media.src}
          name={media.name}
          setImageDimensions={setImageDimensions}
          width={width}
          height={height}
          filterProps={filterProps}
        />
      );
    case MediaType.AUDIO:
      return <AudioPreview identifier={media.identifier} title={media.name} />;
    case MediaType.VIDEO:
      return (
        <VideoPreview
          identifier={media.identifier}
          title={media.name}
          subtitles={media.closed_captions}
          selectedLocale={selectedLocale}
        />
      );
    case MediaType.DOCUMENT:
      return <DocumentPreview url={media.src} />;
    case MediaType.YOUTUBE:
      return <YoutubePreview identifier={media.identifier} title={media.name} />;
    case MediaType.VIMEO:
      return <VimeoPreview identifier={media.identifier} title={media.name} />;
    case MediaType.SKETCHFAB:
      return <SketchfabPreview identifier={media.identifier} title={media.name} />;
    default:
      return <Typography color="error">{t("components.MediaPreview.UnableToPreview")}</Typography>;
  }
};

export default MediaPreview;
