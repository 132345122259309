import DataList, { DataListProps } from "@/components/DataList/DataList";
import { DataListRow } from "@/components/DataList/DataListRow";
import LicenseUserRole from "@/declarations/models/LicenseUserRole";
import PaginationResult from "@/declarations/PaginationResult";
import Api from "@/services/Api";
import { styled } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const VALID_ROUTES = ["instance", "license"];

const UserListContainer = styled("div")`
  > div {
    height: fit-content;
  }
`;

export const LicenseUserList: FC = () => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  const { pathname } = useLocation();
  const pathParams = pathname.split("/");
  const id = Number(pathParams.pop());
  const route = pathParams.pop() || "";

  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());

  if (!VALID_ROUTES.includes(route)) return null;

  const getUsers: DataListProps<LicenseUserRole>["getItems"] = (page: number, pageSize: number) => {
    const defaultValue: PaginationResult<LicenseUserRole> = {
      page,
      page_size: pageSize,
      count: 0,
      total_count: 0,
      items: [],
    };
    const ctx =
      route === "license"
        ? Api.getUsersByLicenseId({ license_id: id, page, page_size: pageSize })
        : Api.getUsersByInstanceId({ application_instance_id: id, page, page_size: pageSize });
    return ctx.fetchDirect(defaultValue);
  };

  const userMapper = (item: LicenseUserRole): DataListRow => ({
    key: `license-${item.license_id}-user-${item.user_id}`,
    title: `${item.name_of_user}`,
    subTitle: `${item.username}`,
    infoText: `Id: ${item.user_id}`,
    chipContent: t(`userRole.${item.user_role}`),
  });

  const onItemClick = (item: LicenseUserRole) => {
    history("/admin/user/" + item.user_id);
  };

  const deleteUserFromLicense = async (item: LicenseUserRole) => {
    const [_, error] = await Api.removeUserFromLicense(item.user_id, item.license_id).fetch();
    if (error) {
      return Promise.reject("Unable to remove user from license");
    }
    setLastFetchedTimestamp(Date.now());
  };

  return (
    <UserListContainer>
      <DataList
        listTitle={t("views.admin.licenses.userList")}
        getItems={getUsers}
        mapperFn={userMapper}
        onItemClick={onItemClick}
        externalDataChanged={lastFetchedTimestamp}
        onDeleteItem={deleteUserFromLicense}
        disableSorting
      />
    </UserListContainer>
  );
};

export default LicenseUserList;
