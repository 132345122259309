import BreadcrumbNode from "@/components/BreadcrumbNode";
import { CreateOrEditProps } from "@/components/CreateOrEdit";
import { DataListProps } from "@/components/DataList/DataList";
import { DataListRow } from "@/components/DataList/DataListRow";
import { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import ApplicationInstance from "@/declarations/models/ApplicationInstance";
import { RequestContext } from "@/declarations/RequestContext";
import { schema } from "@/declarations/schemas/instance/schema";
import { uiSchema } from "@/declarations/schemas/instance/uiSchema";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import LicenseUserList from "@/views/admin/LicenseUserList";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateOrEditRoutes from "./CreateOrEditRoutes";

const mapperFn = (instanceItem: ApplicationInstance): DataListRow => ({
  key: String(instanceItem.id),
  title: instanceItem.name!,
  subTitle: instanceItem.owner_name,
  chipContent: `${instanceItem.slug}`,
  infoText: `ID: ${instanceItem.id}`,
  updatedAt: instanceItem.updated_at,
  updatedBy: instanceItem.updated_by,
});

const getInstanceFormData: CreateOrEditProps<ApplicationInstance>["getFormData"] = (
  id: number
): RequestContext<ApplicationInstance> => {
  return Api.getOneInstance(id);
};

const updateInstanceFormData: CreateOrEditProps<ApplicationInstance>["onSubmit"] = (
  instance: ApplicationInstance
): RequestContext<ApplicationInstance> => {
  return Api.updateInstance(instance);
};

const createInstanceFormData: CreateOrEditProps<ApplicationInstance>["onSubmit"] = (
  instance: ApplicationInstance
): RequestContext<ApplicationInstance> => {
  return Api.createInstance(instance);
};

const deleteInstance = async (instance: ApplicationInstance) => {
  const error = (await Api.deleteInstance(instance?.id || 0).fetch())[1];
  if (error) {
    return Promise.reject("Unable to delete instance");
  }
};

const undeleteInstance = async (item: ApplicationInstance) => {
  await Api.undeleteInstance(item.id!).fetch();
};

export const InstanceView = () => {
  const { t } = useTranslation("common");

  const [sortBy, setSortBy] = useState<string>("updated_at");
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [searchInput]);

  const getInstances: DataListProps<ApplicationInstance>["getItems"] = (page, page_size) => {
    const defaultResponse = { page, page_size, count: 0, items: [], total_count: 0 };

    return Api.getAllInstances({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
    }).fetchDirect(defaultResponse);
  };

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    if (sortProp && sortProp !== sortBy) setSortBy(sortProp);
    if (sortDirection) setSortAscending(sortDirection === "asc");

    setLastFetchedTimestamp(Date.now());
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.INSTANCE}`} />
      <CreateOrEditRoutes
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit
        createFormData={createInstanceFormData}
        updateFormData={updateInstanceFormData}
        getFormData={getInstanceFormData}
        afterContent={<LicenseUserList />}
        listProps={{
          getItems: getInstances,
          mapperFn: mapperFn,
          onDeleteItem: deleteInstance,
          onDeleteItemForever: deleteInstance,
          onUndeleteItem: undeleteInstance,
          listTitle: t("schemaTypes.instance_plural"),
          hideImage: true,
          defaultViewType: ViewType.LIST,
          disableViewTypeSelection: true,
          handleOnItemsChanged: handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          initialSortOption: 2,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default InstanceView;
