import ThreeDPlaceholder from "@/assets/img/placeholder_3d.svg";
import AudioPlaceholder from "@/assets/img/placeholder_audio.svg";
import ImagePlaceholder from "@/assets/img/placeholder_image.svg";
import VideoPlaceholder from "@/assets/img/placeholder_video.svg";
import Styles from "@/assets/styles/Styles";
import DataListChip from "@/components/DataList/common/DataListChip";
import DataListOptions from "@/components/DataList/common/DataListOptions";
import DataListTitle from "@/components/DataList/common/DataListTitle";
import DataListUpdatedByAt from "@/components/DataList/common/DataListUpdatedByAt";
import { DataListRowInternal } from "@/components/DataList/DataListRow";
import { DataListViewProps } from "@/components/DataList/ViewTypes/ListView";
import { Image } from "@/components/Image";
import BaseModel from "@/declarations/models/BaseModel";
import Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import Api from "@/services/Api";
import { Box, ButtonBase, Checkbox, Paper, styled, Typography } from "@mui/material";
import React from "react";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "wrap",
  gap: theme.spacing(2),
  margin: theme.spacing(2),
}));

const Item = styled(Paper)`
  position: relative;
  flex: 1 0 auto;
  max-width: min(100%, max(25vw, 300px));
  &.enabled {
    :hover {
      background-color: ${Styles.Colors.THEME_BACKGROUND_PRIMARY};
    }
  }
`;

const StyledButtonBase = styled(ButtonBase)`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  &.deletedOrSelected {
    opacity: 0.5;
  }
`;

export const StyledImage = styled(Image)({
  backgroundColor: Styles.Colors.DATA_LIST_IMAGE_BACKGROUND_COLOR,
  overflow: "hidden",
  display: "inline",

  "& img": {
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
  },
});

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  gap: theme.spacing(1),
  width: "100%",
  padding: theme.spacing(1),
}));

const ActionButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
  width: "100%",
});

const TextContainer = styled("span")({
  width: "fit-content",
  maxWidth: "calc(100% - 8px)",
});

const ChipUpdatedContainer = styled("span")({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  maxWidth: "calc(100% - 8px)",
});

const StyledCheckbox = styled(Checkbox)`
  background-color: rgba(255, 255, 255, 0.75);
  width: 36px;
  height: 36px;
  :hover {
    background-color: white;
  }
`;

async function resolveMediaUrlFromID(id: number): Promise<string | undefined> {
  const media = await Api.getOneMedia(id).fetchDirect(null);
  if (media) {
    return media.thumbnail_src;
  }
}

const mediaTypePlaceholderMap: { [mt in MediaType]: string | undefined } = {
  [MediaType.IMAGE]: ImagePlaceholder,
  [MediaType.AUDIO]: AudioPlaceholder,
  [MediaType.VIDEO]: VideoPlaceholder,
  [MediaType.DOCUMENT]: ImagePlaceholder, // FIXME: Add proper fallback-image for Document
  [MediaType.YOUTUBE]: VideoPlaceholder,
  [MediaType.VIMEO]: VideoPlaceholder,
  [MediaType.SKETCHFAB]: ThreeDPlaceholder,
  [MediaType.DM]: ImagePlaceholder, // FIXME: Add proper fallback-image for DM
};

export const GridView = <T extends object>({
  items,
  getIsSelected,
  onSelectChanged,
  onItemClick,
  onEdit,
  onDelete,
  onUndelete,
  onDeleteForever,
  onTagClicked,
  selectedItems,
  enableSelection,
  hideImage,
  hideUpdated,
  denseOptions,
  fallbackImage,
  customListItemButtons,
}: DataListViewProps<T>) => {
  const handleSelectionChange = (item: DataListRowInternal<T>, selected: boolean) =>
    enableSelection && onSelectChanged!(item, selected);

  return (
    <Container>
      {items.map((item) => {
        let imageSrc: string | Promise<string | undefined> | undefined;
        if (!isNaN(Number(item.imageURL)) && Number(item.imageURL) > 0) {
          // If the image source is just a number, assume it is referencing a media object
          imageSrc = resolveMediaUrlFromID(Number(item.imageURL));
        } else if (typeof item.imageURL === "string") {
          imageSrc = item.imageURL;
        }
        const sourceItem = item.sourceItem as BaseModel;
        const selected = getIsSelected(item);
        const isDeleted = !!sourceItem.deleted_at;
        const wasSelected = selectedItems?.includes(item.key);
        const enabled = !!onItemClick && !isDeleted && !wasSelected;
        return (
          <Item key={item.key} className={enabled ? "enabled" : undefined}>
            <ActionButtonContainer>
              {enableSelection && (
                <StyledCheckbox
                  checked={selected ?? wasSelected}
                  onChange={() => handleSelectionChange(item, !selected)}
                />
              )}
              <DataListOptions
                item={item}
                denseOptions={denseOptions}
                isDeleted={isDeleted}
                handleEditClick={onEdit}
                handleDeleteClick={onDelete}
                handleDeleteForeverClick={onDeleteForever}
                handleUndelete={onUndelete}
                customButtons={customListItemButtons}
              />
            </ActionButtonContainer>

            <StyledButtonBase
              onClick={() => onItemClick?.(item)}
              disabled={!enabled}
              className={isDeleted || wasSelected ? "deletedOrSelected" : ""}
            >
              {!hideImage && (
                <StyledImage
                  src={imageSrc}
                  alt={item.title}
                  fallbackImage={
                    sourceItem.hasOwnProperty("media_type")
                      ? mediaTypePlaceholderMap[(sourceItem as Media).media_type]
                      : fallbackImage
                  }
                  height={Styles.Dimensions.DATA_LIST_GRID_ITEM_SIZE}
                />
              )}

              <ContentContainer>
                <>
                  <TextContainer>
                    <DataListTitle item={item} />
                  </TextContainer>
                  {item.subTitle && (
                    <TextContainer>
                      <DataListTitle item={item} subtitle />
                    </TextContainer>
                  )}
                </>
                <ChipUpdatedContainer>
                  {item.chipContent && <DataListChip chipContent={item.chipContent} onTagClicked={onTagClicked} />}
                  <Box display={"flex"} flexDirection={"column"}>
                    {!!item.infoText && (
                      <Typography variant={"caption"} fontSize={"1em"} fontWeight={700}>
                        {item.infoText}
                      </Typography>
                    )}
                    {!hideUpdated && <DataListUpdatedByAt item={item} />}
                  </Box>
                </ChipUpdatedContainer>
              </ContentContainer>
            </StyledButtonBase>
          </Item>
        );
      })}
    </Container>
  );
};

export default GridView;
