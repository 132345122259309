import { JSONSchema7 } from "json-schema";

export const schema: JSONSchema7 = {
  type: "object",
  properties: {
    id: {
      type: "integer",
      readOnly: true,
    },
    language_code: {
      type: "string",
      pattern: "^[A-Za-z\\-]*$",
      minLength: 2,
    },
    name: {
      type: "string",
      minLength: 1,
    },
  },
  required: ["name", "language_code"],
};
