import ArrayRelationField from "@/framework/KioForm/fields/documentRelationFieldVariants/ArrayRelationField";
import SingleRelationField from "@/framework/KioForm/fields/documentRelationFieldVariants/SingleRelationField";
import { KioFieldProps } from "@/framework/KioForm/KioFieldProps";
import { Typography } from "@mui/material";
import { UiSchema, utils } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface RelationFieldOptions {
  schemaId: number;
  schemaIds: number[];
  imageUrl?: string;
  displayReferenceKey?: boolean;
  displayContentField?: boolean;
  addButton?: { "ui:label": string };
  createButton?: { "ui:label": string };
}

function configurationErrors({ uiSchema, jsonSchema }: { uiSchema: UiSchema; jsonSchema: JSONSchema7 }): string[] {
  const errors: string[] = [];
  if (
    !uiSchema["ui:options"]?.schemaIds ||
    !Array.isArray(uiSchema["ui:options"].schemaIds) ||
    !uiSchema["ui:options"].schemaIds.length
  ) {
    errors.push("components.relationField.missing.uiSchema.schemaIds");
  }

  if (jsonSchema.type !== "object") {
    errors.push("components.relationField.schema.invalid.type");
  }

  if (!jsonSchema.properties || !("to_document_id" in jsonSchema.properties)) {
    errors.push("components.relationField.schema.missing.property.to_document_id");
  }

  if (!jsonSchema.properties || !("reference_key" in jsonSchema.properties)) {
    errors.push("components.relationField.schema.missing.property.reference_key");
  }

  return errors;
}

const DocumentRelationField: FC<KioFieldProps> = (props) => {
  const { t } = useTranslation("common");

  const schema = utils.retrieveSchema(props.schema, props.registry.definitions, props.formData);
  let objectTypeSchema = schema;
  if (schema.type === "array" && schema.items) {
    if (typeof schema.items === "object") {
      objectTypeSchema = Array.isArray(schema.items) ? (schema.items[0] as JSONSchema7) : schema.items;
    } else {
      return <Typography role="alert">{t("components.relationField.schema.invalid.type")}</Typography>;
    }
  }
  const configErrors = configurationErrors({ uiSchema: props.uiSchema, jsonSchema: objectTypeSchema });
  if (configErrors.length) {
    return (
      <>
        {configErrors.map((err) => (
          <Typography key={err} role="alert">
            {t(err)}
          </Typography>
        ))}
      </>
    );
  }

  if (schema.type === "object") {
    return <SingleRelationField {...props} showDocumentSelector />;
  } else if (schema.type === "array") {
    return <ArrayRelationField {...props} schema={schema} />;
  } else {
    console.error("Unsupported schema type for DocumentRelationField", schema);
  }

  return null;
};

export default DocumentRelationField;
