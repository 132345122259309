import React, { FC } from "react";
import DMSMediaPlayer from "@/components/DMSMediaPlayer";

export interface AudioPreviewProps {
  identifier?: string;
  title?: string;
}

export const AudioPreview: FC<AudioPreviewProps> = ({ identifier, title }) => {
  return <DMSMediaPlayer identifier={identifier} title={title || "Audio player"} audio />;
};

export default AudioPreview;
