import { grey } from "@mui/material/colors";

abstract class Colors {
  public static readonly THEME_PRIMARY: string = "#3E78BB";
  public static readonly THEME_SECONDARY: string = Colors.THEME_PRIMARY;
  public static readonly THEME_BACKGROUND_PRIMARY: string = "#2A26330F";

  public static readonly THEME_ADMIN_PRIMARY: string = "#D24150";
  public static readonly THEME_ADMIN_SECONDARY: string = Colors.THEME_ADMIN_PRIMARY;
  public static readonly PREVIEW_BACKGROUND_COLOR: string = "#333333";
  public static readonly EDITOR_TABS_BORDER_COLOR: string = "#BDBDBD";
  public static readonly EDITOR_BACKGROUND_COLOR: string = "#EEEEEE";
  public static readonly THEME_PRIMARY_HOVER_COLOR: string = "#F1F3F5";
  public static readonly PREVIEW_BORDER_COLOR: string = "#000000";
  public static readonly EMBEDDED_CONTENT_PREVIEW_FALLBACK_BORDER_COLOR: string = "#DDDDDD";
  public static readonly DATA_LIST_IMAGE_BACKGROUND_COLOR: string = "#DCDCDC";
  public static readonly FILE_UPLOAD_DRAGGING_BACKGROUND_COLOR: string = "#CCCCCC";
  public static readonly FILE_UPLOAD_REMOVE_FILE_BACKGROUND_COLOR: string = "#FFFFFF99";
  public static readonly HEADER_SEPARATOR_COLOR: string = "#9E9E9E4D";
  public static readonly FORM_BACKGROUND_COLOR: string = "#EEEEEE";
  public static readonly GREY_600: string = grey[600];
  public static readonly WHITE = "#FFFFFF";
  public static readonly DRAWER_BACKGROUND_COLOR: string = "#FAFAFA";
  public static readonly AVATAR_BACKGROUND_COLOR: string = "#004D8B80";
  public static readonly PAGINATION_SELECTED_BACKGROUND_COLOR: string = "#E0E0E0";
  public static readonly MENU_INPUT_BACKGROUND_COLOR: string = "#EBEBEB";
}

abstract class Dimensions {
  public static readonly MENU_WIDTH: string = "256px";
  public static readonly INPUT_WIDTH: string = "300px";
  public static readonly HEADER_HEIGHT: number = 9;
  public static readonly PREVIEW_DESKTOP_WIDTH: number = 1920;
  public static readonly PREVIEW_DESKTOP_HEIGHT: number = 1080;
  public static readonly PREVIEW_TABLET_WIDTH: number = 1024;
  public static readonly PREVIEW_TABLET_HEIGHT: number = 768;
  public static readonly PREVIEW_MOBILE_WIDTH: number = 812;
  public static readonly PREVIEW_MOBILE_HEIGHT: number = 375;
  public static readonly PREVIEW_BORDER_WIDTH: string = "5px";
  public static readonly SCHEMA_PREVIEW_WIDTH: string = "50vw";
  public static readonly MENU_PREVIEW_WIDTH: string = "25vw";
  public static readonly DEFAULT_ADMIN_PREVIEW_WIDTH: string = Dimensions.SCHEMA_PREVIEW_WIDTH;
  public static readonly EDITOR_PREVIEW_WIDTH: string = "50vw";
  public static readonly MEDIA_SELECTOR_DRAWER_WIDTH: string = "50vw";
  public static readonly JSON_EDITOR_HEIGHT: string = "600px";
  public static readonly EDITOR_SECTION_ACTIVE_BORDER_WIDTH: string = "4px";
  public static readonly EDITOR_SECTION_NESTED_BORDER_WIDTH: string = "1px";
  /**
   * Max height of the first image in the grid in FocalPointModal.
   */
  public static readonly FOCAL_POINT_MODAL_MASTER_IMAGE_MAX_HEIGHT = "40vh";
  /**
   * Space between fields in a form when they are on the same row
   */
  public static readonly FORM_FIELD_ROW_FIELDS_SPACING: number = 1;
  /**
   * The height/width of an item in a DataList when using grid-view
   */
  public static readonly DATA_LIST_GRID_ITEM_SIZE: string = "262px";
  public static readonly DATA_LIST_GRID_MAX_TEXT_WIDTH: string = "300px";
  public static readonly MEDIA_PREVIEW_MEDIA_PLAYER_HEIGHT: string = "450px";
  public static readonly MEDIA_PREVIEW_MEDIA_PLAYER_WIDTH: string = "800px";
  public static readonly MEDIA_PREVIEW_AUDIO_PLAYER_HEIGHT: string = "54px";
  public static readonly MENU_LOADER_HEADING_WIDTH: string = "75px";
  public static readonly MENU_LOADER_HEADING_HEIGHT: string = "48px";
  public static readonly MENU_LOADER_ITEM_HEIGHT: string = "36px";

  public static readonly SETTINGS_BAR_GAP: string = "8px";
}

export const Styles = {
  Colors,
  Dimensions,
};

export default Styles;
