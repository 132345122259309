import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationApi from "../../services/AuthenticationApi";
import Loader from "../Loader";
import { cancellablePromise } from "@/utils/async";
import { getQueryParams } from "@/utils/url";

export const EkulturCallbackHandler: FC = () => {
  const history = useNavigate();

  useEffect(() => {
    const { code, state } = getQueryParams<{ code: string; state: string }>();
    if (!code || !state || !history) {
      // Throw error. Caught by error-boundary:
      throw Error("Callback reached without code or state");
    }

    const [swapCodeForToken, cancel] = cancellablePromise(AuthenticationApi.codeToToken(code, state));

    swapCodeForToken.then(() => history("/dashboard")).catch(console.error);

    return cancel;
  }, [history]);

  return <Loader loadingText="views.login.loadingCallback" />;
};

export default EkulturCallbackHandler;
