import React, { FC, ReactNode, useMemo, useState } from "react";
import { AppBar, styled, Tab, Tabs } from "@mui/material";

export interface TabContent {
  value: string | number;
  content?: ReactNode;
}

export interface TabsLayoutProps {
  tabs: Array<TabContent>;
  ariaLabel: string;
  getLabel: (value: string | number) => string;
  /**
   * The value of the tab to select by default.
   * Selects the first element of `tabs` if not provided
   */
  defaultSelectedTab?: string | number;
  /**
   * If true, the content of each tab will be mounted on first render, and dismounted when the component dismounts.
   * If false, the content of each tab will be mounted when the tab is selected, and dismounted when another tab is selected.
   */
  keepContentMounted?: boolean;
  /**
   * Provides a way to render the same component for each tab. May be overridden by the `content` provided for each tab.
   * @param value
   */
  children?: (value: string | number) => ReactNode;
  elevation?: number;
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const TabPanel = styled("div")({
  width: "100%",
  height: "100%",
  overflow: "auto",
});

export const TabsLayout: FC<TabsLayoutProps> = ({
  tabs,
  getLabel,
  defaultSelectedTab,
  ariaLabel,
  keepContentMounted = true,
  elevation = 1,
  children,
}) => {
  const defaultTab = useMemo<string | number>(() => {
    if ((!!defaultSelectedTab || defaultSelectedTab === 0) && tabs.some((tab) => tab.value === defaultSelectedTab)) {
      return defaultSelectedTab;
    }
    return tabs?.[0]?.value || "";
  }, [tabs, defaultSelectedTab]);

  const [selectedTab, setSelectedTab] = useState<string | number>(defaultTab);

  const currentTab = tabs.some((tab) => tab.value === selectedTab) ? selectedTab : defaultTab;

  if (tabs?.length === 1) {
    return <>{tabs[0].content ?? children!(tabs[0].value)}</>;
  }

  return (
    <Container>
      <AppBar position="static" color="transparent" elevation={elevation}>
        <Tabs
          value={currentTab}
          indicatorColor="secondary"
          textColor="primary"
          onChange={(_, value) => setSelectedTab(value)}
          variant="scrollable"
          aria-label={ariaLabel}
        >
          {tabs.map((tab) => (
            <Tab
              id={String(tab.value)}
              aria-controls={String(tab.value)}
              key={tab.value}
              value={tab.value}
              label={getLabel(tab.value)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab) => (
        <TabPanel
          key={tab.value}
          id={String(tab.value)}
          role="tabpanel"
          aria-labelledby={String(tab.value)}
          hidden={tab.value !== currentTab}
        >
          {(keepContentMounted || currentTab === tab.value) && (tab.content ?? children!(tab.value))}
        </TabPanel>
      ))}
    </Container>
  );
};

export default TabsLayout;
