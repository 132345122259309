import React, { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";
import DateFnsUtils from "@date-io/date-fns";
import { Box, TextField, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export interface KioDateTimePickerProps {
  onChange?: (date: string | null) => void;
  value?: string | null;
  label: string;
  description?: string;
  name?: string | undefined;
  timePickerType: "date-time" | "date" | "time";
  formValue: string | null;
  cancelLabel?: string;
  okLabel?: string;
  clearLabel?: string;
  todayLabel?: string;
  invalidDateMessage?: string;
  disabled?: boolean;
}

const DateTimePickerWrapper = styled("span")`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  .MuiTextField-root {
    width: 100%;
  }
`;

const KioDateTimePicker: FC<KioDateTimePickerProps> = ({
  timePickerType,
  name,
  description,
  formValue,
  onChange,
  label,
  cancelLabel,
  okLabel,
  clearLabel,
  todayLabel,
  invalidDateMessage,
  disabled,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateTimeChange = (date: any) => {
    setSelectedDate(date);
    try {
      onChange?.(date ? date?.toISOString() : null);
    } catch (err) {
      if (err instanceof RangeError) {
      } else {
        throw err;
      }
    }
  };
  useEffect(() => {
    let formValueToDate: Date | null = null;
    if (formValue) {
      try {
        formValueToDate = new Date(formValue);
      } catch (err) {
        if (err instanceof RangeError) {
          // Bad date/time format
          formValueToDate = null;
        }
      }
      setSelectedDate(formValueToDate);
    }
  }, [formValue]);

  const RenderTimePicker = (timePickerType: any, commonProps: object) => {
    switch (timePickerType) {
      case "date-time":
        return (
          <DesktopDatePicker
            inputFormat="DD.MM.YYYY HH:mm"
            value={selectedDate}
            onChange={handleDateTimeChange}
            renderInput={(params) => <TextField variant={"filled"} {...params} />}
            {...commonProps}
          />
        );
      case "date":
        return (
          <DesktopDatePicker
            inputFormat="DD.MM.YYYY"
            value={selectedDate}
            onChange={handleDateTimeChange}
            renderInput={(params) => <TextField variant={"filled"} {...params} />}
            {...commonProps}
          />
        );
      case "time":
        return (
          <DesktopDatePicker
            inputFormat="HH:mm"
            value={selectedDate}
            onChange={handleDateTimeChange}
            renderInput={(params) => <TextField variant={"filled"} {...params} />}
            {...commonProps}
          />
        );
    }
  };

  return (
    <>
      <LocalizationProvider utils={DateFnsUtils} dateAdapter={AdapterMoment}>
        <DateTimePickerWrapper>
          {RenderTimePicker(timePickerType, {
            clearable: true,
            fullWidth: true,
            showTodayButton: true,
            ampm: false,
            inputVariant: "filled",
            name,
            description,
            label,
            cancelLabel,
            okLabel,
            clearLabel,
            todayLabel,
            invalidDateMessage,
            disabled,
            "aria-disabled": disabled,
          })}
        </DateTimePickerWrapper>
      </LocalizationProvider>
      {!!description && (
        <Tooltip id={`${name}-description`} title={description} arrow>
          <Box p={"16px 8px 0 16px"}>
            <Info color="primary" />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default KioDateTimePicker;
