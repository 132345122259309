import React, { FC } from "react";
import { Navigate, Route, Routes as Switch } from "react-router-dom";
import EkulturLoginController from "./EkulturLoginController";
import Settings from "../../Settings";
import EkulturCallbackHandler from "./EkulturCallbackHandler";
import EkulturLogoutHandler from "./EkulturLogoutHandler";
import KioUserLoader from "./KioUserLoader";

interface EkulturLoginHandlerProps {
  children: any;
}

/**
 * This component should handle all routing associated with the authentication.
 * The '/oauth2/callback' is a route that should handle a redirect from the IDP, and exchange a code with a token.
 * The '/logout' should log out the current user with the IDP
 * the '*' and '/' -routes should assume the user is logged in if a token is present while it is refreshed, otherwise start the login process
 * @param children
 * @constructor
 */
export const EkulturLoginHandler: FC<EkulturLoginHandlerProps> = ({ children }) => {
  return (
    <Switch>
      <Route path={Settings.EKULTUR_CALLBACK_URL} element={<EkulturCallbackHandler />} />

      <Route path="/logout" element={<EkulturLogoutHandler />} />

      <Route
        path="/*"
        element={
          <EkulturLoginController>
            <KioUserLoader>{children}</KioUserLoader>
          </EkulturLoginController>
        }
      />
    </Switch>
  );
};

export default EkulturLoginHandler;
