import React, { FC } from "react";
import { BaseTextFieldProps, InputAdornment, styled, TextField, TextFieldProps, Tooltip } from "@mui/material";
import { Info, Language } from "@mui/icons-material";
import Styles from "@/assets/styles/Styles";
import { useTranslation } from "react-i18next";

export interface KioTextFieldProps extends BaseTextFieldProps {
  onChange?: TextFieldProps["onChange"];
  onFocus?: TextFieldProps["onFocus"];
  readonly?: boolean;
  description?: string;
  isLocalized?: boolean;
}

const TextFieldWrapper = styled("span")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  "&:hover": {
    backgroundColor: Styles.Colors.THEME_PRIMARY_HOVER_COLOR,
  },
});

const StyledTextField = styled(TextField)`
  &.multiline {
    .MuiInputAdornment-root {
      height: fit-content;
      width: fit-content;
      margin: -8px 0 auto 8px;
    }
    & textarea {
      min-height: 4em;
      max-height: 40em;
      overflow-y: auto !important;
      resize: vertical;
    }
  }
`;

const DescriptionIconContainer = styled("span")(({ theme }) => ({
  padding: theme.spacing(2, 1, 0, 2),
}));

const KioTextField: FC<KioTextFieldProps> = ({
  title,
  description,
  name,
  required,
  disabled,
  readonly,
  isLocalized,
  multiline,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <TextFieldWrapper>
      <StyledTextField
        className={multiline ? "multiline" : undefined}
        aria-describedby={`${name}-description`}
        autoComplete="on"
        variant="filled"
        color="primary"
        label={title}
        name={name}
        required={required}
        disabled={disabled}
        aria-required={required}
        aria-disabled={disabled}
        aria-readonly={readonly}
        fullWidth
        multiline={multiline}
        InputProps={
          isLocalized
            ? {
                endAdornment: (
                  <Tooltip title={t("kioForm.fields.LocalizedField.localizedField")}>
                    <InputAdornment position="end" sx={{}}>
                      <Language />
                    </InputAdornment>
                  </Tooltip>
                ),
              }
            : undefined
        }
        {...props}
      />
      {!!description && (
        <Tooltip id={`${name}-description`} title={description} arrow>
          <DescriptionIconContainer>
            <Info color="primary" />
          </DescriptionIconContainer>
        </Tooltip>
      )}
    </TextFieldWrapper>
  );
};

export default KioTextField;
