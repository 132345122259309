import Styles from "@/assets/styles/Styles";
import MediaSelectorDrawerContent from "@/components/MediaSelector/MediaSelectorDrawerContent";
import PersistentDrawer, { PersistentDrawerProps } from "@/components/PersistentDrawer";
import MediaType from "@/declarations/models/MediaType";
import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useRef, useState } from "react";

/**
 * A callback to run when the selection changes
 */
type SelectionHandler = (value: any) => void;

/**
 *
 */
export interface MediaSelectorProps extends Partial<Pick<PersistentDrawerProps, "position" | "width">> {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean | ((prev: boolean) => boolean)) => void;
}

/**
 * Definition of all data and operations available
 */
export interface MediaSelectorAPI {
  openMediaDrawer: (
    availableMediaTypes: Array<MediaType>,
    onSelectHandler: SelectionHandler,
    selectMultiple?: boolean,
    selectedItems?: string[]
  ) => void;
  closeMediaDrawer: () => void;
  isOpen: boolean;
}

/**
 *
 */
const MediaSelectorApi = createContext<MediaSelectorAPI>({} as MediaSelectorAPI);

/**
 * This hook provides access to the Media Selector API.
 */
export function useMediaSelector(): MediaSelectorAPI {
  return useContext(MediaSelectorApi);
}

/**
 *
 * @param open
 * @param setOpen
 * @param children
 * @param width
 * @param position
 * @param topOffset
 * @constructor
 */
export const MediaSelector: FC<MediaSelectorProps> = ({ open, setOpen, children, width, position }) => {
  const [availableMediaTypes, setAvailableMediaTypes] = useState<Array<MediaType>>([]);
  const selectionHandlerRef = useRef<SelectionHandler | null>(null);
  const [selectMultiple, setSelectMultiple] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<string[]>();

  const openMediaDrawer = useCallback(
    (mediaTypes: Array<MediaType>, handler: SelectionHandler, selectMultiple?: boolean, selectedList?: string[]) => {
      if (!!selectionHandlerRef.current) {
        console.error("MediaSelector is busy. Please finish the ongoing operation");
        return;
      }
      if (!handler) {
        console.warn("No handler available. Refusing to open MediaSelector");
        return;
      }
      if (!mediaTypes?.length) {
        console.error("No MediaTypes available. Refusing to open MediaSelector.");
        return;
      }
      setAvailableMediaTypes(mediaTypes);
      selectionHandlerRef.current = handler;
      setSelectMultiple(!!selectMultiple);
      !!selectedList && setSelectedList(selectedList);
      setOpen(true);
    },
    [setOpen]
  );

  const closeMediaDrawer = useCallback(() => {
    selectionHandlerRef.current = null;
    setOpen(false);
  }, [setOpen]);

  const mediaSelectorAPI = useMemo<MediaSelectorAPI>(
    () => ({
      openMediaDrawer,
      closeMediaDrawer,
      isOpen: open,
    }),
    [open, openMediaDrawer, closeMediaDrawer]
  );

  const handleSelectionChanged: SelectionHandler = (value) => {
    selectionHandlerRef.current?.(value);
  };

  return (
    <MediaSelectorApi.Provider value={mediaSelectorAPI}>
      <PersistentDrawer
        open={open}
        position={position || "left"}
        width={width || Styles.Dimensions.MEDIA_SELECTOR_DRAWER_WIDTH}
        backgroundColor={Styles.Colors.WHITE}
        drawerContent={
          <MediaSelectorDrawerContent
            availableMediaTypes={availableMediaTypes}
            onSelect={handleSelectionChanged}
            onClose={closeMediaDrawer}
            drawerOpen={open}
            selectMultiple={selectMultiple}
            selectedList={selectedList}
          />
        }
      >
        {children}
      </PersistentDrawer>
    </MediaSelectorApi.Provider>
  );
};

export default MediaSelector;
