import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./fonts.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./Store";
import { ErrorBoundary } from "react-error-boundary";
import ErrorView from "./views/ErrorView";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { unstable_createMuiStrictModeTheme } from "@mui/material";
import Settings from "./Settings";
import i18n from "./i18n/config";
import { I18nextProvider } from "react-i18next";
// @ts-ignore
import { createRoot } from "react-dom/client";

const theme = unstable_createMuiStrictModeTheme(Settings.THEME_OPTIONS);

//TODO, REACT 18 render, enable this once @rjsf version 5.X is out of beta.
/*
const container = document.getElementById('root');

const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <StoreProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StoreProvider>
      </ErrorBoundary>
    </I18nextProvider>
  </StrictMode>
);
*/

ReactDOM.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <StoreProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StoreProvider>
      </ErrorBoundary>
    </I18nextProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: setup reporting of WebVitals
reportWebVitals();
