import { Route, Routes as Switch, useNavigate, useLocation } from "react-router-dom";
import CreateOrEdit, { CreateOrEditProps } from "@/components/CreateOrEdit";
import React, { ReactNode } from "react";
import { JSONSchema7 } from "json-schema";
import BaseModel from "@/declarations/models/BaseModel";
import DataList, { DataListProps } from "@/components/DataList/DataList";
import { styled } from "@mui/material";

export interface CreateOrEditRoutesProps<T> extends Pick<CreateOrEditProps<T>, "beforeContent" | "afterContent"> {
  listProps: Omit<DataListProps<T>, "onItemClick">;
  schema: JSONSchema7;
  uiSchema: any;
  getFormData?: CreateOrEditProps<T>["getFormData"];
  createFormData?: CreateOrEditProps<T>["onSubmit"];
  updateFormData?: CreateOrEditProps<T>["onSubmit"];
  goBackOnSubmit?: boolean;
  onFormDataChanged?: (formData: T) => void;
  previewContent?: ReactNode;
  previewWidth?: string;
}

const DataListWrapper = styled("div")`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CreateOrEditRoutes = <T extends BaseModel>({
  schema,
  uiSchema,
  getFormData,
  createFormData,
  updateFormData,
  listProps,
  goBackOnSubmit = false,
  onFormDataChanged,
  previewContent,
  previewWidth,
  beforeContent,
  afterContent,
}: CreateOrEditRoutesProps<T>) => {
  const { pathname } = useLocation();
  const history = useNavigate();
  if (listProps.listTitle) document.title = listProps.listTitle;

  const editItem = (item: T) => {
    history(`${pathname}/${item.id}`);
  };
  const path = pathname.split("/");
  const createOrEdit = path[3] || "";
  const schemaType = path[2];
  uiSchema["ui:title"] =
    createOrEdit === "create" ? "schemaTypes.create_" + schemaType : "schemaTypes.edit_" + schemaType;

  return (
    <Switch>
      {createFormData && (
        <Route
          path={`/create`}
          element={
            <CreateOrEdit
              schema={schema}
              uiSchema={uiSchema}
              previewContent={previewContent}
              previewWidth={previewWidth}
              onChange={onFormDataChanged}
              onSubmit={createFormData}
              goBackOnSubmit={goBackOnSubmit}
            />
          }
        />
      )}
      {updateFormData && (
        <Route
          path={`/:id`}
          element={
            <CreateOrEdit
              schema={schema}
              uiSchema={uiSchema}
              beforeContent={beforeContent}
              afterContent={afterContent}
              previewContent={previewContent}
              previewWidth={previewWidth}
              getFormData={getFormData}
              onSubmit={updateFormData}
              onChange={onFormDataChanged}
              goBackOnSubmit={goBackOnSubmit}
            />
          }
        />
      )}
      <Route
        path={"/"}
        element={
          <>
            <DataListWrapper>
              <DataList
                {...listProps}
                createNewButton={{
                  onCreateNew: () => history(`${pathname}/create`),
                  buttonLabel: `schemaTypes.create_${schemaType}`,
                }}
                onItemClick={editItem}
              />
            </DataListWrapper>
          </>
        }
      />
    </Switch>
  );
};

export default CreateOrEditRoutes;
