import React from "react";
import { styled, Typography } from "@mui/material";
import { limit } from "@/utils/numbers";

const Title = styled(Typography)({
  textAlign: "left",
  display: "-webkit-box",
  overflow: "hidden",
  wordBreak: "break-word",
  textOverflow: "ellipsis",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical",
  fontWeight: 700,
  "&.title-0": {
    fontSize: "22px",
  },
  "&.title-1": {
    fontSize: "18px",
  },
  "&.title-2": {
    fontSize: "16px",
  },
  "&.title-3": {
    fontSize: "14px",
  },
});

const Container = styled("div")({
  display: "flex",
  "&.row": {
    flexFlow: "row",
    columnGap: "8px",
    alignItems: "baseline",
  },
  "&.column": {
    flexFlow: "column",
  },
});

interface TitleProps {
  title?: string;
  description?: string;
  level?: number;
  isRow?: boolean;
}

const KioTitle = ({ title, description, level = 1, isRow = false }: TitleProps) => {
  return (
    <Container className={isRow ? "row" : "column"}>
      {title && <Title className={`title-${limit(level, 0, 3)}`}>{title}</Title>}
      {description && (
        <Title variant={"subtitle1"} color={"textSecondary"} fontSize={"13px"}>
          {description}
        </Title>
      )}
    </Container>
  );
};

export default KioTitle;
