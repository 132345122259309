import DocumentSelector from "@/components/DocumentSelector/DocumentSelector";
import DocumentReference from "@/declarations/models/DocumentReference";
import { useEditorStore } from "@/EditorContextProvider";
import { RelationFieldOptions } from "@/framework/KioForm/fields/DocumentRelationField";
import ArrayRelationFieldItem from "@/framework/KioForm/fields/documentRelationFieldVariants/ArrayRelationFieldItem";
import { KioFieldProps } from "@/framework/KioForm/KioFieldProps";
import { KioFormContext } from "@/framework/KioForm/KioForm";
import ArrayFieldTemplate from "@/framework/KioForm/templates/array/ArrayFieldTemplate";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { getSchemaTitle } from "@/utils/schema";
import { IdSchema, utils } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import React, { FC, useEffect, useRef, useState } from "react";

const ArrayRelationField: FC<KioFieldProps> = ({
  schema,
  idSchema,
  uiSchema,
  formContext,
  formData,
  onChange,
  registry,
  errorSchema,
}) => {
  const { state: editorState, addDocumentIds } = useEditorStore();
  const { success } = useMessenger();
  const mutableData = useRef<DocumentReference[]>(Array.isArray(formData) ? formData : []);

  const itemSchema = Array.isArray(schema.items) ? (schema.items[0] as JSONSchema7) : (schema.items as JSONSchema7);
  const uiOptions = uiSchema["ui:options"] as any as RelationFieldOptions;
  const [items, setItems] = useState<any[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // used to force onChange when adding item

  useEffect(
    () => () => {
      timeoutRef.current !== null && clearTimeout(timeoutRef.current);
    },
    []
  );

  useEffect(() => {
    if (Array.isArray(formData)) addDocumentIds(formData.map((item: DocumentReference) => item.to_document_id));
    else if (formData && formData.hasOwnProperty("to_document_id")) addDocumentIds([formData.to_document_id]);
  }, [formData]);

  const onChangeForIndex = (index: number) => {
    return (val: DocumentReference) => {
      const existingFormData = [...mutableData.current];
      const newFormData = [
        ...existingFormData.slice(0, index),
        val,
        ...existingFormData.slice(index + 1, existingFormData.length),
      ];
      onChange(newFormData);
      mutableData.current = newFormData;
    };
  };

  const onDropIndex = (index: number) => () => {
    const newFormData = [...mutableData.current.slice(0, index), ...mutableData.current.slice(index + 1)];
    onChange(newFormData);
    mutableData.current = newFormData;
  };

  const onReorder = (index: number, newIndex: number) => () => {
    const newFormData = mutableData.current.slice();
    newFormData.splice(index, 1);
    newFormData.splice(newIndex, 0, mutableData.current[index]);
    onChange(newFormData);
    mutableData.current = newFormData;
  };

  const mapRelationToArrayTemplateItem = (item: any, i: number, length: number) => {
    const relation = (editorState.documentRelations || []).find((rel) => rel.document_id === item.to_document_id);
    if (relation) {
      const itemIdSchema = utils.toIdSchema(itemSchema, idSchema.$id + "_" + i, registry, relation) as IdSchema;
      return {
        className: "",
        disabled: false,
        hasMoveDown: i < length - 1,
        hasMoveUp: i > 0,
        hasRemove: true,
        hasToolbar: true,
        index: i,
        onDropIndexClick: onDropIndex,
        onReorderClick: onReorder,
        readonly: false,
        key: `${relation.document_id}`,
        description: relation.status,
        children: (
          <ArrayRelationFieldItem
            key={relation.document_id}
            documentRelation={relation}
            schema={itemSchema}
            uiSchema={uiSchema}
            formData={formData?.[i]}
            idSchema={itemIdSchema}
            errorSchema={errorSchema?.[i]}
            registry={registry}
            formContext={formContext}
            onChange={onChangeForIndex(i)}
            uiOptions={uiOptions}
            autofocus={false}
            disabled={false}
            required={false}
            name={"item-" + i}
            onBlur={() => {}}
            onFocus={() => {}}
            readonly={false}
          />
        ),
      };
    }
  };

  const addDocument = (documentId: number) => {
    if (!!documentId && !mutableData.current.some((ref: DocumentReference) => ref.to_document_id === documentId)) {
      const newFormData = [...mutableData.current, { to_document_id: documentId }];
      timeoutRef.current = setTimeout(() => {
        onChange(newFormData);
        timeoutRef.current = null;
      });
      mutableData.current = newFormData;
      success("kioForm.fields.DocumentRelationField.documentAddedSuccess", 2000);
    }
  };

  useEffect(() => {
    if (Array.isArray(formData)) {
      const newItems = formData
        .map((item, i) => mapRelationToArrayTemplateItem(item, i, formData.length))
        .filter((item) => !!item);
      !!newItems && setItems(newItems);
    }
  }, [formData, editorState.documentRelations]);

  return (
    <>
      <ArrayFieldTemplate
        canAdd={false}
        className={""}
        disabled={false}
        idSchema={idSchema}
        items={items}
        onAddClick={() => {}}
        readonly={false}
        required={false}
        schema={schema}
        uiSchema={uiSchema}
        title={getSchemaTitle(formContext, schema, uiSchema, formData)}
        formContext={formContext}
        formData={formData}
        registry={registry}
      />
      <DocumentSelector
        multiple
        selectedItems={items.map((item) => item.key)}
        onSelect={addDocument}
        selectedLanguage={(formContext as KioFormContext).selectedLocale}
        uiOptions={uiOptions}
      />
    </>
  );
};

export default ArrayRelationField;
