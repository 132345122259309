import Styles from "@/assets/styles/Styles";
import { CustomButton } from "@/components/DataList/common/DataListOptions";
import DataListHeader, { DataListHeaderProps } from "@/components/DataList/ListHeader/DataListHeader";
import { DataListRow, DataListRowInternal } from "@/components/DataList/DataListRow";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import GridView from "@/components/DataList/ViewTypes/GridView";
import ListView, { DataListViewProps } from "@/components/DataList/ViewTypes/ListView";
import { KioPaginatorProps } from "@/components/KioPaginator";
import PaginationResult from "@/declarations/PaginationResult";
import Loader from "@/framework/Loader";
import { useDepSafeValue } from "@/hooks/useDepSafeCallback";
import { useLoadingState } from "@/hooks/useLoadingState";
import Settings from "@/Settings";
import { resolvePotentiallyLocalizedString } from "@/utils/obj";
import { cancellablePromise } from "@/utils/async";
import { Pagination, styled, Typography } from "@mui/material";
import React, { ReactNode, useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

export interface CommonDataListProps {
  onTagClicked?: (tag: string) => void;
  altUpdatedAt?: boolean;
  fallbackImage?: string;
  hideUpdated?: boolean;
  hideImage?: boolean;
  denseOptions?: boolean;
  customListItemButtons?: Array<CustomButton>;
}

export interface DataListProps<T>
  extends CommonDataListProps,
    Pick<KioPaginatorProps, "pageSizes" | "defaultPageSize" | "showFirstLastButtons" | "showPreviousNextButtons">,
    Pick<
      DataListHeaderProps,
      | "disableSorting"
      | "disableViewTypeSelection"
      | "onToggle"
      | "toggleLabel"
      | "listTitle"
      | "sortOptions"
      | "initialSortOption"
      | "filterOptions"
      | "filter"
      | "createNewButton"
      | "searchProp"
      | "customBatchButtons"
    > {
  children?: ReactNode;
  /**
   * A mapper-function to map a generic item to a common ListItemData-object
   * @param item
   */
  mapperFn: (item: T) => DataListRow;
  /**
   * This function should generate a promise that resolves an Array of items to display,
   * based on the provided page and page number.
   * The actual "fetching" will be handled by this List-component.
   * @param page The page to fetch
   * @param pageSize the number of items to fetch
   */
  getItems: (page: number, pageSize: number) => Promise<PaginationResult<T>>;
  onSelectionChanged?: (selectedItems: Array<T>) => void;
  onItemClick?: (item: T) => void;
  onEditItem?: (item: T) => void;
  /**
   * Deleting an item is usually an async operation.
   * Therefore, if the returned promise is resolved,
   * it is assumed that the delete-operation was successful, and the "getItems(...)" callback is called again to refresh the page.
   * If it is rejected, it is assumed that the delete-operation failed, and no action will be taken.
   * @param item
   */
  onDeleteItem?: (item: T) => Promise<void>;
  onDeleteItemForever?: (item: T) => Promise<void>;
  onUndeleteItem?: (item: T) => Promise<void>;
  handleOnItemsChanged?: (sortProp?: string, sortDirection?: string, filter?: string) => void;
  selectedItems?: string[];
  disablePagination?: boolean;
  externalDataChanged?: {};
  resetPageDeps?: any[];
  disableSelection?: boolean;
  defaultViewType?: ViewType;
  selectedLanguage?: string;
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  overflow: "hidden",
  height: "100%",
  width: "100%",
});

const ItemsContainer = styled("div")({
  height: "100%",
  overflow: "hidden auto",
});

const NoContentText = styled(Typography)({
  textAlign: "center",
  display: "block",
});

const PaginatorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2, 0),
  borderTop: `1px solid ${theme.palette.divider}`,
  "& .MuiPaginationItem-page.Mui-selected": {
    background: Styles.Colors.PAGINATION_SELECTED_BACKGROUND_COLOR,
  },
}));

export const DataList = <T extends object>({
  children,
  listTitle,
  getItems,
  mapperFn,
  selectedItems,
  onSelectionChanged,
  onItemClick,
  onEditItem,
  onDeleteItem,
  onDeleteItemForever,
  onUndeleteItem,
  onToggle,
  handleOnItemsChanged,
  onTagClicked,
  disablePagination = false,
  disableSorting = false,
  disableViewTypeSelection = false,
  pageSizes = [5, 10, 25, 50, 100],
  defaultPageSize = 10,
  showFirstLastButtons = false,
  showPreviousNextButtons = true,
  toggleLabel = "",
  hideUpdated = false,
  hideImage = false,
  altUpdatedAt = false,
  denseOptions = false,
  externalDataChanged,
  disableSelection,
  defaultViewType = ViewType.LIST,
  fallbackImage,
  selectedLanguage = Settings.DEFAULT_LOCALE,
  sortOptions,
  initialSortOption,
  filterOptions,
  filter,
  createNewButton,
  customListItemButtons,
  resetPageDeps = [],
  searchProp,
  customBatchButtons,
}: DataListProps<T>) => {
  const { t } = useTranslation("common");
  const getPotentiallyLocalizedString = resolvePotentiallyLocalizedString(selectedLanguage);

  const onSelectionChangedRef = useDepSafeValue(onSelectionChanged);
  const getItemsRef = useDepSafeValue(getItems);
  const mapperRef = useDepSafeValue((item: T): DataListRowInternal<T> => {
    const dataRow: DataListRow = mapperFn(item);
    return {
      ...dataRow,
      title: getPotentiallyLocalizedString(dataRow.title) ?? "",
      subTitle: getPotentiallyLocalizedString(dataRow.subTitle) ?? "",
      sourceItem: item,
    };
  });
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [isGridView, setIsGridView] = useState<boolean>(defaultViewType === ViewType.GRID);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(defaultPageSize);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState<number>(0);
  const [items, setItems] = useState<Array<DataListRowInternal<T>>>([]);
  const paginationDisabled = disablePagination || totalNumberOfItems <= defaultPageSize;
  const [selectionState, setSelectionState] = useReducer(
    (state: { [id: string]: T | null }, [id, selected]: [id: string, selected: T | null]) => ({
      ...state,
      [id]: selected,
    }),
    {}
  );

  // Go back to page 1 (0) when various states are changed
  useEffect(() => {
    currentPage !== 0 && setCurrentPage(0);
  }, [filter, ...resetPageDeps]);

  const handlePageChange: KioPaginatorProps["onPageChange"] = (page, pageSize) => {
    const lastPageIndex = totalNumberOfItems > 0 ? Math.ceil(totalNumberOfItems / pageSize) - 1 : 0;
    setCurrentPage(page >= lastPageIndex ? lastPageIndex : page);
    setCurrentPageSize(pageSize);
  };

  const handleSelectionChanged = (selected: boolean, key: string, item: T) => {
    setSelectionState([key, selected ? item : null]);
  };

  const reloadPage = useCallback(
    async (pageNumber: number, pageSize: number) => {
      if (!getItemsRef.current) {
        setItems([]);
        return;
      }
      try {
        startLoading();
        const paginationResult = await getItemsRef.current(pageNumber, pageSize);
        setTotalNumberOfItems(paginationResult?.total_count || 0);
        const items = paginationResult?.items || [];
        setItems(items.map((i) => ({ ...mapperRef.current(i), sourceItem: i })));
      } catch (e) {
        console.error(e);
        setItems([]);
      } finally {
        stopLoading();
      }
    },
    [getItemsRef, mapperRef]
  );

  const handleDeleteItem = async (item: DataListRowInternal<T>): Promise<void> => {
    if (!!onDeleteItemForever || window.confirm(t("components.list.confirmDelete"))) {
      try {
        await onDeleteItem?.(item.sourceItem);
        await reloadPage(currentPage, currentPageSize);
      } catch (e) {
        console.warn("Delete item failed:", item.sourceItem, e);
      }
    }
  };

  const handleDeleteItemForever = async (item: DataListRowInternal<T>): Promise<void> => {
    if (window.confirm(t("components.list.confirmDelete"))) {
      try {
        await onDeleteItemForever?.(item.sourceItem);
        await reloadPage(currentPage, currentPageSize);
      } catch (e) {
        console.warn("Delete item forever failed:", item.sourceItem, e);
      }
    }
  };

  const handleUndeleteItem = async (item: DataListRowInternal<T>): Promise<void> => {
    try {
      await onUndeleteItem?.(item.sourceItem);
      await reloadPage(currentPage, currentPageSize);
    } catch (e) {
      console.warn("Delete item forever failed:", item.sourceItem, e);
    }
  };

  useEffect(() => {
    // Load items on init and on page-change/page-size-change/external data change
    const [fetchItems, cancel] = cancellablePromise(reloadPage(currentPage, currentPageSize));
    fetchItems.then();
    return cancel;
  }, [currentPage, currentPageSize, reloadPage, externalDataChanged]);

  useEffect(() => {
    if (!onSelectionChangedRef.current) return;

    onSelectionChangedRef.current(
      Object.keys(selectionState)
        .filter((key) => selectionState[key] !== null)
        .map((key) => selectionState[key] as T)
    );
  }, [selectionState, onSelectionChangedRef]);

  const enableSelection: boolean =
    !disableSelection && (!!onSelectionChanged || !!onDeleteItem || !!onUndeleteItem || !!customBatchButtons?.length);
  const treatItemClickAsSelect: boolean = !onItemClick && enableSelection;
  const itemClickIsDisabled: boolean = !treatItemClickAsSelect && !onItemClick;
  const handleOnItemClick = (item: DataListRowInternal<T>) => {
    treatItemClickAsSelect
      ? handleSelectionChanged(!selectionState[item.key], item.key, item.sourceItem)
      : onItemClick?.(item.sourceItem);
  };

  const commonProps: DataListViewProps<T> = {
    items: items,
    getIsSelected: (item) => !!selectionState[item.key],
    onSelectChanged: (item, selected) => handleSelectionChanged(selected, item.key, item.sourceItem),
    onItemClick: !itemClickIsDisabled ? handleOnItemClick : undefined,
    onEdit: onEditItem ? (item) => onEditItem(item.sourceItem) : undefined,
    onDelete: onDeleteItem ? handleDeleteItem : undefined,
    onDeleteForever: onDeleteItemForever ? handleDeleteItemForever : undefined,
    onUndelete: onUndeleteItem ? handleUndeleteItem : undefined,
    onTagClicked,
    selectedItems,
    enableSelection,
    hideUpdated,
    altUpdatedAt,
    denseOptions,
    hideImage,
    fallbackImage,
    customListItemButtons,
  };

  return (
    <Container>
      {children}

      <DataListHeader
        items={items}
        listTitle={listTitle}
        onItemsChanged={handleOnItemsChanged}
        disableSorting={disableSorting}
        disablePagination={paginationDisabled}
        enableSelection={enableSelection}
        selectedViewType={isGridView ? ViewType.GRID : ViewType.LIST}
        setSelectedViewType={() => setIsGridView(!isGridView)}
        disableViewTypeSelection={disableViewTypeSelection}
        onToggle={onToggle}
        toggleLabel={toggleLabel}
        page={currentPage}
        pageSize={currentPageSize}
        availablePageSizes={pageSizes}
        totalCount={totalNumberOfItems}
        onPageChange={handlePageChange}
        onToggleSelection={(selectAll) =>
          items.forEach((item) => handleSelectionChanged(selectAll, item.key, item.sourceItem))
        }
        sortOptions={sortOptions}
        initialSortOption={initialSortOption}
        filterOptions={filterOptions}
        filter={filter}
        createNewButton={createNewButton}
        searchProp={searchProp}
        selectedKeys={items
          .filter((item) => Object.entries(selectionState).some(([key, value]) => item.key === key && !!value))
          .map((item) => item.key)}
        onDeleteItem={onDeleteItem}
        onUndeleteItem={onUndeleteItem}
        customBatchButtons={customBatchButtons}
      />

      <ItemsContainer>
        {isLoading ? (
          <Loader loadingText="components.list.loadingContent" />
        ) : !items?.length ? (
          <NoContentText variant="overline" color="textSecondary">
            {t("components.list.noContent")}
          </NoContentText>
        ) : (
          <>
            {isGridView && <GridView {...commonProps} />}
            {!isGridView && <ListView {...commonProps} />}
          </>
        )}
      </ItemsContainer>

      {!paginationDisabled && (
        <PaginatorContainer>
          <Pagination
            page={currentPage + 1}
            count={Math.ceil(totalNumberOfItems / currentPageSize) || 0}
            onChange={(_: any, newPage: number) => handlePageChange(newPage - 1, currentPageSize)}
            showFirstButton={showFirstLastButtons}
            showLastButton={showFirstLastButtons}
            hideNextButton={!showPreviousNextButtons}
            hidePrevButton={!showPreviousNextButtons}
            size="large"
          />
        </PaginatorContainer>
      )}
    </Container>
  );
};

export default DataList;
