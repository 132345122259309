import React, { FC } from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

interface SearchFieldProps extends Omit<TextFieldProps, "onChange"> {
  onChange: (input: any) => void;
}

export const SearchField: FC<SearchFieldProps> = ({ onChange, value, fullWidth = false, placeholder }) => {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {!value ? (
              <Search color="action" />
            ) : (
              <IconButton onClick={() => onChange("")} size={"small"} sx={{ padding: 0 }}>
                <Clear color="action" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      variant={"outlined"}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(e.target.value)}
      size={"small"}
      fullWidth={fullWidth}
      placeholder={placeholder}
    />
  );
};

export default SearchField;
