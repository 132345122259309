import DataList from "@/components/DataList/DataList";
import { DataListRow } from "@/components/DataList/DataListRow";
import SearchField from "@/components/SearchField";
import Document from "@/declarations/models/Document";
import PaginationResult from "@/declarations/PaginationResult";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import { useStore } from "@/Store";
import { Search } from "@mui/icons-material";
import { Box, InputAdornment, styled, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  schemaIds: number[];
  onSelect: (document: Document) => void;
  mapper: (document: Document) => DataListRow;
  selectedItems?: string[];
  selectedLanguage?: string;
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  height: "100%",
  width: "100%",
});

const DocumentListModalContent: FC<Props> = ({ schemaIds, onSelect, mapper, selectedItems, selectedLanguage }) => {
  const { state } = useStore();
  const instance = state.cmsContextInstance;
  const { t } = useTranslation("common");

  const [sortBy, setSortBy] = useState<string>("name");
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    if (sortProp && sortProp !== sortBy) setSortBy(sortProp);
    if (sortDirection) setSortAscending(sortDirection === "asc");

    setLastFetchedTimestamp(Date.now());
  };

  const refreshData = useDebounce<string>(500, () => setLastFetchedTimestamp(Date.now()));

  const updateInput = (value: string): void => {
    setSearchInput(value);
    refreshData();
  };

  async function getItems(page: number, pageSize: number): Promise<PaginationResult<Document>> {
    return Api.getAllDocuments({
      schema_ids: schemaIds,
      page: page,
      page_size: pageSize,
      application_instance_id: instance?.id,
      sort_by_title: sortBy === "name",
      sort: sortBy === "name" ? null : "$" + sortBy,
      search: searchInput,
      order_asc: sortAscending,
      locale: selectedLanguage,
    }).fetchDirect({
      page: 0,
      page_size: 10,
      items: [],
      total_count: 0,
      count: 0,
    });
  }

  return (
    <Container>
      <Box m={"16px 16px 0"}>
        <SearchField
          value={searchInput}
          fullWidth
          onChange={updateInput}
          placeholder={t("search.searchDocuments")}
          label={t("search.inputLabel")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {schemaIds && schemaIds.length ? (
        <DataList
          mapperFn={mapper}
          getItems={getItems}
          onItemClick={onSelect}
          selectedItems={selectedItems}
          handleOnItemsChanged={handleOnItemsChanged}
          externalDataChanged={lastFetchedTimestamp}
          resetPageDeps={[searchInput]}
          onTagClicked={updateInput}
        />
      ) : (
        <Typography color={"error"} margin={"16px auto"}>
          {t("components.relationField.missing.uiSchema.schemaIds")}
        </Typography>
      )}
    </Container>
  );
};

export default DocumentListModalContent;
