import React, { FC, useEffect, useRef } from "react";
import KioTextField from "@/framework/KioForm/common/KioTextField";
import { RelationFieldOptions } from "@/framework/KioForm/fields/DocumentRelationField";
import { KioFieldProps } from "@/framework/KioForm/KioFieldProps";
import { useTranslation } from "react-i18next";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import i18next from "i18next";

interface ArrayRelationFieldItemProps extends KioFieldProps {
  uiOptions: RelationFieldOptions;
  documentRelation: any;
}

const ArrayRelationFieldItem: FC<ArrayRelationFieldItemProps> = ({
  formContext,
  formData,
  uiOptions,
  schema,
  uiSchema,
  onChange,
  errorSchema,
  idSchema,
  registry,
  onTitleChange,
  documentRelation,
}) => {
  const { t } = useTranslation("common");
  const titleRef = useRef<string | null | undefined>(null);

  registry.formContext = {
    ...formContext,
    documentId: formData ? formData["to_document_id"] : null,
  };

  useEffect(() => {
    // Resolve the selected document's title.
    if (documentRelation === null || !!titleRef.current || !onTitleChange) return;

    if (!!documentRelation) {
      const title = resolvePotentiallyLocalizedString(formContext.selectedLocale || getLanguageCode(i18next.language))(
        documentRelation.title
      );
      titleRef.current = title;
      !!title && onTitleChange?.(title);
      if (!!title) return;
    }
  }, [formContext, onTitleChange]);

  if (!formData) return null;

  const onReferenceKeyChange = (key: string) => {
    onChange({
      ...formData,
      reference_key: key,
    });
  };

  const onContentChange = (content: any) => {
    onChange({
      ...formData,
      content: content,
    });
  };

  const SchemaField = registry.fields.SchemaField;
  const disabledContent = !formData["to_document_id"] || !formData["reference_key"];
  const content = schema?.properties?.["content"];
  return (
    <>
      <div style={uiOptions.displayReferenceKey ? undefined : { display: "none" }}>
        <KioTextField
          id={`${idSchema?.["reference_key"].$id}`}
          title={t("kioForm.fields.DocumentRelationField.referenceKey")}
          name={"reference_key"}
          value={formData["reference_key"] || ""}
          onChange={(e) => onReferenceKeyChange(e.target.value)}
        />
      </div>
      {!!content && typeof content !== "boolean" && (
        <div style={uiOptions.displayContentField ? undefined : { display: "none" }}>
          <SchemaField
            readonly={false}
            required={false}
            disabled={disabledContent}
            autofocus={false}
            schema={content}
            uiSchema={uiSchema["content"]}
            idSchema={idSchema?.["content"]}
            formData={formData["content"]}
            errorSchema={errorSchema?.["content"]}
            registry={registry}
            onChange={onContentChange}
            onBlur={() => {}}
            onFocus={() => {}}
            formContext={formContext}
            name={titleRef.current || ""}
          />
        </div>
      )}
    </>
  );
};

export default ArrayRelationFieldItem;
