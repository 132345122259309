import React, { FC, useState } from "react";
import { AddMediaContentProps } from "@/components/MediaSelector/AddMediaModal";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DMSearchResult } from "@/declarations/models/DMSearchResult";
import TabsLayout from "@/components/TabsLayout";
import SearchDMTabContent from "@/components/MediaSelector/forms/AddDMMedia/SearchDMTabContent";
import SelectedDMItemsTabContent from "@/components/MediaSelector/forms/AddDMMedia/SelectedDMItemsTabContent";
import EditDMMedia from "@/components/MediaSelector/forms/AddDMMedia/EditDMMedia";

enum DMTab {
  SEARCH = "search",
  SELECT = "select",
}

const TabContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  padding: theme.spacing(4, 1),
  gap: theme.spacing(2),
}));

export const AddDMMedia: FC<AddMediaContentProps> = ({ value, onMediaSaved, onCancel, ownerId, instanceId }) => {
  const { t } = useTranslation("common");
  const [selectedItems, setSelectedItems] = useState<Array<DMSearchResult>>([]);

  if (value) {
    return <EditDMMedia media={value} onCancel={onCancel} onSubmit={onMediaSaved} />;
  }

  const getTabLabel = (tab: DMTab): string => {
    switch (tab) {
      case DMTab.SEARCH:
        return t("components.MediaSelector.AddDMContent.searchTabLabel");
      case DMTab.SELECT:
        return t("components.MediaSelector.AddDMContent.selectedTabLabel", { count: selectedItems.length });
    }
    return "?";
  };

  return (
    <TabsLayout
      defaultSelectedTab={DMTab.SEARCH}
      tabs={[
        {
          value: DMTab.SEARCH,
          content: (
            <TabContentContainer>
              <SearchDMTabContent onSelectionChanged={setSelectedItems} onCancel={onCancel} />
            </TabContentContainer>
          ),
        },
        {
          value: DMTab.SELECT,
          content: (
            <TabContentContainer>
              <SelectedDMItemsTabContent
                selectedItems={selectedItems}
                onCancel={onCancel}
                onSubmit={onMediaSaved}
                ownerId={ownerId}
                instanceId={instanceId}
              />
            </TabContentContainer>
          ),
        },
      ]}
      ariaLabel={t("components.MediaSelector.AddDMContent.search")}
      getLabel={(t) => getTabLabel(t as DMTab)}
      keepContentMounted
    />
  );
};

export default AddDMMedia;
