import React, { FC, memo } from "react";
import { Skeleton, SkeletonProps } from "@mui/material";
import { Box } from "@mui/material";
import Styles from "@/assets/styles/Styles";

export interface MenuLoaderProps extends Pick<SkeletonProps, "animation" | "variant"> {
  numberOfMenus?: number;
  numberOfItems?: number;
}

export const MenuLoader: FC<MenuLoaderProps> = ({ numberOfMenus = 3, numberOfItems = 4, ...skeletonProps }) => (
  <>
    {[...new Array(numberOfMenus)].map((_, menuIndex) => (
      <Box pl={2} pr={2} key={menuIndex}>
        <Skeleton
          {...skeletonProps}
          width={Styles.Dimensions.MENU_LOADER_HEADING_WIDTH}
          height={Styles.Dimensions.MENU_LOADER_HEADING_HEIGHT}
        />
        {[...new Array(numberOfItems)].map((_, menuItemIndex) => (
          <Skeleton {...skeletonProps} key={menuItemIndex} height={Styles.Dimensions.MENU_LOADER_ITEM_HEIGHT} />
        ))}
      </Box>
    ))}
  </>
);

export default memo(MenuLoader);
