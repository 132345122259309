import BreadcrumbNode from "@/components/BreadcrumbNode";
import { StyledIcon } from "@/components/DataList/common/DataListOptions";
import DataListSelect from "@/components/DataList/common/DataListSelect";
import { SortOptions } from "@/components/DataList/ListHeader/SortSelector";
import KioPaginator from "@/components/KioPaginator";
import LocaleSelectorButton from "@/components/LocaleSelectorButton";
import SearchField from "@/components/SearchField";
import TimeDiffLabel from "@/components/TimeDiffLabel";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import Locale from "@/declarations/models/Locale";
import { TranslationKey } from "@/declarations/models/TranslationKey";
import PaginationResult from "@/declarations/PaginationResult";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import Settings from "@/Settings";
import TranslationEditor from "@/views/admin/translation/TranslationEditor";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, styled, Tooltip, Typography } from "@mui/material";
import i18next from "i18next";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const DEFAULT_TRANSLATION_KEYS_PAGE_SIZE: number = 25;
const sortOptions: Array<SortOptions> = [
  { prop: "translation_key", direction: "asc", label: "components.list.sort.byKeyAsc" },
  { prop: "translation_key", direction: "desc", label: "components.list.sort.byKeyDesc" },
  { prop: "updated_at", direction: "asc", label: "components.list.sort.byUpdatedDateAsc" },
  { prop: "updated_at", direction: "desc", label: "components.list.sort.byUpdatedDateDesc" },
];

async function getLocales(): Promise<Array<Locale>> {
  const page = await Api.getAllLocales({ page: 0, page_size: 100 }).fetchDirect(null);
  return page?.items || [];
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  & .MuiAccordionSummary-content {
    margin: 8px 0;
  }
`;

const ListContainer = styled("div")`
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  .MuiAccordionSummary-root {
    min-height: fit-content;
    margin: 4px 0;
  }
  .MuiAccordionSummary-content {
    margin: 8px 0;
  }
`;

const Toolbar = styled("div")`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const Row = styled("span")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
});

const HeaderRow = styled(Row)`
  padding: 16px 16px 0;
  & .MuiButton-root {
    font-size: 16px;
    line-height: 28px;
    text-transform: none;
    & .MuiSvgIcon-root {
      height: 28px;
      width: 28px;
    }
  }
`;

export const ManageTranslationsView: FC = () => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  const history = useNavigate();
  const title = t("schemaTypes.translation_plural");
  if (title) document.title = title;

  // useAsyncSafeState necessary?
  const [availableLocales, setAvailableLocales] = useAsyncSafeState<Array<Locale>>([]);
  const [selectedLocale, setSelectedLocale] = useAsyncSafeState<Locale | null>(null);

  const [currentPage, setCurrentPage] = useAsyncSafeState<PaginationResult<TranslationKey> | null>(null);
  const [currentExpandedPanel, setCurrentExpandedPanel] = useState<number>(0);
  const [currentSortOptionIndex, setCurrentSortOptionIndex] = useState<number>(2);

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => setSearchTerms(t || ""));

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [searchInput]);

  async function getTranslationKeys(
    page: number = 0,
    page_size: number = DEFAULT_TRANSLATION_KEYS_PAGE_SIZE
  ): Promise<PaginationResult<TranslationKey> | null> {
    return Api.getAllTranslationKeys({
      page,
      page_size,
      search: searchTerms,
      sort_by: sortOptions[currentSortOptionIndex].prop,
      order_asc: sortOptions[currentSortOptionIndex].direction === "asc",
    }).fetchDirect(null);
  }

  const handleSortChange = async (index: number | string) => {
    index = Number(index);
    const sortProp = sortOptions[index].prop;
    const sortDirection = sortOptions[index].direction;
    if (sortProp && sortDirection && currentPage && index !== currentSortOptionIndex) {
      setCurrentSortOptionIndex(index && index > 0 ? index : 0);
    }
  };

  useEffect(() => {
    if (currentPage?.page === 0) getTranslationKeys(currentPage.page, currentPage.page_size).then(setCurrentPage);
  }, [currentSortOptionIndex, searchTerms]);

  const handlePageChange = async (page: number, pageSize: number, forcedRefresh = false) => {
    if (forcedRefresh || currentPage?.page !== page || currentPage?.page_size !== pageSize) {
      setCurrentPage(await getTranslationKeys(page, pageSize));
    }
  };

  const handleSelectedPreviewLocaleChanged = async (locale: Locale | null) => {
    if (locale?.language_code) {
      await i18next.reloadResources(locale.language_code, "db");
      setSelectedLocale(locale);
    }
  };

  useEffect(() => {
    getLocales().then(setAvailableLocales);
  }, [setAvailableLocales]);

  const handleDeleteClick = async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, tk: TranslationKey) => {
    event.stopPropagation();
    if (window.confirm(t("components.list.confirmDelete"))) {
      !!tk.id
        ? Api.deleteTranslationKey(tk.id)
            .fetch()
            .then(() => {
              handlePageChange(
                currentPage ? currentPage?.page : 0,
                currentPage ? currentPage?.page_size : DEFAULT_TRANSLATION_KEYS_PAGE_SIZE,
                true
              );
            })
        : console.warn("Translation key id missing");
    }
  };

  return (
    <Container>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.TRANSLATION}`} />
      <HeaderRow>
        <KioTitle title={title} level={0} />
        <Button
          type="button"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => history(`${pathname}/create`)}
        >
          {t("schemaTypes.create_translation-key") || "generic.createNew"}
        </Button>
      </HeaderRow>
      <HeaderRow>
        <Row>
          <Typography>{t("views.admin.translations.selectPreviewLocale")}:</Typography>
          <LocaleSelectorButton
            value={selectedLocale?.language_code || Settings.DEFAULT_LOCALE}
            onSelectionChanged={handleSelectedPreviewLocaleChanged}
          />
        </Row>
        <SearchField value={searchInput} onChange={setSearchInput} placeholder={t("search.inputLabel")} />
        <DataListSelect
          items={sortOptions.map(({ label }, value) => ({ value, label: t(label) }))}
          value={currentSortOptionIndex}
          onChange={handleSortChange}
        />
      </HeaderRow>
      {!!currentPage?.items?.length && (
        <ListContainer>
          {currentPage.items.map((translationKey) => (
            <Accordion
              key={translationKey.id}
              expanded={translationKey.id === currentExpandedPanel}
              onChange={(_, expanded) => setCurrentExpandedPanel(expanded ? translationKey.id ?? 0 : 0)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Toolbar>
                  <Typography variant="body1" component="span" color="textPrimary">
                    {translationKey.translation_key}
                  </Typography>
                  <Row style={{ width: "55%", minWidth: "fit-content" }}>
                    {translationKey.updated_at && (
                      <TimeDiffLabel datetime={translationKey.updated_at} name={translationKey.updated_by || ""} />
                    )}
                    <Typography variant="body2" component="span" color="textSecondary">
                      {t(translationKey.translation_key, {
                        lng: selectedLocale?.language_code,
                        fallbackLng: [],
                        ns: "db",
                        defaultValue: "",
                      })}
                    </Typography>
                    <Tooltip title={t("arrayButtons.removeElement") || "Remove"}>
                      <StyledIcon onClick={(event) => handleDeleteClick(event, translationKey)}>
                        <Delete />
                      </StyledIcon>
                    </Tooltip>
                  </Row>
                </Toolbar>
              </AccordionSummary>
              <AccordionDetails>
                <TranslationEditor translationKey={translationKey} localesToEdit={availableLocales} />
              </AccordionDetails>
            </Accordion>
          ))}
        </ListContainer>
      )}
      <KioPaginator
        defaultPageSize={DEFAULT_TRANSLATION_KEYS_PAGE_SIZE}
        totalNumberOfItems={currentPage?.total_count || 0}
        onPageChange={handlePageChange}
        resetPageDeps={[currentSortOptionIndex, searchTerms]}
      />
    </Container>
  );
};

export default ManageTranslationsView;
