import FormButtons from "@/components/FormButtons";
import Image from "@/components/Image";
import { StyledDialog } from "@/components/MediaSelector/FocalPointModal";
import Media from "@/declarations/models/Media";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Slider,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

export interface FilterProps {
  brightness?: number;
  contrast?: number;
  saturate?: number;
  grayscale?: number;
  opacity?: number;
}

interface Filter {
  name: keyof FilterProps;
  min: number;
  max: number;
  step: number;
}

const FILTERS: Filter[] = [
  { name: "brightness", min: 0, max: 2, step: 0.05 },
  { name: "contrast", min: 0, max: 2, step: 0.05 },
  { name: "saturate", min: 0, max: 10, step: 0.2 },
  { name: "grayscale", min: 0, max: 1, step: 0.02 },
  { name: "opacity", min: 0, max: 1, step: 0.02 },
];

export const DEFAULT_FILTERS: FilterProps = { brightness: 1, contrast: 1, saturate: 1, grayscale: 0, opacity: 1 };

const SliderBox = styled("div")`
  display: flex;
  width: 100%;
  min-width: 400px;
  gap: 16px;
  & .MuiInput-root,
  .MuiSlider-root {
    min-width: 50px;
  }
`;
const SliderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Container = styled("div")`
  justify-content: center;
  display: flex;
  padding: 0 16px;
  gap: 16px;
`;
const ImgContainer = styled("div")`
  display: flex;
  max-height: min(80vh, 90vw, 100%);
  max-width: min(80vh, 70vw, 75%);
`;

interface FiltersModalProps {
  mediaItem: Media | null;
  originalFilters: FilterProps;
  open: boolean;
  handleClose: () => void;
  onFiltersChange?: (filtersObj: FilterProps) => void;
}

const ColorFiltersModal: FC<FiltersModalProps> = ({
  mediaItem,
  open,
  originalFilters,
  handleClose,
  onFiltersChange,
}) => {
  const { t } = useTranslation("common");
  const [filters, setFilters] = useState<FilterProps>(originalFilters);

  const updateFilters = () => {
    onFiltersChange!(filters);
    handleClose();
  };

  const onCancel = () => {
    setFilters(originalFilters);
    handleClose();
  };

  const handleSlider = (value: number | number[], keyName: string) => {
    const numberValue = typeof value === "number" ? value : value[0];
    setFilters({ ...filters, [keyName]: numberValue });
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, keyName: string) => {
    const numberValue = Number(e.target.value);
    if (!!numberValue || numberValue === 0) {
      setFilters({ ...filters, [keyName]: numberValue });
    }
  };

  return (
    <StyledDialog maxWidth={"xl"} open={open} onClose={onCancel}>
      <DialogTitle id="focalpointModal-title">
        <KioTitle title={t("components.MediaSelector.Filters.adjustFilters")} />
        <IconButton onClick={onCancel} className={"noPadding"}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container>
          <SliderContainer>
            {FILTERS.map((f) => (
              <SliderBox key={f.name}>
                <Typography width={"200px"} align={"right"}>
                  {t(`components.MediaSelector.Filters.${f.name}`)}
                </Typography>
                <Slider
                  value={filters[f.name]}
                  onChange={(_, value) => handleSlider(value, f.name)}
                  aria-labelledby="brightness-slider"
                  step={f.step}
                  min={f.min}
                  max={f.max}
                />
                <Input
                  value={filters[f.name]}
                  size="small"
                  onChange={(e) => handleInput(e, f.name)}
                  inputProps={{
                    step: f.step,
                    min: f.min,
                    max: f.max,
                    type: "number",
                    "aria-labelledby": "brightness-slider",
                  }}
                />
              </SliderBox>
            ))}
          </SliderContainer>
          <ImgContainer>
            <Image src={mediaItem?.src} alt={mediaItem?.name} filterProps={filters} />
          </ImgContainer>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setFilters(DEFAULT_FILTERS)} variant={"outlined"}>
          {t("generic.reset")}
        </Button>
        <FormButtons onSubmit={updateFilters} onCancel={onCancel} />
      </DialogActions>
    </StyledDialog>
  );
};
export default ColorFiltersModal;
