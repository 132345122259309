import { useCallback, useEffect, useRef } from "react";

/**
 * A custom hook that provides you with a function to check if your component is currently
 * mounted or unmounted. The returned function is safe to add to a DepsArray
 */
export const useIsMounted = (): (() => boolean) => {
  const isMounted = useRef<boolean>(true);

  const checkIsMounted = useCallback(() => {
    return isMounted.current;
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return checkIsMounted;
};
