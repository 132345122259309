import styled from "@emotion/styled";
import { Autocomplete, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PlacesProps {
  map: any;
  location: any;
  handleInputChange: any;
  onLocationChange: any;
  clickLocation: any;
  setClickLocation?: any;
}

const InputContainer = styled.div`
  display: grid;
  flex: 1 1 auto;
  gap: 16px;
  margin-left: 16px;
  height: fit-content;

  > div:first-of-type {
    grid-column: 1 / span 2;
  }

  svg {
    fill: #737373;
  }
`;
export const Places: FC<PlacesProps> = ({ map, location, handleInputChange, onLocationChange, clickLocation }) => {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [auto, setAuto] = useState<google.maps.places.PlacesService>();
  const [autoValue, setAutoValue] = useState<any>(null);

  const query = (val: any) => {
    const request = {
      query: val,
      fields: ["name", "geometry", "formatted_address"],
      locationBias: "IP_BIAS",
    };
    auto?.findPlaceFromQuery(
      request,
      (results: google.maps.places.PlaceResult[] | null, status: google.maps.places.PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          setSearchResults(results);
        }
      }
    );
  };

  useEffect(() => {
    if (map && !auto) {
      let service = new google.maps.places.PlacesService(map);
      setAuto(service);
    }
  }, [map, auto]);

  useEffect(() => {
    if (clickLocation != null) {
      setAutoValue(clickLocation);
      setSearchResults([]);
    }
  }, [clickLocation]);

  const autocompleteSelectHandler = (event: any, location: any) => {
    try {
      const coords = location.geometry.location;
      onLocationChange?.({ lat: coords.lat(), lng: coords.lng() });
      map.setZoom(9);
      map.setCenter(coords);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <InputContainer>
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("components.locationPickerField.search")}
            variant="filled"
            inputProps={{
              ...params.inputProps,
              "data-testid": "location-picker-field_input_search",
            }}
            onChange={(event) => query(event.target.value)}
            id="query"
          />
        )}
        options={searchResults}
        getOptionLabel={(option) => {
          return option.formatted_address ?? clickLocation ?? "";
        }}
        isOptionEqualToValue={(option, value) => {
          return option.formatted_address === value.formatted_address || clickLocation === value;
        }}
        value={autoValue}
        onChange={(event, newInputValue) => {
          setAutoValue(newInputValue);
          autocompleteSelectHandler(event, newInputValue);
        }}
      />
      <>
        <TextField
          type="number"
          inputProps={{
            "data-testid": "location-picker-field_input_latitude",
          }}
          label={t("generic.latitude")}
          value={location?.lat ?? ""}
          variant="filled"
          onChange={handleInputChange}
          id="lat"
        />
        <TextField
          type="number"
          inputProps={{
            "data-testid": "location-picker-field_input_longitude",
          }}
          label={t("generic.longitude")}
          value={location?.lng ?? ""}
          variant="filled"
          onChange={handleInputChange}
          id="lng"
        />
      </>
    </InputContainer>
  );
};
