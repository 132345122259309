import React, { FC } from "react";
import CreateOrEdit, { CreateOrEditProps } from "@/components/CreateOrEdit";
import { schema } from "@/declarations/schemas/user/schema";
import { uiSchema } from "@/declarations/schemas/user/uiSchema";
import { RequestContext } from "@/declarations/RequestContext";
import Api from "@/services/Api";
import KioUser from "@/declarations/models/KioUser";

const createUserFormData: CreateOrEditProps<KioUser>["onSubmit"] = (user: KioUser): RequestContext<KioUser> => {
  return Api.createUser(user);
};

export const CreateUserView: FC = () => {
  return <CreateOrEdit schema={schema} uiSchema={uiSchema} onSubmit={createUserFormData} />;
};

export default CreateUserView;
