import React, { FC, ReactNode, useEffect, useState } from "react";
import AuthenticationApi from "../../services/AuthenticationApi";
import { cancellablePromise } from "@/utils/async";

function hasToken(): boolean {
  return !!localStorage.getItem("kit.access_token");
}

export const EkulturLoginController: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(hasToken());

  useEffect(() => {
    const [checkValidToken, cancel] = cancellablePromise(AuthenticationApi.refreshToken());

    checkValidToken.then(() => setIsAuthenticated(hasToken())).catch(() => AuthenticationApi.login());

    return cancel;
  }, []);

  return <>{isAuthenticated && children}</>;
};

export default EkulturLoginController;
