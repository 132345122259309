import DataList from "@/components/DataList/DataList";
import { DataListRow } from "@/components/DataList/DataListRow";
import { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { MediaTypeManagerProps } from "@/components/MediaSelector/MediaTypeTabPanel";
import { DMSearchResult } from "@/declarations/models/DMSearchResult";
import Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import PaginationResult from "@/declarations/PaginationResult";
import { KioSelect, SelectOption } from "@/framework/KioForm/common/KioSelect";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useDebounce } from "@/hooks/useDebounce";
import { useLookup } from "@/hooks/useLookup";
import Api from "@/services/Api";
import styled from "@emotion/styled";
import { Box, FormControlLabel, Switch } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

export interface SearchDMTabContentProps
  extends Omit<MediaTypeManagerProps, "mediaTypes" | "selectMultiple" | "selectedList"> {}

const getDmTypes = (t: TFunction): SelectOption[] => [
  { label: t("dmTab.dmTypes.allTypes"), value: "*" },
  { label: t("dmTab.dmTypes.actor"), value: "Actor" },
  { label: t("dmTab.dmTypes.person"), value: "Person" },
  { label: t("dmTab.dmTypes.organization"), value: "Organization" },
  { label: t("dmTab.dmTypes.vessel"), value: "NavalVessel" },
  { label: t("dmTab.dmTypes.photograph"), value: "Photograph" },
  { label: t("dmTab.dmTypes.thing"), value: "Thing" },
  { label: t("dmTab.dmTypes.fineArt"), value: "Fineart" },
  { label: t("dmTab.dmTypes.architecture"), value: "Architecture" },
  { label: t("dmTab.dmTypes.story"), value: "Story" },
  { label: t("dmTab.dmTypes.folder"), value: "Folder" },
  { label: t("dmTab.dmTypes.building"), value: "Building" },
  { label: t("dmTab.dmTypes.exhibition"), value: "Exhibition" },
  { label: t("dmTab.dmTypes.video"), value: "Video" },
  { label: t("dmTab.dmTypes.audio"), value: "Audio" },
];

const DmFilterContainer = styled.div`
  display: flex;
  flex-flow: row;
  gap: 16px;
  margin: 16px 16px 0;
`;

const LabelText = styled.div`
  white-space: nowrap;
`;

function mapDMSearchResultToDataListItem(dm: DMSearchResult): DataListRow {
  return {
    key: String(dm.artifact_uniqueId || dm.identifier_id || dm.artifact_uuid),
    title: String(dm.artifact_ingress_title || dm.artifact_uniqueId || dm.identifier_id || "no title"),
    subTitle: dm.artifact_ingress_subjects?.join(", "),
    chipContent: dm.identifier_owner,
    imageURL: dm.thumbnail_src,
    updatedAt: dm.artifact_updatedDate,
  };
}

export const DMTabPanel: FC<SearchDMTabContentProps> = ({ onSelect = () => {}, instanceId, ownerId }) => {
  const { t } = useTranslation("common");
  const { error } = useMessenger();
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const queryCache = useLookup<PaginationResult<DMSearchResult>>();
  const [dmType, setDmType] = useState<string>("*");
  const [onlyOpenLicenses, setOnlyOpenLicenses] = useState<boolean>(false);
  const [includeNoPictures, setIncludeNoPictures] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setCurrentQuery(t || "");
    setLastUpdated(Date.now());
  });

  useEffect(() => {
    searchInput.length !== 1 && debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput]);

  const updateDmType = (value: string): void => {
    setDmType(value);
    setLastUpdated(Date.now());
  };

  const updateLicenseType = (value: boolean): void => {
    setOnlyOpenLicenses(value);
    setLastUpdated(Date.now());
  };

  const onToggleNoPictures = (checked: boolean) => {
    setIncludeNoPictures(checked);
    setLastUpdated(Date.now());
  };

  const onItemClick = async (item: DMSearchResult) => {
    if (item.artifact_uniqueId) {
      let savedMedia: Media | null = null;
      await Api.getMediaUnique({
        identifier: item.artifact_uniqueId,
        owner_id: ownerId ?? 0,
        application_instance_id: instanceId ?? 0,
        media_type: MediaType.DM,
      })
        .fetch()
        .then(([value, error, _]) => {
          if (value) savedMedia = value;
          if (
            error &&
            ((typeof error !== "string" && error.exception !== "KIONotFoundException") ||
              (typeof error === "string" && error !== "KIONotFoundException"))
          )
            console.error(error);
        });
      if (!savedMedia) {
        savedMedia = await Api.createMedia({
          media_type: MediaType.DM,
          src: item.thumbnail_src || `https://digitaltmuseum.org/${item.artifact_uniqueId}`,
          name: item.artifact_ingress_title,
          thumbnail_src: item.thumbnail_src || `https://digitaltmuseum.org/${item.artifact_uniqueId}`,
          filename: item.artifact_defaultMediaIdentifier || "no filename",
          identifier: item.artifact_uniqueId,
          owner_id: ownerId,
          application_instance_id: instanceId,
        }).fetchDirect(null);
      }
      savedMedia ? onSelect(savedMedia) : error("kioForm.widgets.MediaSelectorField.selectionError");
    }
  };

  const getItems = async (page: number, page_size: number): Promise<PaginationResult<DMSearchResult>> => {
    let query = currentQuery;
    //query = query.concat("&artifact.type=Person")
    const cacheKey = `${page}-${page_size}-${query}-${dmType}-${onlyOpenLicenses}-${includeNoPictures}`.toLowerCase();
    const cachedPage = queryCache.getItem(cacheKey);
    if (cachedPage) {
      return cachedPage;
    }
    const result = await Api.searchDM({
      page,
      page_size,
      query,
      dm_type: dmType,
      open_licenses_only: onlyOpenLicenses,
      include_no_pictures: includeNoPictures,
    }).fetchDirect({
      page,
      page_size,
      items: [],
      count: 0,
      total_count: 0,
    });
    queryCache.setItem(cacheKey, result);
    return result;
  };

  return (
    <Box height={"calc(100% - 56px)"}>
      <DmFilterContainer>
        <KioSelect
          label={t("dmTab.dmType")}
          onChange={(value) => updateDmType(value)}
          value={dmType}
          options={getDmTypes(t)}
          isHeaderVariant
        />
        <FormControlLabel
          control={<Switch checked={onlyOpenLicenses} onChange={(e) => updateLicenseType(e.target.checked)} />}
          label={<LabelText>{t("dmTab.licenseType")}</LabelText>}
        />
      </DmFilterContainer>
      <DataList
        listTitle={t("components.MediaSelector.AddDMContent.searchResult")}
        getItems={getItems}
        mapperFn={mapDMSearchResultToDataListItem}
        externalDataChanged={lastUpdated}
        onItemClick={onItemClick}
        disableSorting
        defaultPageSize={10}
        resetPageDeps={[currentQuery, dmType, onlyOpenLicenses, includeNoPictures]}
        onToggle={onToggleNoPictures}
        toggleLabel={"dmTab.includeNoPictures"}
        searchProp={
          {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.searchDM"),
          } as SearchProp
        }
      />
    </Box>
  );
};

export default DMTabPanel;
