export enum MediaType {
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  YOUTUBE = "youtube",
  VIMEO = "vimeo",
  SKETCHFAB = "sketchfab",
  DOCUMENT = "document",
  DM = "dm",
}

export default MediaType;
