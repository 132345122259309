import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";

export interface DataListToggleProps {
  onToggle?: (selected: boolean) => void;
  toggleLabel?: string;
}

const DataListToggle: FC<DataListToggleProps> = ({ onToggle, toggleLabel = "" }) => {
  const { t } = useTranslation("common");
  const [isChecked, setIsChecked] = useState(false);
  const changeHandler = (_: React.ChangeEvent<HTMLInputElement>, selected: boolean) => {
    onToggle?.(selected);
    setIsChecked(selected);
  };

  return (
    <FormControl>
      <FormControlLabel control={<Checkbox onChange={changeHandler} checked={isChecked} />} label={t(toggleLabel)} />
    </FormControl>
  );
};
export default DataListToggle;
