import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import AdminView from "@/views/admin/AdminView";
import ApiKeyView from "@/views/admin/ApiKeyView/ApiKeyView";
import ApplicationView from "@/views/admin/ApplicationView/ApplicationView";
import InstanceView from "@/views/admin/InstanceView";
import LicenseView from "@/views/admin/LicenseView";
import LocaleView from "@/views/admin/LocaleView";
import OwnerView from "@/views/admin/OwnerView";
import SchemaView from "@/views/admin/SchemaView/SchemaView";
import TranslationView from "@/views/admin/translation/TranslationView";
import CreateUserView from "@/views/admin/user/CreateUserView";
import SingleUserView from "@/views/admin/user/SingleUserView";
import UserView from "@/views/admin/user/UserView";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

export const AdminRootView = () => {
  const { pathname } = useLocation();
  const getPath = (resource: AdminResourcePath): string => `${resource.toString()}`;

  return (
    <Routes>
      <Route
        path={getPath(AdminResourcePath.USER) + "/:user_id"}
        element={
          <AdminView>
            <SingleUserView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.USER) + "/create"}
        element={
          <AdminView>
            <CreateUserView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.USER)}
        element={
          <AdminView>
            <UserView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.TRANSLATION) + "/*"}
        element={
          <AdminView>
            <TranslationView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.APPLICATION) + "/*"}
        element={
          <AdminView>
            <ApplicationView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.OWNER) + "/*"}
        element={
          <AdminView>
            <OwnerView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.LICENSE) + "/*"}
        element={
          <AdminView>
            <LicenseView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.API_KEY) + "/*"}
        element={
          <AdminView>
            <ApiKeyView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.SCHEMA) + "/*"}
        element={
          <AdminView>
            <SchemaView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.LOCALE) + "/*"}
        element={
          <AdminView>
            <LocaleView />
          </AdminView>
        }
      />
      <Route
        path={getPath(AdminResourcePath.INSTANCE) + "/*"}
        element={
          <AdminView>
            <InstanceView />
          </AdminView>
        }
      />
      <Route
        path={"/"}
        element={
          <AdminView>
            <Navigate to={pathname + "/owner"} />
          </AdminView>
        }
      />
    </Routes>
  );
};
