import { SortDirection } from "@/declarations/SortDirection";

/*
 * Sorting utilities
 */

/**
 *
 * @param num1
 * @param num2
 * @param direction
 */
export function sortNumber(num1: number, num2: number, direction: SortDirection = "asc"): number {
  return direction === "asc" ? num2 - num1 : num1 - num2;
}

/**
 *
 * @param date1
 * @param date2
 * @param direction
 */
export function sortDate(
  date1: number | string | Date,
  date2: number | string | Date,
  direction: SortDirection = "asc"
): number {
  const d1: number = new Date(date1).getTime();
  const d2: number = new Date(date2).getTime();
  return sortNumber(d1, d2, direction);
}

/**
 * Sort a string, ignoring the case.
 * @param str1
 * @param str2
 * @param direction
 */
export function sortString(str1: string, str2: string, direction: SortDirection = "asc"): number {
  const a = (str1 || "").toLowerCase();
  const b = (str2 || "").toLowerCase();
  const isAsc = direction === "asc";
  const comparator = Intl.Collator(["no", "sv", "en", "de", "es", "fr"]).compare(a, b);

  return isAsc ? comparator : comparator * -1;
}
