import { FC, useEffect, useMemo } from "react";
import { FieldTemplateProps } from "@rjsf/core";
import styled from "@emotion/styled";
import { getValueFromUiSchema } from "@/utils/schema";

export interface KioUiSchemaProps {
  colspan?: number;
  rowspan?: number;
  colOffset?: number;
  rowOffset?: number;
  spacing?: number;
  backgroundColor?: string;
  minWidth?: string;
  minHeight?: string;
}

const Container = styled.div<KioUiSchemaProps>`
  grid-column-start: ${({ colOffset }) => (!!colOffset ? colOffset : "auto")};
  grid-column-end: ${({ colspan }) => `span ${colspan || 12}`};

  grid-row-start: ${({ rowOffset }) => (!!rowOffset ? rowOffset + 1 : "auto")};
  grid-row-end: ${({ rowspan }) => `span ${rowspan || 1}`};

  background-color: ${({ backgroundColor }) => `${backgroundColor ? backgroundColor : "initial"}`};
  min-height: ${({ minHeight }) => `${minHeight ? minHeight : "initial"}`};
  min-width: ${({ minWidth }) => `${minWidth ? minWidth : "initial"}`};
  width: 100%;
`;

const FieldTemplate: FC<FieldTemplateProps> = ({ hidden, uiSchema, children, onChange }) => {
  // TODO: find better solution than this to manipulate formData from ArrayItems
  if (!!uiSchema.items) {
    const tempItems = children.props?.children?.[0]?.props?.uiSchema?.items;
    if (tempItems)
      children.props.children[0].props.uiSchema.items["ui:options"] = {
        ...tempItems["ui:options"],
        onChange: onChange,
      };
  }
  const uiSchemaProps = useMemo<KioUiSchemaProps>(
    () => ({
      colspan: getValueFromUiSchema("colspan", uiSchema),
      rowspan: getValueFromUiSchema("rowspan", uiSchema),
      colOffset: getValueFromUiSchema("colOffset", uiSchema),
      rowOffset: getValueFromUiSchema("rowOffset", uiSchema),
      spacing: getValueFromUiSchema("spacing", uiSchema),
      backgroundColor: getValueFromUiSchema("backgroundColor", uiSchema),
      minWidth: getValueFromUiSchema("minWidth", uiSchema),
      minHeight: getValueFromUiSchema("minHeight", uiSchema),
    }),
    [uiSchema]
  );
  if (hidden) return null;
  return <Container {...uiSchemaProps}>{children}</Container>;
};

export { FieldTemplate };
