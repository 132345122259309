import SketchfabLogo from "@/assets/img/logos/sketchfab.svg";
import EmbeddedContent from "@/components/EmbeddedContent";
import { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";
import React, { FC } from "react";

export const SketchfabPreview: FC<AudioPreviewProps> = ({ identifier, title }) => {
  return (
    <EmbeddedContent
      title={title || "3D model viewer"}
      src={`https://sketchfab.com/models/${identifier}/embed`}
      allowFullScreen
      allow="autoplay; fullscreen; xr-spatial-tracking"
      invalid={!identifier}
      fallbackImageSrc={SketchfabLogo}
    />
  );
};

export default SketchfabPreview;
