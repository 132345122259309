import { RequestContext } from "@/declarations/RequestContext";
import { KioFormContext } from "@/framework/KioForm/KioForm";
import Api from "@/services/Api";
import { getValueFromUiSchema } from "@/utils/schema";
import { Autocomplete, ListItem, styled, TextField } from "@mui/material";
import { UiSchema, WidgetProps } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import i18next from "i18next";
import { sortString } from "@/utils/sort";

const StyledListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== "aria-selected" })(
  ({ "aria-selected": selected, theme }) => `
    padding-left: ${selected ? "12px !important" : ""};
    border-left: ${selected ? `${theme.palette.primary.main} solid 4px` : ""};
  `
);

function getTitle(schema: JSONSchema7, uiSchema: UiSchema): string {
  return schema.title ?? getValueFromUiSchema("title", uiSchema) ?? "";
}

const EntityAutocompleteWidget: FC<WidgetProps> = ({
  options,
  onChange,
  value,
  required,
  disabled,
  formContext,
  uiSchema,
  ...rest
}) => {
  const { variant }: KioFormContext = formContext;
  const { t } = useTranslation("common");
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<object | null>(null);
  const [configError, setConfigError] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>("");
  const getLocalizedString = resolvePotentiallyLocalizedString(getLanguageCode(i18next.language));

  const displayFieldName = String(options["displayField"]);
  const valueFieldName = String(options["valueField"]);
  const getOptionLabel = (item: { [key: string]: any }) => item[displayFieldName];
  const title = getTitle(rest.schema, uiSchema);
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    // convert input value to one of the available options
    setAutocompleteValue(
      selectOptions.find((o) => {
        return o[valueFieldName]?.toString() === value?.toString();
      })
    );
  }, [value, selectOptions, valueFieldName]);

  useEffect(() => {
    // set selectOptions based on ui:options type
    let ctx: RequestContext<any>;
    switch (options.type) {
      case "TranslationKey":
        ctx = Api.getAllTranslationKeys({ sort_by: "translation_key" });
        break;
      case "Owner":
        ctx = Api.getAllOwners({ sort_by: "name" });
        break;
      case "License":
        ctx = Api.getAllLicenses({ sort_by: "name" });
        break;
      case "Application":
        ctx = Api.getAllApplications({ sort_by: "name" });
        break;
      case "Locale":
        ctx = Api.getAllLocales({ sort_by: "name" });
        break;
      case "CreditType":
        ctx = Api.getCreditTypes();
        break;
      case "Schema":
        ctx = Api.getAllSchemas(id ? { sort_by: "name", application_id: +id } : { sort_by: "name" });
        break;
      default:
        setConfigError(`Unsupported "type"`);
        return;
    }
    ctx
      .fetchDirect({ items: [] })
      .then(async (result) => result.items)
      .then((selOps: any[]) => {
        if (options.type !== "CreditType" && options.type !== "Owner") setSelectOptions(selOps);
        else if (options.type == "Owner")
          setSelectOptions(
            selOps.map((opt: any) => ({
              id: opt.id,
              name: getLocalizedString(opt.name),
            }))
          );
        else {
          const _selopsTemp = selOps.map((opt: any) => ({
            id: opt.id,
            name: t(`editMedia.creditTypes.${opt.name}`),
          }));
          _selopsTemp.sort((el1, el2) => sortString(el1.name, el2.name));
          setSelectOptions(_selopsTemp);
        }
      });
    return () => ctx.abort();
  }, [options]);

  const onChangeHandler = (e: ChangeEvent<{}>, newValue: { [key: string]: any } | null) => {
    setAutocompleteValue(newValue || null);
    onChange && onChange(newValue ? newValue[valueFieldName]?.toString() : undefined);
  };

  return (
    <Autocomplete
      aria-required={required}
      disabled={disabled || !!configError}
      getOptionLabel={getOptionLabel}
      options={selectOptions}
      value={autocompleteValue || null}
      defaultValue={null}
      inputValue={inputValue}
      onInputChange={(_, newValue) => setInputValue(newValue)}
      onChange={onChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          disabled={disabled || !!configError}
          required={required}
          label={title}
          helperText={configError || getValueFromUiSchema("help", uiSchema)}
          fullWidth
        />
      )}
      renderOption={(props, option) => <StyledListItem {...props}>{getOptionLabel(option)}</StyledListItem>}
      fullWidth
      isOptionEqualToValue={(option, value) => value.id === option.id}
    />
  );
};

export default EntityAutocompleteWidget;
