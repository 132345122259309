import Styles from "@/assets/styles/Styles";
import DocumentSearchHit from "@/declarations/models/DocumentSearchHit";
import SearchHit from "@/framework/SearchHit";
import { useDebounce } from "@/hooks/useDebounce";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { Search } from "@mui/icons-material";
import { Autocomplete, CircularProgress, InputAdornment, Paper, styled, TextField } from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const HitList = styled(Paper)`
  max-width: 600px;
  min-width: 100%;
  width: fit-content;
  margin: 0;
  ul {
    padding: 0;
  }
`;

const LabelWithIcon = styled("div")`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background: ${Styles.Colors.MENU_INPUT_BACKGROUND_COLOR};
    :before {
      border-bottom: 2px solid transparent;
    },
  },
`;

const DocumentSearch: FC = () => {
  const { t } = useTranslation("common");
  const history = useNavigate();

  const [hits, setHits] = useState<DocumentSearchHit[]>([]);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const { isLoading, startLoading, stopLoading } = useLoadingState();

  function onHitSelected(event: ChangeEvent<{}>, hit: DocumentSearchHit | null, reason: string) {
    //TODO, validate this
    if (reason === "selectOption" && hit != null) {
      event.preventDefault();
      event.stopPropagation();
      const url = `/${hit.owner_slug}/${hit.instance_slug}/${hit.id}`;
      history(url);
    }
  }

  const doSearch = useDebounce(500, async (query?: string) => {
    if (query) {
      startLoading();
      Api.searchDocuments(query).fetchDirect([]).then(setHits).finally(stopLoading);
    } else {
      setHits([]);
    }
  });

  const onInputChange = (event: ChangeEvent<{}>, value: string, reason: string) => {
    if (reason !== "reset") {
      setSearchInputValue(value);
      doSearch(value);
    }
  };

  return (
    <Autocomplete
      id="search-combobox"
      onChange={onHitSelected}
      inputValue={searchInputValue}
      onInputChange={onInputChange}
      noOptionsText={t("search.noHits")}
      sx={{ padding: "16px" }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          variant="filled"
          autoComplete="off"
          size="small"
          label={
            <LabelWithIcon>
              <Search color="action" />
              <span>{t("search.searchDocuments") || ""}</span>
            </LabelWithIcon>
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={15} variant="indeterminate" />
              </InputAdornment>
            ),
          }}
        />
      )}
      PaperComponent={(props) => <HitList {...props}>{props.children}</HitList>}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <li style={{ padding: 0 }} {...props}>
          <SearchHit hit={option} />
        </li>
      )}
      getOptionLabel={(option) => String(option.id)}
      options={hits}
    />
  );
};

export default DocumentSearch;
