import Styles from "@/assets/styles/Styles";
import EnsureDMSContentReady from "@/components/EnsureDMSContentReady";
import { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";
import { DMSPlaybackFormatUrls } from "@/declarations/DMSPlaybackUrls";
import Loader from "@/framework/Loader";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { styled, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface DMSMediaPlayerProps extends AudioPreviewProps {
  thumbnailUrl?: string;
  audio?: boolean;
  captionSrc?: string;
}

const Video = styled("video")`
  max-width: 100%;
  object-fit: contain;
  &.audio {
    max-height: fit-content;
    padding: 0 16px;
  }
  &.video {
    height: fit-content;
    ::cue {
    } // style captions
  }
`;

export const DMSMediaPlayer: FC<DMSMediaPlayerProps> = ({ identifier, title, thumbnailUrl, audio, captionSrc }) => {
  const { t } = useTranslation("common");
  const [playbackUrl, setPlaybackUrl] = useAsyncSafeState<DMSPlaybackFormatUrls | null>(null);
  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const handleContentReady = async () => {
    if (identifier) {
      startLoading();
      const getPlaybackUrl = Api.getPlaybackUrls(identifier);
      const urls = await getPlaybackUrl.fetchDirect(null);
      setPlaybackUrl(urls?.urls ?? null);
      stopLoading();
    }
  };

  const getContent = () => {
    if (isLoading) {
      return <Loader loadingText="components.MediaPreview.MDSMediaPreview.loadingPlayback" />;
    }
    if (!playbackUrl || !Object.values(playbackUrl).filter((v) => !!v).length) {
      return <Typography color="error">{t("components.MediaPreview.MDSMediaPreview.mediaNotFound")}</Typography>;
    }
    return (
      <Video
        className={audio ? "audio" : "video"}
        title={title}
        poster={thumbnailUrl}
        width={Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_WIDTH}
        height={
          audio
            ? Styles.Dimensions.MEDIA_PREVIEW_AUDIO_PLAYER_HEIGHT
            : Styles.Dimensions.MEDIA_PREVIEW_MEDIA_PLAYER_HEIGHT
        }
        controls
      >
        {Object.entries(playbackUrl).map(([fileType, url]) => (
          <source key={fileType} src={url} type={`${audio ? "audio" : "video"}/${fileType}`} />
        ))}
        {!!captionSrc && <track default kind={"subtitles"} src={captionSrc} />}
      </Video>
    );
  };

  return (
    <EnsureDMSContentReady identifier={identifier} onReady={handleContentReady}>
      {getContent()}
    </EnsureDMSContentReady>
  );
};

export default DMSMediaPlayer;
