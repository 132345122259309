import { JSONSchema7 } from "json-schema";

export const schema: JSONSchema7 = {
  type: "object",
  properties: {
    id: {
      type: "integer",
      readOnly: true,
    },
    is_admin: {
      type: "boolean",
    },
    is_banned: {
      type: "boolean",
      readOnly: true,
    },
    activated: {
      type: "boolean",
      default: false,
      readOnly: true,
    },
    created_at: {
      type: "string",
      readOnly: true,
    },
    updated_at: {
      type: "string",
      readOnly: true,
    },
    name: {
      type: "string",
      minLength: 1,
    },
    username: {
      format: "email",
      type: "string",
    },
    image_url: {
      type: "string",
      format: "uri",
    },
  },
  required: ["name", "username"],
};
