import Api from "@/services/Api";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const DownloadCsvOfUsersByApplicationButton: FC = () => {
  const { t } = useTranslation("common");
  const { id: application_id } = useParams<{ id: string }>();

  const handleClick = async () => {
    const ctx = Api.getAllUsersByApplicationCSV({ application_id: Number(application_id) });
    ctx
      .fetchDirect("")
      .then((res) => {
        // https://stackoverflow.com/questions/65684497/how-to-save-csv-file-from-api-response-using-react
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "user_list.csv.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(ctx.abort);
  };

  return (
    <Button onClick={handleClick} variant={"contained"}>
      {t("views.admin.applications.downloadUserCSV")}
    </Button>
  );
};

export default DownloadCsvOfUsersByApplicationButton;
