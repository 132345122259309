import { Field } from "@rjsf/core";
import LocalizedStringField from "./fields/LocalizedStringField";
import LocationPickerField from "./fields/LocationPickerField";
import ChipListField from "./fields/ChipListField";
import { DMQueryField } from "@/framework/KioForm/fields/DMQueryField";
import DocumentRelationField from "@/framework/KioForm/fields/DocumentRelationField";
import LocalizedField from "@/framework/KioForm/fields/LocalizedField";
import MediaSelectorField from "@/framework/KioForm/fields/MediaSelectorField";
import ImageMapField from "@/framework/KioForm/fields/ImageMapField";
import StateRelationPickerField from "@/framework/KioForm/fields/StateRelationPickerField";
import QRCodeField from "@/framework/KioForm/fields/QRCodeField";
import DMSelectorField from "@/framework/KioForm/fields/DMSelectorField";

const Fields: { [name: string]: Field } = {
  LocalizedStringField,
  LocationPickerField,
  ChipList: ChipListField,
  DMQueryField,
  StateRelationPickerField,
  DocumentRelation: DocumentRelationField,
  LocalizedField,
  MediaSelectorField,
  ImageMapField,
  QRCodeField,
  DMSelectorField,
};

const WhiteListedFields: string[] = [
  "array",
  "boolean",
  "description",
  "one-of",
  "any-of",
  "null",
  "number",
  "object",
  "schema",
  "string",
  "title",
  "unsupported",
];

export { Fields, WhiteListedFields };
