import { MutableRefObject, useCallback, useEffect } from "react";
import { ArrayFieldTemplateProps } from "@rjsf/core";

const getArrayItemOfDragHandle = (item: Element): Element | undefined => {
  try {
    const child = item.closest(".field > div");
    return child ? child : undefined;
  } catch (err) {
    return undefined;
  }
};

const getClosestArrayItemOfCursor = <T>(arr: T[], clientY: number): { item: T; before: boolean } => {
  const isBefore = (item: any): boolean => {
    const { y, height } = item.getBoundingClientRect();
    return clientY < y + height / 2;
  };
  let before = isBefore(arr?.[0]);
  return {
    item: (arr as any[]).reduce((acc: any, cur: any) => {
      const getCenter = (item: any): number => {
        const rect = item.getBoundingClientRect();
        return rect.y + rect.height / 2;
      };
      const curCenterDelta = Math.abs(getCenter(cur) - clientY);
      const accCenterDelta = Math.abs(getCenter(acc) - clientY);
      if (curCenterDelta < accCenterDelta) {
        before = isBefore(cur);
        return cur;
      }
      return acc;
    }),
    before,
  };
};

const getArrayItemsOfDragHandle = (src: Element): Element[] => {
  const container = src.closest(".field-array");
  const children = Array.from(container?.children ?? []);
  return children.filter((child: Element) => child.querySelector(".collapse-bar"));
};

const applyIndication = (element: Element, before: boolean): void => {
  let className = `drag-insert-${before ? "before" : "after"}`;
  element.classList.add(className);
};

const calcNextIdxFromDropPoint = (from: number, to: number, before: boolean): number => {
  if (before && from < to) return to - 1;
  if (!before && from > to) return to + 1;
  return to;
};

const removeDragAndDropIndication = (elements: Element[]): void => {
  elements.forEach((ele: any) => {
    ele.classList.remove("drag-insert-after");
    ele.classList.remove("drag-insert-before");
  });
};

export const useDraggables = (
  arrayRef: MutableRefObject<HTMLDivElement | null | undefined>,
  items: ArrayFieldTemplateProps["items"]
) => {
  const moveItem = useCallback(
    (from: number, to: number) => {
      const move = items?.[0]?.onReorderClick(from, to);
      move?.();
    },
    [items]
  );

  useEffect(() => {
    const arrayDragOverHandler = (e: DragEvent) => {
      e.preventDefault();
      const ArrayItems = getArrayItemsOfDragHandle(e.target as Element);
      const { item: closest, before: isBefore } = getClosestArrayItemOfCursor<Element>(ArrayItems, e.clientY);
      removeDragAndDropIndication(ArrayItems);
      applyIndication(closest, isBefore);
    };
    const arrayDragEndHandler = (e: DragEvent) => {
      const srcElement = e.target as HTMLDivElement;
      const elements = getArrayItemsOfDragHandle(srcElement);
      const arrayChild = getArrayItemOfDragHandle(srcElement);
      if (!arrayChild) return;
      let indexOfDraggingElement = elements.indexOf(arrayChild);
      const { item: closest, before } = getClosestArrayItemOfCursor<Element>(elements, e.clientY);
      let nextIdx = elements.indexOf(closest);
      nextIdx = calcNextIdxFromDropPoint(indexOfDraggingElement, nextIdx, before);
      moveItem(indexOfDraggingElement, nextIdx);
      removeDragAndDropIndication(elements);
    };
    const handleDragStartHandler: EventListenerOrEventListenerObject = (e) => {
      if (e.target) {
        (e.target as any).classList.add("draggable");
      }
    };
    const handleDragEndHandler: EventListenerOrEventListenerObject = (e) => {
      if (e.target) {
        (e.target as any).classList.remove("draggable");
      }
    };
    if (!(arrayRef?.current && moveItem)) return;
    const ArrayElement = arrayRef.current;
    ArrayElement?.addEventListener("dragend", arrayDragEndHandler);
    const DragHandles = ArrayElement.querySelectorAll(".collapse-bar");
    DragHandles.forEach((handle: Element) => {
      handle.classList.add("draggable");
      handle.setAttribute("draggable", "true");
      handle.addEventListener("dragstart", handleDragStartHandler);
      handle.addEventListener("dragend", handleDragEndHandler);
    });
    ArrayElement.addEventListener("dragover", arrayDragOverHandler);

    return () => {
      ArrayElement?.removeEventListener("dragover", arrayDragOverHandler);
      ArrayElement.removeEventListener("dragend", arrayDragEndHandler);
      DragHandles.forEach((handle: Element) => {
        handle.removeEventListener("dragstart", handleDragStartHandler);
        handle.removeEventListener("dragend", handleDragEndHandler);
      });
    };
  }, [arrayRef, items, moveItem]);
};
