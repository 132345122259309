import { QueryParams } from "./QueryParams";
import { RequestContext } from "./RequestContext";
import { parseURL } from "@/utils/url";

/**
 * Creates a new request, and returns the created RequestContext
 */
abstract class ApiRequest<T> {
  private readonly headers: Headers = new Headers();
  private readonly requestSettings: RequestInit = { credentials: "omit" };
  private readonly queryParams: QueryParams = {};
  private readonly api: "admin" | "health" | "portal";
  private readonly path: Array<string | number>;

  protected constructor(api: "admin" | "health" | "portal", path: Array<string | number>) {
    this.api = api;
    this.path = path;
  }

  public withAuth(): ApiRequest<T> {
    const token = localStorage.getItem("kit.access_token");
    if (token) {
      this.headers.set("Authorization", `Bearer ${token}`);
      this.requestSettings.credentials = "include";
    }
    return this;
  }

  public withParams(params?: QueryParams): ApiRequest<T> {
    if (params) {
      Object.keys(params).forEach((param) => {
        if (params[param] !== null && params[param] !== undefined) {
          this.queryParams[param] = Array.isArray(params[param])
            ? (params[param] as []).filter((_elem: string | number) => _elem !== undefined && _elem !== null)
            : params[param];
        }
      });
    }
    return this;
  }

  public withFormData(formData: FormData): ApiRequest<T> {
    if (formData) {
      this.requestSettings.body = formData;
    }
    return this;
  }

  public withBody(body: Partial<T> | string | any[]): ApiRequest<T> {
    if (body) {
      if (typeof body === "string") {
        this.headers.set("Content-Type", "text/plain");
        this.requestSettings.body = body;
      } else {
        this.headers.set("Content-Type", "application/json");
        this.requestSettings.body = JSON.stringify(body);
      }
    }
    return this;
  }

  private fetch(method: "get" | "put" | "post" | "delete" = "get"): RequestContext<T> {
    const controller = new AbortController();
    const url = parseURL([this.api, "api", ...this.path], this.queryParams);
    const request = fetch(url, {
      ...this.requestSettings,
      headers: this.headers,
      method: method,
      signal: controller.signal,
    });
    return new RequestContext<T>(request, controller);
  }

  public get(): RequestContext<T> {
    return this.fetch("get");
  }

  public put(): RequestContext<T> {
    return this.fetch("put");
  }

  public post(): RequestContext<T> {
    return this.fetch("post");
  }

  public delete(): RequestContext<T> {
    return this.fetch("delete");
  }
}

/**
 * A Wrapper for all requests against the HealthApi (/health/api)
 */
export class HealthApiRequest<T> extends ApiRequest<T> {
  public constructor(...path: Array<string | number>) {
    super("health", path);
  }
}

/**
 * A Wrapper for all requests against the AdminApi (/admin/api)
 */
export class AdminApiRequest<T> extends ApiRequest<T> {
  constructor(...path: Array<string | number>) {
    super("admin", path);
  }
}
