import { FC, memo } from "react";
import { styled } from "@mui/material";
import Styles from "@/assets/styles/Styles";

const TextDividerDef = styled("span")(({ theme }) => ({
  margin: theme.spacing(0, 2),
  width: "1px",
  backgroundColor: Styles.Colors.HEADER_SEPARATOR_COLOR,
  display: "flex",
  alignSelf: "stretch",
}));

export const TextDivider: FC = memo(TextDividerDef);

export default TextDivider;
