import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { WidgetProps } from "@rjsf/core";
import React, { FC, useEffect, useState } from "react";

interface GridOption {
  columns: number;
  rows: number;
}

interface GridItemProps extends MenuItemProps {
  highlighted?: string;
}

const GridItem = styled((props: GridItemProps) => <MenuItem {...props} />)(
  ({ highlighted }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 60px;
  width: 60px;
  background-color: ${Boolean(highlighted) ? "rgba(0, 0, 0, 0.1) !important" : "rgba(0, 0, 0, 0) !important"};
  border: 1px solid rgba(0, 0, 0, 0.54);
`
);

const menuProps = (rows: number, columns: number) => ({
  PaperProps: {
    style: {
      minWidth: "fit-content",
    },
  },
  MenuListProps: {
    style: {
      display: "grid",
      width: "fit-content",
      gridTemplateRows: `repeat(${rows}, 1fr)`,
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      border: "1px solid rgba(0, 0, 0, 0.54)",
      padding: "0px",
    },
  },
});

const GridSelectWidget: FC<WidgetProps> = ({ label, value, onChange, uiSchema }) => {
  const uiOptions = uiSchema?.["ui:options"];
  const maxColumns = Number(uiOptions?.maxColumns) || 3;
  const maxRows = Number(uiOptions?.maxRows) || 3;
  const helperText = uiOptions?.["ui:help"];
  const [valueState, setValueState] = useState<string>("1x1");
  const [hoverState, setHoverState] = useState<GridOption>({ columns: 0, rows: 0 });
  const isHeaderVariant = Boolean(uiSchema?.["ui:options"]?.isHeaderVariant);
  // Initialize component, useEffect necessary to call onChange if value is not a string, otherwise validation might fail on save
  useEffect(() => {
    let strValue = "1x1";
    if (!!value && typeof value === "string") strValue = value;
    else if (!value || typeof value !== "string") {
      if (value?.columns && value?.rows) strValue = `${value.rows}x${value.columns}`;
      onChange(strValue);
    }
    const values = strValue.split("x").length > 1 ? strValue.split("x") : [0, 0];
    const colValue = Number(values[0]);
    const rowValue = Number(values[1]);
    setValueState(strValue);
    setHoverState({ columns: colValue, rows: rowValue });
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      onChange(event.target.value);
      setValueState(event.target.value);
    }
  };

  const resetHoverState = () => {
    const values = valueState.split("x");
    const colValue = Number(values[0]) || 0;
    const rowValue = Number(values[1]) || 0;
    setHoverState({ columns: colValue, rows: rowValue });
  };

  return (
    <FormControl
      variant={isHeaderVariant ? "outlined" : "filled"}
      size={isHeaderVariant ? "small" : "medium"}
      fullWidth
    >
      {label && <InputLabel id={"label-id"}>{label}</InputLabel>}
      <Select
        value={valueState}
        onChange={handleChange}
        MenuProps={menuProps(maxRows, maxColumns)}
        renderValue={() => valueState}
      >
        {Array.from({ length: maxRows }, (_, i) =>
          Array.from({ length: maxColumns }, (_, j) => {
            const option = `${j + 1}x${i + 1}`;
            return (
              <GridItem
                key={option}
                value={option}
                onMouseEnter={() => setHoverState({ columns: j + 1, rows: i + 1 })}
                onMouseLeave={resetHoverState}
                highlighted={j < hoverState.columns && i < hoverState.rows ? "true" : ""}
              >
                {option}
              </GridItem>
            );
          })
        )}
      </Select>
      {!!helperText && <FormHelperText>{String(helperText)}</FormHelperText>}
    </FormControl>
  );
};

export default GridSelectWidget;
