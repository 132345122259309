import React, { FC } from "react";
import AddDMSContent from "@/components/MediaSelector/forms/AddDMSContent";
import MediaType from "@/declarations/models/MediaType";
import { AddMediaContentProps } from "@/components/MediaSelector/AddMediaModal";

export const AddVideoMedia: FC<AddMediaContentProps> = ({ value, onCancel, onMediaSaved, ownerId, instanceId }) => {
  return (
    <AddDMSContent
      allowedMediaTypes={[MediaType.VIDEO]}
      media={value ?? null}
      onMediaSaved={onMediaSaved}
      onCancel={onCancel}
      ownerId={ownerId}
      instanceId={instanceId}
    />
  );
};

export default AddVideoMedia;
