import React, { FC } from "react";
import { MenuItem, Select, styled } from "@mui/material";
import { SelectOption, StyledMenuItem } from "@/framework/KioForm/common/KioSelect";

export interface DataListSelectProps {
  items: Array<SelectOption>;
  value: string | number;
  onChange: (value: string | number) => void;
}

const StyledSelect = styled(Select)({
  fontSize: "0.937rem",
  fontWeight: 700,
  "& .MuiSelect-select": {
    alignItems: "center",
    paddingBottom: 0,
  },
});
export const DataListSelect: FC<DataListSelectProps> = ({ items, value, onChange }) => {
  return (
    <StyledSelect
      variant={"standard"}
      size={"small"}
      value={value}
      onChange={(e) => onChange(e.target.value as string | number)}
      disableUnderline
    >
      {items.map((option) => (
        <StyledMenuItem key={option.value} value={option.value}>
          {option.label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default DataListSelect;
