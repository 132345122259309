import React from "react";
import { DataListRowInternal } from "@/components/DataList/DataListRow";
import { styled, Typography } from "@mui/material";

export interface ListTitleProps<T> {
  item: DataListRowInternal<T>;
  subtitle?: boolean;
  small?: boolean;
}

const Text = styled(Typography)({
  width: "fit-content",
  textAlign: "left",
  display: "-webkit-box",
  overflow: "hidden",
  wordBreak: "break-word",
  textOverflow: "ellipsis",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  fontWeight: 700,
  "&.small": {
    fontSize: "14px",
    fontWeight: 400,
  },
  "&.subtitle": {
    fontWeight: 400,
    lineHeight: 1.3,
  },
});

export const DataListTitle = <T extends object>({ item, subtitle = false, small = subtitle }: ListTitleProps<T>) =>
  subtitle ? (
    <Text variant="caption" color="textSecondary" className={small ? "small  subtitle" : "subtitle"}>
      {item.subTitle || ""}
    </Text>
  ) : (
    <Text className={small ? "small" : ""}>{item.title || ""}</Text>
  );

export default DataListTitle;
