import { DataListRow, DataListRowInternal } from "@/components/DataList/DataListRow";
import ApplicationInstance from "@/declarations/models/ApplicationInstance";
import Document from "@/declarations/models/Document";
import MediaType from "@/declarations/models/MediaType";
import Menu from "@/declarations/models/Menu";
import { QueryParams } from "@/declarations/QueryParams";
import Loader from "@/framework/Loader";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { useStore } from "@/Store";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import { getQueryParams } from "@/utils/url";
import DocumentView from "@/views/cms/DocumentView";
import MediaView from "@/views/cms/MediaView";
import QRCodeView from "@/views/cms/QRCodeView";
import PageNotFoundView from "@/views/PageNotFoundView";
import i18next from "i18next";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentStatus from "@/declarations/models/DocumentStatus";
import { CheckCircle, Unpublished } from "@mui/icons-material";
import ErrorView from "@/views/ErrorView";

/**
 * QueryParameters that define the mapping between the content and the ListItem.
 * May be object-paths to refer to content within the document.
 */
type MappingQueryParams = { [mappingKey in keyof DataListRow]: string };

export interface FinderProps {
  listTitle: string;
  instance: ApplicationInstance;
  langCode: string;
  userId?: number;
  getDeleted?: boolean;
}

/**
 * All parameters that are used to configure what data is displayed.
 * These are the params that should be available when creating an Action
 */
export interface FinderConfigurationQueryParams extends QueryParams, MappingQueryParams {
  /**
   * The ID of the schema whose documents to load
   */
  schema: number | string;
  mediaType?: MediaType;
}

/**
 * Default values for all params
 */
export const defaultFinderQueryParamsValues: FinderConfigurationQueryParams = {
  key: "id",
  title: "content.title",
  subTitle: undefined,
  imageURL: "",
  schema: 0,
  updatedAt: "updated_at",
  updatedBy: "updated_by",
  chipContent: undefined,
  mediaType: undefined,
};

/**
 * This view renders the content for the user.
 * The content is decided by the QueryParams.
 * These will be provided by the selected "Action.query_params", and may look like this:
 * <pre>
 *   ?schema=1&title=name&chipContent=status&updatedAt=created_at&updatedBy=created_by
 * </pre>
 * @constructor
 * @see FinderConfigurationQueryParams for a reference of all available params
 */
export const FinderView: FC = () => {
  const { t } = useTranslation("common");
  const { search } = useLocation();
  const history = useNavigate();
  const { state } = useStore();
  const instance = state.cmsContextInstance;
  // reload page if there are no query params:
  const queryParams = instance?.default_action_params;
  if (queryParams && !search) history({ search: queryParams });

  const langCode = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(langCode);

  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [listTitle, setListTitle] = useState<string>("Kulturio Admin");

  const params = getQueryParams<FinderConfigurationQueryParams>(defaultFinderQueryParamsValues, search);
  const schema_id = Number(params.schema);
  const mediaType = params.mediaType;

  const [singleDocument, setSingleDocument] = useState<boolean>(false);
  const [appMenus, setAppMenus] = useState<Menu[]>([]);

  useEffect(() => {
    if (instance?.application_id) {
      startLoading();
      const getApp = Api.getOneApplication(instance.application_id);
      getApp
        .fetchDirect(null)
        .then((app) => setAppMenus(app?.menu || []))
        .then(getApp.abort)
        .finally(stopLoading);
    }
  }, [instance?.application_id]);

  const handleListTitle = (tempTitle?: string) => {
    if (!tempTitle) {
      if (mediaType) tempTitle = t("mediaType.plural." + mediaType);
      tempTitle = tempTitle || instance?.name || "Kulturio Admin";
    }
    setListTitle(tempTitle);
    document.title = tempTitle;
  };

  useEffect(() => {
    appMenus.some((menu) => {
      return menu.menu_items?.some((item) => {
        if (
          Number(item.action.schema_id) === schema_id ||
          (mediaType && item.action.media_type === mediaType) ||
          String(params.schema).toLowerCase() === item.action.doc_type.toLowerCase()
        ) {
          handleListTitle(getLocalizedString(item.title) || undefined);
          setSingleDocument(!!item.action.single_document);
          return true;
        }
        return false;
      });
    });
  }, [appMenus, params.schema, mediaType]);

  if (isLoading) return <Loader loadingText={t("views.cms.loadingContent")} />;

  if (!instance?.id) return <PageNotFoundView />;

  if (params.schema === "qr") return <QRCodeView listTitle={listTitle} instance={instance} langCode={langCode} />;

  if (params.schema === "documentbin") {
    return (
      <DocumentView
        listTitle={listTitle}
        instance={instance}
        langCode={langCode}
        userId={state.user?.id}
        getDeleted
        appMenus={appMenus}
      />
    );
  }

  if (params.schema === "mediabin")
    return (
      <MediaView listTitle={listTitle} instance={instance} getDeleted langCode={langCode} userId={state.user?.id} />
    );

  if (schema_id) {
    const setPublishStatus = async (doc: Document, status: DocumentStatus) => {
      if (doc.status !== status) await Api.updateDocument({ ...doc, status: status }).fetchDirect(null);
    };
    return (
      <DocumentView
        listTitle={listTitle}
        instance={instance}
        langCode={langCode}
        userId={state.user?.id}
        schemaId={schema_id}
        singleDocument={singleDocument}
        customBatchButtons={[
          {
            tooltip: t("components.list.header.publish"),
            onClick: (item: DataListRowInternal<any>) => setPublishStatus(item.sourceItem, DocumentStatus.PUBLISHED),
            icon: <CheckCircle />,
            confirmationText: t("components.list.header.confirmPublish"),
          },
          {
            tooltip: t("components.list.header.draft"),
            onClick: (item: DataListRowInternal<any>) => setPublishStatus(item.sourceItem, DocumentStatus.DRAFT),
            icon: <Unpublished />,
            confirmationText: t("components.list.header.confirmDraft"),
          },
        ]}
      />
    );
  }

  if (mediaType)
    return (
      <MediaView
        listTitle={listTitle}
        instance={instance}
        langCode={langCode}
        userId={state.user?.id}
        mediaType={mediaType}
      />
    );

  return <>ERROR // FIXME</>;
};

export default FinderView;
