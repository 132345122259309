import React, { FC, ReactElement, useCallback } from "react";
import { getValue, isObject, objectFromPath } from "@/utils/obj";
import { canFieldGoToHeader } from "@/utils/form";
import { Box } from "@mui/material";
import { utils } from "@rjsf/core";
import { getSchemaTitle } from "@/utils/schema";

interface SettingsFieldsProps {
  element?: ReactElement;
}

function stopBubbling(e: { stopPropagation: () => void }) {
  e.stopPropagation();
}

// Because "objects" in jsonSchema have their properties under ".properties"
function pathToJsonSchemaObjectPath(path: string): string {
  return path.split(".").join(".properties.");
}

const ArrayItemSettingsFields: FC<SettingsFieldsProps> = ({ element }) => {
  const { registry, formContext, formData, errorSchema, idSchema, onChange, schema, uiSchema, disabled } =
    element?.props || {};

  const onChangeForProperty = useCallback(
    (propertyPath: string) => (value: any) => {
      // transform the path to a deep object, and merge with the "proper" formData
      const obj = objectFromPath(propertyPath.split("."), value);
      const mergedObj = utils.mergeObjects(formData, obj);

      onChange(mergedObj);
    },
    [onChange, formData]
  );

  if (
    !schema ||
    schema.type !== "object" ||
    !isObject(schema.properties) ||
    !uiSchema?.["ui:settings"] ||
    !Array.isArray(uiSchema["ui:settings"])
  ) {
    return null;
  }

  const SchemaField = registry.fields.SchemaField;

  return (
    <Box display={"flex"} onClick={stopBubbling}>
      {uiSchema["ui:settings"]
        .filter((path) => canFieldGoToHeader(pathToJsonSchemaObjectPath(path), schema))
        .map(function (path) {
          const jsonSchemaPath = pathToJsonSchemaObjectPath(path);
          const _schema = getValue(schema.properties, jsonSchemaPath);
          const readonly = _schema?.["readonly"] === true;
          const required = false;
          const _uiSchema = getValue(uiSchema, path) || {};
          const _formData = getValue(formData, path);

          if (!_uiSchema?.["ui:options"]) {
            _uiSchema["ui:options"] = {};
          }
          _uiSchema["ui:options"] = { ..._uiSchema["ui:options"], isHeaderVariant: true };

          return (
            <Box m={1} key={`${idSchema.$id}_${path}_${JSON.stringify(formData)}`}>
              <SchemaField
                schema={_schema}
                uiSchema={_uiSchema}
                idSchema={getValue(idSchema, path)}
                formData={_formData}
                errorSchema={getValue(errorSchema, path) || {}}
                registry={registry}
                formContext={formContext}
                onChange={onChangeForProperty(path)}
                onBlur={() => {}}
                onFocus={() => {}}
                autofocus={false}
                name={getSchemaTitle(formContext, _schema, _uiSchema, _formData)}
                disabled={disabled}
                readonly={readonly}
                required={required}
              />
            </Box>
          );
        })}
    </Box>
  );
};

export default ArrayItemSettingsFields;
