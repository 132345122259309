import React, { FC } from "react";

import { ReactComponent as Home } from "@/assets/img/custom-icons/home.svg";
import { ReactComponent as Articles } from "@/assets/img/custom-icons/articles.svg";
import { ReactComponent as Presentations } from "@/assets/img/custom-icons/presentations.svg";
import { ReactComponent as Museum } from "@/assets/img/custom-icons/museum.svg";
import { ReactComponent as Map } from "@/assets/img/custom-icons/map.svg";
import { ReactComponent as Rotation3D } from "@/assets/img/custom-icons/3d-rotation.svg";
import { ReactComponent as PhotoLibrary } from "@/assets/img/custom-icons/photo-library.svg";
import { ReactComponent as MusicLibrary } from "@/assets/img/custom-icons/music-library.svg";
import { ReactComponent as VideoLibrary } from "@/assets/img/custom-icons/video-library.svg";
import { ReactComponent as Restore } from "@/assets/img/custom-icons/restore.svg";
import { ReactComponent as Deleted } from "@/assets/img/custom-icons/deleted.svg";
import { ReactComponent as DM } from "@/assets/img/custom-icons/icon_dm.svg";
import { ReactComponent as Minne } from "@/assets/img/custom-icons/icon_minne.svg";
import { ReactComponent as Sketchfab } from "@/assets/img/custom-icons/sketchfab.svg";
import { Icon } from "@mui/material";
import { Google, QuestionMark, YouTube } from "@mui/icons-material";

export interface CustomIconProps {
  name?: string;
  className?: string;
}

export const customIconNames = [
  "Home",
  "Articles",
  "Presentations",
  "Museum",
  "Map",
  "3DRotation",
  "MusicLibrary",
  "PhotoLibrary",
  "VideoLibrary",
  "Restore",
  "Deleted",
  "DM",
  "Minne",
  "Google",
  "YouTube",
  "Sketchfab",
];

const getCustomIcon = (name: string) => {
  switch (name) {
    case "Home":
      return <Home />;
    case "Articles":
      return <Articles />;
    case "Presentations":
      return <Presentations />;
    case "Museum":
      return <Museum />;
    case "Map":
      return <Map />;
    case "3DRotation":
      return <Rotation3D />;
    case "MusicLibrary":
      return <MusicLibrary />;
    case "PhotoLibrary":
      return <PhotoLibrary />;
    case "VideoLibrary":
      return <VideoLibrary />;
    case "Restore":
      return <Restore />;
    case "Deleted":
      return <Deleted />;
    case "DM":
      return <DM />;
    case "Minne":
      return <Minne />;
    case "Google":
      return <Google />;
    case "YouTube":
      return <YouTube />;
    case "Sketchfab":
      return <Sketchfab />;
  }
};

export const CustomIcons: FC<CustomIconProps> = ({ name, className }) => {
  return <Icon className={className || ""}>{name ? getCustomIcon(name) : <QuestionMark />}</Icon>;
};

export default CustomIcons;
