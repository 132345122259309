export function groupBy<T, K = T[keyof T]>(
  items: Array<T>,
  getKey: (item: T) => K,
  eq: (key1: K, key2: K) => boolean = (a: K, b: K): boolean => a === b
): Array<{ key: K; items: Array<T> }> {
  return items.reduce((groups, currentItem) => {
    const currentKey = getKey(currentItem);
    const existingGroup = groups.find((group) => eq(group.key, currentKey));
    if (existingGroup) {
      existingGroup.items.push(currentItem);
    } else {
      groups.push({
        key: currentKey,
        items: [currentItem],
      });
    }
    return groups;
  }, [] as Array<{ key: K; items: Array<T> }>);
}

export function filterAndOrderByKeyValues<T extends { [K: string]: unknown }, K extends keyof T>(
  items: T[],
  itemAttr: K,
  keys: string[]
): T[] {
  if (keys.length === 0) return items;
  const arr = [];
  for (const key of keys) {
    const prop = items.find((prop) => prop?.[itemAttr] === key);
    prop && arr.push(prop);
  }
  return arr;
}
