import VimeoLogo from "@/assets/img/logos/Vimeo.jpg";
import EmbeddedContent from "@/components/EmbeddedContent";
import { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";
import React, { FC } from "react";

export const VimeoPreview: FC<AudioPreviewProps> = ({ identifier, title }) => {
  return (
    <EmbeddedContent
      title={title || "Vimeo player"}
      src={`https://player.vimeo.com/video/${identifier}`}
      allow="fullscreen; picture-in-picture"
      allowFullScreen
      invalid={!identifier}
      fallbackImageSrc={VimeoLogo}
    />
  );
};

export default VimeoPreview;
