import { useCallback, useMemo, useReducer, useRef } from "react";

type Dictionary<T> = { [key: string]: T | null };
type Action<T> = { type: "clear" | "set" | "remove"; key?: string; value?: T | null };

export interface Lookup<T> {
  /**
   * Clears all items from the lookup
   */
  clear: () => void;
  /**
   * Set or override an existing value at the specified key
   * @param key The key of the item to set the value for
   * @param value The value to set. Uses NULL if not defined
   * @return the value that was just set, so that an additional lookup is not needed
   */
  setItem: (key?: string | number, value?: T | null) => T | null;
  /**
   * Retrieve an existing item, or NULL if not found
   * @param key
   */
  getItem: (key?: string | number) => T | null;
  /**
   * Remove an exsiting entry, or NOOP if not defined
   * @param key
   */
  removeItem: (key?: string | number) => void;
  /**
   * Get a non-mutable version of the current state
   */
  getCurrentState: () => Dictionary<T>;
}

/**
 * This hook works like a lookup/dictionary.
 * It is very useful when implementing a cache,
 * or when keeping track of state for many unknown items
 * @param initialValues
 */
export const useLookup = <T>(initialValues?: Dictionary<T>): Lookup<T> => {
  const [state, dispatch] = useReducer((lookup: Dictionary<T>, action: Action<T>) => {
    switch (action.type) {
      case "clear":
        return {};
      case "set":
        return action.key == null
          ? lookup
          : {
              ...lookup,
              [action.key]: action.value || null,
            };
      case "remove":
        if (action.key == null) return lookup;
        const lookupCpy = { ...lookup };
        delete lookupCpy[action.key];
        return lookupCpy;
    }
    return lookup;
  }, (initialValues || {}) as Dictionary<T>);

  const stateRef = useRef<Dictionary<T>>(state);
  stateRef.current = state;

  const clear: Lookup<T>["clear"] = useCallback(() => {
    dispatch({ type: "clear" });
  }, []);

  const setItem: Lookup<T>["setItem"] = useCallback((key?: string | number, value?: T | null) => {
    dispatch({ type: "set", key: String(key), value });
    return value ?? null;
  }, []);

  const removeItem: Lookup<T>["removeItem"] = useCallback((key?: string | number) => {
    dispatch({ type: "remove", key: String(key) });
  }, []);

  const getItem: Lookup<T>["getItem"] = useCallback((key?: string | number): T | null => {
    if (key == null || !stateRef.current) return null;
    return stateRef.current[key] ?? null;
  }, []);

  const getCurrentState: Lookup<T>["getCurrentState"] = useCallback(() => {
    return Object.freeze({ ...stateRef.current });
  }, []);

  return useMemo<Lookup<T>>(
    () =>
      ({
        clear,
        setItem,
        getItem,
        removeItem,
        getCurrentState,
      } as Lookup<T>),
    [clear, setItem, getItem, removeItem, getCurrentState]
  );
};
