import React, { FC, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import DataList from "@/components/DataList/DataList";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import FormButtons from "@/components/FormButtons";
import { DMSearchResult } from "@/declarations/models/DMSearchResult";
import { DataListRow } from "@/components/DataList/DataListRow";
import PaginationResult from "@/declarations/PaginationResult";
import Api from "@/services/Api";
import { useLookup } from "@/hooks/useLookup";
import { useDebounce } from "@/hooks/useDebounce";
import { useTranslation } from "react-i18next";

export interface SearchDMTabContentProps {
  onSelectionChanged: (selectedItems: Array<DMSearchResult>) => void;
  onCancel?: () => void;
}

function mapDMSearchResultToDataListItem(dm: DMSearchResult): DataListRow {
  return {
    key: String(dm.artifact_uuid || dm.artifact_uniqueId || dm.identifier_id || ""),
    title: String(dm.artifact_ingress_title || "?"),
    subTitle: dm.artifact_ingress_subjects?.join(", "),
    chipContent: dm.identifier_owner,
    imageURL: dm.thumbnail_src,
    updatedAt: dm.artifact_updatedDate,
  };
}

export const SearchDMTabContent: FC<SearchDMTabContentProps> = ({ onSelectionChanged, onCancel }) => {
  const { t } = useTranslation("common");
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [lastUpdated, setLastUpdated] = useState<number>(Date.now());

  const queryCache = useLookup<PaginationResult<DMSearchResult>>();

  const refreshData = useDebounce<string>(500, (query) => {
    if (!query) {
      return;
    }
    setLastUpdated(Date.now());
  });

  const updateQuery = (value: string): void => {
    setCurrentQuery(value);
    refreshData(value);
  };

  const getItems = async (page: number, page_size: number): Promise<PaginationResult<DMSearchResult>> => {
    const query = currentQuery || "*";
    const cacheKey = `${page}-${page_size}-${query}`.toLowerCase();
    const cachedPage = queryCache.getItem(cacheKey);
    if (cachedPage) {
      return cachedPage;
    }
    const result = await Api.searchDM({ page, page_size, query }).fetchDirect({
      page,
      page_size,
      items: [],
      count: 0,
      total_count: 0,
    });
    queryCache.setItem(cacheKey, result);
    return result;
  };

  return (
    <>
      <TextField
        id="query-input"
        variant="outlined"
        value={currentQuery}
        onChange={(e) => updateQuery(e.target.value || "")}
        label={t("components.MediaSelector.AddDMContent.search")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="action" />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <DataList
        listTitle={t("components.MediaSelector.AddDMContent.searchResult")}
        getItems={getItems}
        mapperFn={mapDMSearchResultToDataListItem}
        externalDataChanged={lastUpdated}
        onSelectionChanged={onSelectionChanged}
        disableSorting
        defaultViewType={ViewType.GRID}
        defaultPageSize={25}
      />
      <FormButtons pt={2} onCancel={onCancel} />
    </>
  );
};

export default SearchDMTabContent;
