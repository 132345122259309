import { CustomButton } from "@/components/DataList/common/DataListOptions";
import React, { FC, useEffect } from "react";
import ViewTypeSelector, { ViewTypeSelectorProps } from "./ViewTypeSelector";
import SortSelector, { SortSelectorProps } from "./SortSelector";
import DataListToggle, { DataListToggleProps } from "./DataListToggle";
import { useTranslation } from "react-i18next";
import HeaderPaginator from "@/components/DataList/ListHeader/HeaderPaginator";
import { Box, Button, Checkbox, Divider, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { DataListRowInternal } from "@/components/DataList/DataListRow";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import { Add, Delete, Restore } from "@mui/icons-material";
import SearchField from "@/components/SearchField";

export interface DataListHeaderProps extends SortSelectorProps, ViewTypeSelectorProps, DataListToggleProps {
  items?: Array<DataListRowInternal<any>>;
  listTitle?: string;
  disableSorting?: boolean;
  disableViewTypeSelection?: boolean;
  disablePagination?: boolean;
  enableSelection?: boolean;
  page: number;
  pageSize: number;
  availablePageSizes: Array<number>;
  totalCount: number;
  onPageChange: (page: number, pageSize: number) => void;
  onToggleSelection: (selectAll: boolean) => void;
  filterOptions?: Array<any>;
  filter?: string;
  createNewButton?: CreateNewButton;
  searchProp?: SearchProp;
  customBatchButtons?: CustomButton[];
  selectedKeys: string[];
  onDeleteItem?: (item: any) => void;
  onUndeleteItem?: (item: any) => void;
}

export interface CreateNewButton {
  onCreateNew: () => void;
  buttonLabel?: string;
  singleDocument?: boolean;
}

export interface SearchProp {
  query: string;
  updateQuery: (query: string) => void;
  placeholder?: string;
}

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",
  width: "100%",
  padding: 0,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: 1,
}));

const Row = styled("div")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  gap: "16px",
  alignItems: "center",
  "& .MuiCheckbox-root": {
    padding: "6px",
  },
  "& .MuiButtonBase-root": {
    whiteSpace: "nowrap",
  },
});

const Row1 = styled(Row)(({ theme }) => ({
  justifyContent: "space-between",
  margin: theme.spacing(2),
  minHeight: "40px",
  "& .MuiButton-root": {
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    minWidth: "fit-content",
    "& .MuiSvgIcon-root": {
      height: "28px",
      width: "28px",
    },
  },
}));

const Row2 = styled(Row)(({ theme }) => ({
  justifyContent: "space-between",
  padding: theme.spacing(0, 2),

  "& .MuiTypography-caption": {
    fontSize: "0.9375rem",
    fontWeight: 700,
  },
}));

export const DataListHeader: FC<DataListHeaderProps> = ({
  items = [],
  onItemsChanged,
  selectedViewType,
  setSelectedViewType,
  onToggle,
  disableSorting = false,
  disableViewTypeSelection = false,
  disablePagination = false,
  enableSelection = false,
  toggleLabel = "",
  listTitle,
  page,
  pageSize,
  totalCount,
  availablePageSizes,
  onPageChange,
  onToggleSelection,
  sortOptions,
  initialSortOption,
  filterOptions,
  filter,
  createNewButton,
  searchProp,
  selectedKeys,
  onDeleteItem,
  onUndeleteItem,
  customBatchButtons = [],
}) => {
  const { t } = useTranslation("common");

  const currentPageCountStart = page * pageSize + 1;
  const currentPageCountEnd = (page + 1) * pageSize;

  const allSelected = !!selectedKeys?.length && selectedKeys?.length == items.length;
  const someSelected = !!selectedKeys?.length && selectedKeys.length < items.length;

  useEffect(() => {
    if (disableSorting && onItemsChanged) onItemsChanged();
  }, [items, disableSorting]);

  const batchButtons: Array<CustomButton> = [];
  !!onDeleteItem &&
    batchButtons.push({
      tooltip: t("components.list.header.delete"),
      onClick: (item: DataListRowInternal<any>) => onDeleteItem(item.sourceItem),
      icon: <Delete />,
      confirmationText: t("components.list.header.confirmDelete"),
    });
  !!onUndeleteItem &&
    batchButtons.push({
      tooltip: t("components.list.header.restore"),
      onClick: (item: DataListRowInternal<any>) => onUndeleteItem(item.sourceItem),
      icon: <Restore />,
      displayCondition: () => items.some((i) => selectedKeys.some((k) => i.key === k) && i.sourceItem.deleted_at),
    });

  const buttons = [...customBatchButtons, ...batchButtons];

  const doBatchOperation = async (button: CustomButton) => {
    if (!button.confirmationText || window.confirm(button.confirmationText)) {
      if (!!button.onClickAll) {
        const selectedItems = items.filter((i) => selectedKeys.some((k) => i.key === k));
        await button.onClickAll(selectedItems);
        onToggleSelection(false);
        onItemsChanged && onItemsChanged();
      } else if (!!button.onClick) {
        await Promise.all(
          selectedKeys.map(async (key) => {
            const item = items.find((i) => i.key == key);
            if (item && !!button.onClick) await button.onClick(item);
          })
        ).then(() => onItemsChanged && onItemsChanged());
      }
    }
  };

  return (
    <HeaderContainer>
      <Row1>
        {listTitle && <KioTitle title={listTitle} level={0} />}
        <Row sx={{ marginLeft: "auto" }}>
          {searchProp && (
            <SearchField
              value={searchProp.query}
              fullWidth
              onChange={searchProp?.updateQuery}
              placeholder={searchProp.placeholder || t("search.inputLabel")}
            />
          )}
          {filterOptions && (
            <KioSelect
              label={t("components.list.filter.filterList")}
              onChange={(v) => onItemsChanged?.(undefined, undefined, v)}
              value={filter}
              options={filterOptions}
              isHeaderVariant
            />
          )}
          {!disableViewTypeSelection && (
            <ViewTypeSelector selectedViewType={selectedViewType} setSelectedViewType={setSelectedViewType} />
          )}
          {!!createNewButton && (!createNewButton.singleDocument || totalCount < 1) && (
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={createNewButton.onCreateNew}
            >
              {t(createNewButton.buttonLabel || "generic.createNew")}
            </Button>
          )}
        </Row>
      </Row1>

      <Divider />

      <Row2>
        <Row>
          {enableSelection && !!buttons.length && (
            <Checkbox
              checked={allSelected}
              disabled={!items.length}
              color="primary"
              indeterminate={!allSelected && someSelected}
              onChange={() => onToggleSelection(!allSelected || someSelected)}
            />
          )}
          {enableSelection && !!buttons.length && !!selectedKeys?.length ? (
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="caption" color="textSecondary" paddingRight={1}>
                {t("components.list.header.selected", { count: selectedKeys.length, total: totalCount })}
              </Typography>
              <Divider orientation={"vertical"} flexItem style={{ margin: "6px 0" }} />
              {buttons.map(
                (button, index) =>
                  (!button.displayCondition || button.displayCondition(selectedKeys)) && (
                    <Tooltip title={button.tooltip} key={`custom-button-${index}`}>
                      <IconButton sx={{ paddingRight: 0 }} onClick={() => doBatchOperation(button)}>
                        {button.icon}
                      </IconButton>
                    </Tooltip>
                  )
              )}
            </Box>
          ) : (
            <Typography variant="caption" color="textSecondary">
              {t("components.list.header.itemCount", {
                currentPageStart: totalCount === 0 ? 0 : currentPageCountStart,
                currentPageEnd: totalCount < currentPageCountEnd ? totalCount : currentPageCountEnd,
                count: totalCount,
              })}
            </Typography>
          )}
        </Row>

        <Row>
          {!!onToggle && <DataListToggle onToggle={onToggle} toggleLabel={toggleLabel} />}
          {!disableSorting && (
            <Box p={"8px 0"}>
              <SortSelector
                onItemsChanged={onItemsChanged}
                sortOptions={sortOptions}
                initialSortOption={initialSortOption}
              />
            </Box>
          )}
          {!disablePagination && (
            <HeaderPaginator
              page={page}
              pageSize={pageSize}
              totalPageCount={Math.ceil(totalCount / pageSize)}
              availablePageSizes={availablePageSizes}
              onChange={onPageChange}
            />
          )}
        </Row>
      </Row2>
    </HeaderContainer>
  );
};

export default DataListHeader;
