import Styles from "@/assets/styles/Styles";
import MediaSelector from "@/components/MediaSelector/MediaSelector";
import PersistentDrawer from "@/components/PersistentDrawer";
import Document from "@/declarations/models/Document";
import DocumentStatus from "@/declarations/models/DocumentStatus";
import KioForm from "@/framework/KioForm/KioForm";
import Layout from "@/framework/Layout";
import { usePrompt } from "@/hooks/useUNSAFE_Prompt";
import Settings from "@/Settings";
import { useStore } from "@/Store";
import { deepCompareFormData } from "@/utils/obj";
import { EditDocumentLoaderProps, SchemaObject } from "@/views/cms/EditorView/EditDocumentLoader";
import EditorHeader from "@/views/cms/EditorView/EditorHeader";
import EditorPreview from "@/views/cms/EditorView/EditorPreview/EditorPreview";
import styled from "@emotion/styled";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormContainer } from "./EditMediaView";

interface EditDocumentViewProps extends EditDocumentLoaderProps {
  schemas: SchemaObject;
  documentToEdit?: Document | null;
  handleSaveDocument: any;
  selectedLocale: any;
  setSelectedLocale: any;
  setReferences: any;
}

const ErrorText = styled("div")`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: crimson;
`;

export const EditDocumentView: FC<EditDocumentViewProps> = ({
  disablePreview,
  schemas,
  documentToEdit,
  handleSaveDocument,
  selectedLocale,
  setSelectedLocale,
  setReferences,
  modalEditor,
  isDirty,
  setIsDirty,
}) => {
  const { state } = useStore();
  const { t } = useTranslation("common");

  const [previewOpen, setPreviewOpen] = useState<boolean>(true);
  const [mediaSelectorOpen, setMediaSelectorOpen] = useState<boolean>(false);
  const [modifiedContent, setModifiedContent] = useState<any>(documentToEdit?.content || {});
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<DocumentStatus>(
    documentToEdit?.status || DocumentStatus.DRAFT
  );
  const canPublishUnlisted: boolean = !!schemas.uiSchema?.["ui:options"]?.["canPublishUnlisted"];

  usePrompt(t("generic.unsavedChangesMessage"), isDirty);
  useEffect(() => documentToEdit?.content && setModifiedContent(documentToEdit.content), [documentToEdit]);

  const handleHeaderSubmitClick = (selectedOption?: string) => {
    switch (selectedOption) {
      case "generic.save_draft":
        handleSaveDocument(modifiedContent, DocumentStatus.DRAFT).then((res: any) => setModifiedContent(res));
        setSelectedDocumentStatus(DocumentStatus.DRAFT);
        break;
      case "generic.save_published":
        handleSaveDocument(modifiedContent, DocumentStatus.PUBLISHED).then((res: any) => setModifiedContent(res));
        setSelectedDocumentStatus(DocumentStatus.PUBLISHED);
        break;
      case "generic.save_published_not_listed":
        handleSaveDocument(modifiedContent, DocumentStatus.PUBLISHED_NOT_LISTED).then((res: any) =>
          setModifiedContent(res)
        );
        setSelectedDocumentStatus(DocumentStatus.PUBLISHED_NOT_LISTED);
        break;
      default:
        handleSaveDocument(modifiedContent, selectedDocumentStatus).then((res: any) => setModifiedContent(res));
    }
    setIsDirty(false);
  };

  const handleOnChange = (formData: any) => {
    if (!formData || !documentToEdit?.content) {
      setIsDirty(true);
      setModifiedContent(formData || {});
      return;
    }
    if (!deepCompareFormData(formData, documentToEdit?.content)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setModifiedContent(formData);
  };

  return (
    <Layout
      header={
        <EditorHeader
          disablePreview={disablePreview}
          previewOpen={!disablePreview && previewOpen}
          onPreviewOpenChanged={setPreviewOpen}
          onSubmit={handleHeaderSubmitClick}
          formIsDirty={isDirty}
          currentStatus={selectedDocumentStatus}
          lastModified={documentToEdit?.updated_at}
          lastModifiedBy={documentToEdit?.updated_by}
          setSelectedLocale={setSelectedLocale}
          selectedLocale={selectedLocale}
          canPublishUnlisted={canPublishUnlisted}
        />
      }
    >
      {!schemas.schema_id && !documentToEdit ? (
        <ErrorText>{t("views.admin.schemaPreview.missingSchema")}</ErrorText>
      ) : (
        <MediaSelector
          open={mediaSelectorOpen}
          setOpen={setMediaSelectorOpen}
          width={!!modalEditor ? "calc(50vw - 16px)" : undefined}
        >
          {modalEditor ? (
            <KioForm
              formData={modifiedContent}
              selectedLocale={selectedLocale || Settings.DEFAULT_LOCALE}
              onChange={handleOnChange}
              onSubmit={() => {}} // submit from header
              onReferencesChange={setReferences}
              schema={schemas.jsonSchema}
              uiSchema={schemas.uiSchema}
            >
              <button type="submit" hidden={true} />
            </KioForm>
          ) : (
            <PersistentDrawer
              open={!disablePreview && previewOpen && !mediaSelectorOpen}
              position="right"
              width={Styles.Dimensions.EDITOR_PREVIEW_WIDTH}
              drawerContent={
                <EditorPreview
                  schemas={schemas}
                  formData={modifiedContent}
                  selectedLocale={selectedLocale}
                  availableLocales={state.cmsContextInstance!.available_locales}
                />
              }
            >
              <FormContainer>
                <KioForm
                  formData={modifiedContent}
                  selectedLocale={selectedLocale || Settings.DEFAULT_LOCALE}
                  onChange={handleOnChange}
                  onSubmit={() => {}} // submit from header
                  onReferencesChange={setReferences}
                  schema={schemas.jsonSchema}
                  uiSchema={schemas.uiSchema}
                >
                  <button type="submit" hidden={true} />
                </KioForm>
              </FormContainer>
            </PersistentDrawer>
          )}
        </MediaSelector>
      )}
    </Layout>
  );
};

export default EditDocumentView;
