import { UiSchema, WidgetProps } from "@rjsf/core";
import React, { FC } from "react";
import { getValueFromUiSchema } from "@/utils/schema";
import KioDateTimePicker, { KioDateTimePickerProps } from "../common/KioDateTimePicker";
import { JSONSchema7 } from "json-schema";
import { useTranslation } from "react-i18next";

export interface KioDateTimeWidgetProps extends WidgetProps {
  onChange: (date: string | undefined | null) => void;
}

function getTimePickerType(schema: JSONSchema7, uiSchema: UiSchema): any {
  if (schema.format && ["date-time", "date", "time"].includes(schema.format)) {
    // @ts-ignore
    return schema.format as KioDateTimePickerProps["timePickerType"];
  }
  return "date-time";
}

function getTitle(schema: JSONSchema7, uiSchema: UiSchema): string {
  return schema.title ?? getValueFromUiSchema("title", uiSchema) ?? getValueFromUiSchema("label", uiSchema) ?? "";
}

function getDescription(schema: JSONSchema7, uiSchema: UiSchema): string | undefined {
  return schema.description ?? getValueFromUiSchema("description", uiSchema);
}

const KioDateTimeWidget: FC<KioDateTimeWidgetProps> = ({
  schema,
  uiSchema,
  id,
  value,
  onChange,
  disabled,
  readonly,
}) => {
  const { t } = useTranslation("common");
  const title = getTitle(schema, uiSchema);
  const description = getDescription(schema, uiSchema);
  const timePickerType = getTimePickerType(schema, uiSchema);
  return (
    <KioDateTimePicker
      timePickerType={timePickerType}
      name={id}
      description={description}
      formValue={value || null}
      onChange={onChange}
      label={title}
      cancelLabel={t("generic.cancel")}
      okLabel={t("generic.ok")}
      clearLabel={t("generic.clear")}
      todayLabel={t("generic.today")}
      invalidDateMessage={t("kioForm.common.kioDateTimePicker.error.invalidDateMessage")}
      disabled={disabled || readonly}
    />
  );
};

export default KioDateTimeWidget;
