import { Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface DocumentPreviewProps {
  url?: string;
}

export const DocumentPreview: FC<DocumentPreviewProps> = ({ url }) => {
  const { t } = useTranslation("common");
  if (!url) return <Typography color="error">{t("components.MediaPreview.UnableToPreview")}</Typography>;
  return <iframe src={url} width={"100%"} height={"100%"} />;
};

export default DocumentPreview;
