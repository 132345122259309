import { JSONSchema7 } from "json-schema";

export const schema: JSONSchema7 = {
  type: "object",
  title: "Instance",
  properties: {
    id: {
      type: "integer",
      readOnly: true,
    },
    path: {
      type: ["string", "null"],
      readOnly: true,
    },
    name: {
      type: ["string", "null"],
    },
    slug: {
      type: ["string", "null"],
    },
    application_id: {
      type: "integer",
    },
    license_id: {
      type: "integer",
    },
    owner_id: {
      type: "integer",
    },
    available_locales: {
      type: "array",
      minItems: 1,
      items: {
        type: "object",
        properties: {
          id: {
            type: "integer",
          },
        },
      },
    },
  },
  required: ["application_id", "license_id", "owner_id", "available_locales"],
};
