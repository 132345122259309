import React, { FC, useEffect, useState } from "react";
import { useStore } from "@/Store";
import Api from "../../services/Api";
import Loader from "../Loader";
import AccessDeniedView from "@/views/AccessDeniedView";

export const KioUserLoader: FC<any> = ({ children }) => {
  const { state, setUser } = useStore();
  const [retry, setRetry] = useState<boolean>(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const isLoaded = state.user !== null;

  useEffect(() => {
    const getUserInfoContext = Api.getUserInfo();
    let retryTimeout: NodeJS.Timeout;
    getUserInfoContext.fetch().then(([user, error, source]) => {
      if (user) {
        setUser(user);
        return;
      }

      if (error) {
        //console.error("UserInfo:", error);
        if (!retry) {
          retryTimeout = setTimeout(() => setRetry(true), 1000);
        } else {
          // hack to make error boundary catch errors
          // https://github.com/facebook/react/issues/14981
          const err = source instanceof Error ? source : Error("Failed to load user");
          console.error(err);
          setIsUnauthorized(true);
        }
      }
    });
    return () => {
      getUserInfoContext.abort();
      clearTimeout(retryTimeout);
    };
  }, [setUser, retry]);
  if (isUnauthorized) return <AccessDeniedView />;
  return <>{isLoaded ? children : <Loader loadingText="views.userLoader.loadingUser" />}</>;
};

export default KioUserLoader;
