import useGlobalDefs from "@/hooks/useGlobalDefs";
import { useStore } from "@/Store";
import AccessDeniedView from "@/views/AccessDeniedView";
import { AdminRootView } from "@/views/admin/AdminRootView";
import CMSRootView from "@/views/cms/CMSRootView";
import DashboardView from "@/views/DashboardView";
import PageNotFoundView from "@/views/PageNotFoundView";
import React, { FC, useEffect } from "react";
import { Route, Routes as Switch } from "react-router-dom";

const RootView: FC = () => {
  const { globalDefs } = useGlobalDefs();
  const { state, setGlobalSchemaDefinitions } = useStore();
  document.title = "Kulturio Admin";

  useEffect(() => {
    setGlobalSchemaDefinitions(globalDefs);
  }, [globalDefs, setGlobalSchemaDefinitions]);

  return (
    <Switch>
      <Route path="/dashboard" element={<DashboardView />} />

      <Route path="/admin/*" element={!state?.user?.is_admin ? <AccessDeniedView /> : <AdminRootView />} />

      <Route path="/*" element={<CMSRootView />} />

      <Route path="*" element={<PageNotFoundView />} />
    </Switch>
  );
};

export default RootView;
