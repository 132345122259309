import YouTubeLogo from "@/assets/img/logos/YouTube.png";
import EmbeddedContent from "@/components/EmbeddedContent";
import { AudioPreviewProps } from "@/components/MediaPreview/AudioPreview";
import React, { FC } from "react";

export const YoutubePreview: FC<AudioPreviewProps> = ({ identifier, title }) => {
  return (
    <EmbeddedContent
      src={`https://youtube.com/embed/${identifier}`}
      title={title || "YouTube player"}
      allow="fullscreen; picture-in-picture"
      allowFullScreen
      invalid={!identifier}
      fallbackImageSrc={YouTubeLogo}
    />
  );
};

export default YoutubePreview;
