import SideMenu, { SideMenuDef } from "@/components/SideMenu/SideMenu";
import { MenuLoader } from "@/framework/MenuLoader";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useLoadingState } from "@/hooks/useLoadingState";
import { groupBy } from "@/utils/arrays";
import { sortString } from "@/utils/sort";
import React, { FC, useEffect } from "react";
import ApplicationInstance from "../../declarations/models/ApplicationInstance";
import Api from "../../services/Api";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import i18next from "i18next";

export const MainMenu: FC = () => {
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const [menus, setMenus] = useAsyncSafeState<Array<SideMenuDef>>([]);
  const selectedLocale = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);

  useEffect(() => {
    startLoading();
    const getInstances = Api.getAllInstances();
    getInstances
      .fetchDirect(null)
      .then((page) => (page?.items || []) as Array<ApplicationInstance>)
      .then((instances) =>
        groupBy(instances, (instance) => getLocalizedString(instance.owner_name) || "?").map((group) => ({
          label: group.key,
          items: group.items.map((instance) => ({
            label: instance.name || "?",
            path: `../${instance.path}${instance.default_action_params}`,
          })),
        }))
      )
      .then((groupedMenuData) => groupedMenuData.sort((a, b) => sortString(a.label, b.label)))
      .then(setMenus)
      .finally(stopLoading);
    return getInstances.abort;
  }, [startLoading, stopLoading, setMenus]);

  if (isLoading) {
    return <MenuLoader numberOfMenus={2} numberOfItems={2} />;
  }

  return <SideMenu menus={menus} showDocumentSearch />;
};

export default MainMenu;
