import kulturioRocket from "@/assets/img/logos/kulturio_rocket.svg";
import Styles from "@/assets/styles/Styles";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import GridSelectWidget from "@/framework/KioForm/widgets/GridSelectWidget";
import MainLayout from "@/framework/MainLayout";
import MainMenu from "@/framework/Menu/MainMenu";
import { useStore } from "@/Store";
import { EmailOutlined, OpenInNew } from "@mui/icons-material";
import { Box, Button, styled, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

const Container = styled("div")`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${Styles.Colors.THEME_PRIMARY};
  border-radius: 8px;
`;

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Row = styled("div")`
  display: flex;
  margin-top: 16px;
  height: 40px;
  gap: 16px;
`;

const buttonStyling = {
  textTransform: "none",
  color: "inherit",
  borderColor: "inherit",
  ":hover": {
    borderColor: "inherit",
    borderWidth: "2px",
  },
};

const Img = styled("img")`
  height: 138px;
  aspect-ratio: 1/1;
  margin: 48px;
`;

export const DashboardView: FC = () => {
  const { state } = useStore();
  const { t } = useTranslation("common");
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const name = state.user?.name || "";
  const label = !!name ? "views.dashboard.hello" : "views.dashboard.helloAnonymous";

  return (
    <MainLayout menuOpen={menuOpen} setMenuOpen={setMenuOpen} menuContent={<MainMenu />} hideBreadcrumbs>
      <BreadcrumbNode label="generic.dashboard" absolutePath="/dashboard" />
      <Column>
        <Box m={"32px 8px"}>
          <Typography variant="h4" component="h1">
            {t(label, { name })}
          </Typography>
        </Box>
        <Container>
          <Img src={kulturioRocket} alt={"Kulturio rocket logo"} />
          <Column>
            <Typography variant="caption" fontSize={"28px"}>
              {t("views.dashboard.welcome")}
            </Typography>
            <Typography whiteSpace={"pre-line"}>{t("views.dashboard.welcomeMessage")}</Typography>
            <Row>
              <Button
                variant={"outlined"}
                href={"https://akademi.ekultur.org/courses/kulturio"}
                target="_blank"
                startIcon={<OpenInNew />}
                sx={buttonStyling}
              >
                {t("views.dashboard.digitalGuidance")}
              </Button>
              <Button
                variant={"outlined"}
                href={"mailto:support@kulturit.no"}
                startIcon={<EmailOutlined />}
                sx={buttonStyling}
              >
                {t("views.dashboard.contactSupport")}
              </Button>
            </Row>
          </Column>
        </Container>
      </Column>
    </MainLayout>
  );
};

export default DashboardView;
