import React from "react";
import { BrowserRouter } from "react-router-dom";
import EkulturLoginHandler from "./framework/EkulturLoginHandler/EkulturLoginHandler";
import RootView from "./views/RootView";
import Messenger from "./framework/Messenger/Messenger";
import Settings from "./Settings";

const App = () => {
  Settings.validateSettings();

  return (
    <Messenger>
      <BrowserRouter>
        <EkulturLoginHandler>
          <RootView />
        </EkulturLoginHandler>
      </BrowserRouter>
    </Messenger>
  );
};

export default App;
