import { FC, useState } from "react";
import { WidgetProps } from "@rjsf/core";
import { Box, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { defaultFinderQueryParamsValues, FinderConfigurationQueryParams } from "@/views/cms/FinderView";
import { getQueryParams, toQueryString } from "@/utils/url";
import { KioFormContext } from "@/framework/KioForm/KioForm";

const paramKeys = Object.keys(defaultFinderQueryParamsValues);

function parse(queryString: string) {
  return getQueryParams<FinderConfigurationQueryParams>(defaultFinderQueryParamsValues, queryString);
}

const ParamInputContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridAutoRows: "1fr",
  gap: theme.spacing(1),
}));

const ParamsResultContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  "& .param-label": {
    color: theme.palette.text.primary,
  },
  "& .param-value": {
    color: theme.palette.primary.main,
  },
}));
const ActionQueryEditorWidget: FC<WidgetProps> = ({ onChange, value, formContext }) => {
  const { variant }: KioFormContext = formContext;
  const { t } = useTranslation("common");
  const [state, setState] = useState<Partial<FinderConfigurationQueryParams>>(parse(value));

  const onInputChange = (fieldName: string | number, value: string): void => {
    const setStateValue = {
      ...state,
      [fieldName]: value,
    };
    setState(setStateValue);
    onChange(toQueryString(setStateValue));
  };

  const mapFieldNameToInput = (fieldName: keyof FinderConfigurationQueryParams) => {
    return (
      <TextField
        key={fieldName}
        variant={variant}
        id={`query-editor-${fieldName}`}
        label={t(`components.actionQueryEditorWidget.label.${fieldName}`)}
        value={state[fieldName] || ""}
        onChange={(e) => {
          onInputChange(fieldName, e.target.value);
        }}
        fullWidth
      />
    );
  };

  return (
    <>
      <Box mb={1}>
        <Typography>{t("components.actionQueryEditorWidget.description")}</Typography>
      </Box>
      <ParamInputContainer>{paramKeys.map(mapFieldNameToInput)}</ParamInputContainer>
      <Box mt={2} mb={1}>
        <Typography variant="h5" component="div">
          {t("components.actionQueryEditorWidget.result")}:
        </Typography>
      </Box>
      <ParamsResultContainer>
        {toQueryString(state)
          .substring(1)
          .split("&")
          .map((param, i) => {
            const [paramLabel, paramValue] = param.split("=");
            return (
              <Typography
                key={param}
                color="textSecondary"
                variant="body2"
                component="span"
                style={{ textDecoration: !!paramValue ? "none" : "line-through" }}
              >
                {i === 0 ? "?" : "&"}
                <span className="param-label">{paramLabel}</span>=<span className="param-value">{paramValue}</span>
              </Typography>
            );
          })}
      </ParamsResultContainer>
    </>
  );
};

export default ActionQueryEditorWidget;
