import DataList, { DataListProps } from "@/components/DataList/DataList";
import { DataListRow, DataListRowInternal } from "@/components/DataList/DataListRow";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import AddMediaModal from "@/components/MediaSelector/AddMediaModal";
import { StyledDialog } from "@/components/MediaSelector/FocalPointModal";
import SearchField from "@/components/SearchField";
import ApplicationInstance from "@/declarations/models/ApplicationInstance";
import Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import Owner from "@/declarations/models/Owner";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import EditMediaView from "@/views/cms/EditorView/EditMediaView";
import { Add } from "@mui/icons-material";
import { Button, DialogActions, styled, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface MediaTypeManagerProps {
  mediaTypes: MediaType[];
  onSelect: (value: Media | Media[]) => void;
  drawerOpen?: boolean;
  instanceId?: number;
  ownerId?: number;
  selectMultiple?: boolean;
  selectedList?: string[];
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  height: "100%",
  width: "100%",
  padding: 0,

  "& .MuiChip-outlinedPrimary": {
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid",
  },
});

const SearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "40px",
  margin: "16px 16px -56px 16px",
  marginBottom: "-56px",
  zIndex: 2,
  width: "calc(100% - 295px)",
  gap: theme.spacing(2),
  "& .MuiFormControl-root": {
    minWidth: "40px",
  },
  "& .MuiInputBase-root": {
    height: "40px",
  },
}));

export const ModalWrapper = styled("div")({
  overflow: "hidden",
});

export const FullScreenDialog = styled(StyledDialog)`
  .MuiDialog-paperFullWidth {
    width: 100% !important;
    max-width: calc(100vw - 64px) !important;
    height: 100%;
    max-height: calc(100vh - 64px);
    overflow: hidden;
    .MuiAppBar-positionStatic {
      width: 100%;
    }
    .MuiDrawer-paperAnchorLeft {
      position: absolute;
    }
  }
`;

const getMedia = (
  media_types: MediaType[],
  owner_id: Owner["id"],
  applicationInstance: ApplicationInstance["id"],
  search?: string,
  order_by?: string,
  order_asc?: boolean
): DataListProps<Media>["getItems"] => {
  return async (page, page_size) =>
    Api.getAllMedia({
      page,
      page_size,
      media_types,
      owner_id,
      application_instance_id: applicationInstance,
      search,
      order_by,
      order_asc,
    }).fetchDirect({
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    });
};
const AddButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "white",
  height: "24px",
  paddingLeft: "6px",
  borderRadius: "12px",
  backgroundColor: theme.palette.primary.main,
}));

export const MediaTypeTabPanel: FC<MediaTypeManagerProps> = ({
  mediaTypes,
  onSelect,
  drawerOpen,
  instanceId,
  ownerId,
  selectMultiple,
  selectedList,
}) => {
  const { t } = useTranslation("common");

  const [addContentDialogOpen, setAddContentDialogOpen] = useState<boolean>(false);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState<number>(Date.now());
  const [selectedMediaTypes, setSelectedMediaTypes] = useState<MediaType[]>(mediaTypes);
  const [sortBy, setSortBy] = useState<string>("updated_at");
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [mediaId, setMediaId] = useState<number>();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [searchInput]);

  useEffect(() => {
    if (searchInput !== "") setSearchInput("");
  }, [drawerOpen]);

  const mapMediaToListItem = (media: Media): DataListRow => ({
    key: String(media.id),
    title: media.name || media.title || "*",
    chipContent: media.media_type ? t("mediaType." + media.media_type) : "",
    imageURL: media.thumbnail_override?.mediaId || media.thumbnail_src,
    updatedAt: media.updated_at,
    updatedBy: media.updated_by,
  });

  const isMedia = Boolean(
    mediaTypes.includes(MediaType.IMAGE) || mediaTypes.includes(MediaType.AUDIO) || mediaTypes.includes(MediaType.VIDEO)
  );

  const handleEdit = (media: Media) => {
    setMediaId(media.id);
    setEditModalOpen(true);
  };

  const closeModal = () => {
    if (!isDirty || window.confirm(t("generic.unsavedChangesMessage"))) setEditModalOpen(false);
  };

  const handleMediaChanged = () => {
    setLastFetchedTimestamp(Date.now());
    setAddContentDialogOpen(false);
  };

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    if (sortProp && sortProp !== sortBy) setSortBy(sortProp);
    if (sortDirection) setSortAscending(sortDirection === "asc");
    setLastFetchedTimestamp(Date.now());
  };

  useEffect(() => {
    if (!!mediaTypes && mediaTypes !== selectedMediaTypes) {
      if (isMedia) setSelectedMediaTypes(mediaTypes);
      else setSelectedMediaTypes([]);
      setLastFetchedTimestamp(Date.now());
    }
  }, [mediaTypes]);

  const handleChange = (value: any) => {
    setSelectedMediaTypes(value);
    setLastFetchedTimestamp(Date.now());
  };

  const getItems = useMemo(
    () =>
      instanceId
        ? getMedia(
            isMedia && selectedMediaTypes.length > 0 ? selectedMediaTypes : mediaTypes,
            undefined,
            instanceId,
            searchTerms,
            sortBy,
            sortAscending
          )
        : getMedia(
            isMedia && selectedMediaTypes.length > 0 ? selectedMediaTypes : mediaTypes,
            ownerId,
            undefined,
            searchTerms,
            sortBy,
            sortAscending
          ),
    [selectedMediaTypes, sortBy, sortAscending, searchTerms, ownerId, instanceId, isMedia, mediaTypes]
  );

  const addButton = () => (
    <AddButton>
      <Typography variant={"caption"} lineHeight={1}>
        {t("Legg til")}
      </Typography>
      <Add />
    </AddButton>
  );

  return (
    <Container>
      <SearchContainer>
        <SearchField value={searchInput} fullWidth onChange={setSearchInput} placeholder={t("search.searchMedia")} />
        {isMedia && mediaTypes.length > 1 && (
          <KioSelect
            multiple
            displayEmpty
            options={mediaTypes.map((mediaType) => ({
              value: mediaType,
              label: t("mediaType." + mediaType),
            }))}
            value={selectedMediaTypes}
            onChange={handleChange}
            isHeaderVariant
            renderValue={() => {
              const displayMediaTypes = selectedMediaTypes.length > 0 ? selectedMediaTypes : mediaTypes;
              return (
                <div>
                  <span>{t("components.MediaSelector.MediaSelectSelectedIndicator")} </span>
                  {displayMediaTypes.map((mediaType, key) => {
                    return (
                      <span style={{ textTransform: "lowercase" }} key={key}>
                        {(key > 0 ? ", " : "") + t("mediaType.plural." + mediaType)}
                      </span>
                    );
                  })}
                </div>
              );
            }}
          />
        )}
      </SearchContainer>
      <DataList
        mapperFn={mapMediaToListItem}
        getItems={getItems}
        onItemClick={selectMultiple ? undefined : onSelect}
        onEditItem={handleEdit}
        handleOnItemsChanged={handleOnItemsChanged}
        hideUpdated={true}
        altUpdatedAt={true}
        denseOptions
        defaultViewType={ViewType.LIST}
        externalDataChanged={lastFetchedTimestamp}
        initialSortOption={2}
        createNewButton={{
          onCreateNew: () => setAddContentDialogOpen((o) => !o),
          buttonLabel:
            ([MediaType.IMAGE, MediaType.VIDEO, MediaType.AUDIO, MediaType.DOCUMENT].includes(mediaTypes[0])
              ? t("components.MediaSelector.upload")
              : t("components.MediaSelector.add")) || "",
        }}
        resetPageDeps={[searchTerms]}
        disableSelection={!selectMultiple}
        selectedItems={selectedList}
        customBatchButtons={[
          {
            tooltip: t("components.list.header.addSelected"),
            onClickAll: (items: DataListRowInternal<Media>[]) => onSelect(items.map((i) => i.sourceItem)),
            icon: addButton(),
            displayCondition: () => !!selectMultiple,
          },
        ]}
      />
      <AddMediaModal
        mediaTypes={mediaTypes}
        open={addContentDialogOpen}
        onCancel={() => setAddContentDialogOpen(false)}
        onMediaSaved={handleMediaChanged}
        ownerId={ownerId}
        instanceId={instanceId}
      />
      <FullScreenDialog maxWidth={"xl"} fullWidth open={editModalOpen} scroll={"paper"}>
        <ModalWrapper>
          <EditMediaView mediaIdProp={mediaId} modalEditor disablePreview isDirty={isDirty} setIsDirty={setIsDirty} />
        </ModalWrapper>
        <DialogActions>
          <Button type="button" color="primary" variant="contained" onClick={closeModal}>
            {t("generic.close")}
          </Button>
        </DialogActions>
      </FullScreenDialog>
    </Container>
  );
};

export default MediaTypeTabPanel;
