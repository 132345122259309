import { FC, memo, useEffect, useMemo } from "react";
import { useStore } from "@/Store";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "@/declarations/Breadcrumb";
import { useLocation } from "react-router-dom";

export interface BreadcrumbProps {
  label: string;
  absolutePath?: string;
}

/**
 * This component represents one node in the Breadcrumb-path, rendered by the "ApplicationBreadcrumbs"-component.
 *
 * This component has no DOM, but it cannot be a hook because of the React's render cycle.
 * React renders components depth-first, and using a hook will then render the breadcrumbs in reverse order.
 * In addition, a hook wil persist the state across renders,
 * which will cause breadcrumbs to be rendered in an unpredictable way.
 * By using a component the right way, we can ensure the state is correctly updated at the correct time,
 * using React's render cycle to our advantage.
 *
 * Using this component has one requirement:
 * It <b>MUST</b> be rendered BEFORE any child-components render another BreadcrumbNode
 *
 * @param label The label to display
 * @param absolutePath The absolute path (relative to the origin) to navigate to when clicking the breadcrumb
 * @param relativePath The relative path (relative to the current path) to navigate to when clicking the breadcrumb
 * @constructor
 */
const BreadcrumbNode: FC<BreadcrumbProps> = ({ label, absolutePath }) => {
  const { pushBreadcrumb, popBreadcrumb } = useStore();
  const { pathname } = useLocation();
  const { t } = useTranslation("common");

  const breadcrumb = useMemo<Breadcrumb>(
    () => ({
      label: t(label || "?"),
      path: absolutePath != null ? absolutePath : pathname,
    }),
    [t, label, pathname, absolutePath]
  );

  useEffect(() => {
    pushBreadcrumb(breadcrumb);
    return () => popBreadcrumb();
  }, [breadcrumb, pushBreadcrumb, popBreadcrumb]);

  return null;
};

export default memo(BreadcrumbNode);
