import BreadcrumbNode from "@/components/BreadcrumbNode";
import MediaSelector from "@/components/MediaSelector/MediaSelector";
import MainLayout from "@/framework/MainLayout";
import AdminMenu from "@/framework/Menu/AdminMenu";
import Settings from "@/Settings";
import { ThemeProvider, unstable_createMuiStrictModeTheme } from "@mui/material";
import React, { FC, useState } from "react";

const admin_theme = unstable_createMuiStrictModeTheme(Settings.ADMIN_THEME_OPTIONS);

const AdminView: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [mediaSelectorOpen, setMediaSelectorOpen] = useState<boolean>(false);

  return (
    <ThemeProvider theme={admin_theme}>
      <BreadcrumbNode label="breadcrumbs.admin" absolutePath="/admin" />
      <MainLayout
        menuOpen={!mediaSelectorOpen && menuOpen}
        setMenuOpen={setMenuOpen}
        menuContent={<AdminMenu />}
        hideBreadcrumbs
      >
        <MediaSelector open={mediaSelectorOpen} setOpen={setMediaSelectorOpen}>
          {children}
        </MediaSelector>
      </MainLayout>
    </ThemeProvider>
  );
};

export default AdminView;
