import { getLanguageCode } from "@/utils/obj";
import React, { FC } from "react";
import { JSONSchema7 } from "json-schema";
import { UiSchema } from "@rjsf/core";
import KioForm from "@/framework/KioForm/KioForm";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Styles from "@/assets/styles/Styles";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import Nuke from "@/components/Nuke";
import i18next from "i18next";

export interface SchemaPreviewProps {
  schema: JSONSchema7;
  uiSchema: UiSchema;
}

const PreviewContainer = styled("div")(({ theme }) => ({
  borderLeft: `2px solid ${Styles.Colors.PREVIEW_BORDER_COLOR}`,
  background: `${Styles.Colors.FORM_BACKGROUND_COLOR}`,
  width: "100%",
  height: "100%",
  overflow: "auto",
}));

const noop = () => {};

export const SchemaPreview: FC<SchemaPreviewProps> = ({ schema, uiSchema }) => {
  const { t } = useTranslation("common");
  if (!schema) {
    return (
      <PreviewContainer>
        <Nuke errorText={t("views.admin.schemaPreview.missingSchema")} />
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer>
      <ErrorBoundary
        FallbackComponent={(props: FallbackProps) => (
          <Nuke fallbackProps={props} errorText={t("views.admin.schemaPreview.invalidSchema")} />
        )}
      >
        <KioForm
          selectedLocale={getLanguageCode(i18next.language)}
          schema={schema}
          uiSchema={uiSchema}
          onChange={noop}
          onSubmit={noop}
        >
          <span />
        </KioForm>
      </ErrorBoundary>
    </PreviewContainer>
  );
};

export default SchemaPreview;
