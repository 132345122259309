import GridSelectWidget from "@/framework/KioForm/widgets/GridSelectWidget";
import KioTextareaWidget from "./KioTextareaWidget";
import EntityAutocompleteWidget from "./EntityAutocompleteWidget";
import JsonEditorWidget from "./JsonEditorWidget";
import ActionQueryEditorWidget from "./ActionQueryEditorWidget";
import TextAreaWysiwygWidget from "./TextAreaWysiwygWidget";
import SwitchWidget from "./SwitchWidget";
import { SelectWidget } from "./SelectWidget";
import { Widget } from "@rjsf/core";
import KioTextWidget from "@/framework/KioForm/widgets/KioTextWidget";
import KioDateTimeWidget from "./KioDateTimeWidget";
import MuiIconWidget from "@/framework/KioForm/widgets/MuiIconWidget";
import HelperTextWidget from "./HelperTextWidget";
import KioRangeWidget from "@/framework/KioForm/widgets/KioRangeWiget";
import ColorWidget from "@/framework/KioForm/widgets/ColorWidget";

const Widgets: { [widgetName: string]: Widget } = {
  JsonEditorWidget: JsonEditorWidget,
  EntityAutocompleteWidget: EntityAutocompleteWidget,
  ActionQueryEditorWidget: ActionQueryEditorWidget,
  TextAreaWysiwygWidget: TextAreaWysiwygWidget,
  SwitchWidget: SwitchWidget,
  SelectWidget: SelectWidget,
  TextWidget: KioTextWidget,
  TextareaWidget: KioTextareaWidget,
  DateTimeWidget: KioDateTimeWidget,
  MuiIconWidget: MuiIconWidget,
  HelperTextWidget: HelperTextWidget,
  RangeWidget: KioRangeWidget,
  ColorWidget: ColorWidget,
  GridSelectWidget: GridSelectWidget,
};

const WhiteListedWidgets: string[] = [
  "alt-date-time",
  "text",
  "radio",
  "select",
  "textarea",
  "password",
  "color",
  "up-down",
  "range",
  "url",
  "hidden",
  "file",
  "email",
  "date",
  "date-time",
  "checkbox",
  "checkboxes",
  "alt-date",
];

export { Widgets, WhiteListedWidgets };
