import React, { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "@/Store";
import ApplicationInstance from "../../declarations/models/ApplicationInstance";
import Api from "../../services/Api";
import Loader from "../../framework/Loader";
import PageNotFoundView from "../PageNotFoundView";
import BreadcrumbNode from "../../components/BreadcrumbNode";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { ErrorResponse } from "@/declarations/models/ErrorResponse";
import { getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import i18next from "i18next";

export const ApplicationInstanceContextLoader: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { ownerSlug, instanceSlug } = useParams<{ ownerSlug: string; instanceSlug: string }>();
  const { setCmsContextInstance } = useStore();
  const [loading, setLoading] = useAsyncSafeState<boolean>(true);
  const [error, setError] = useAsyncSafeState<ErrorResponse | string | null>(null);
  const [instance, setInstance] = useAsyncSafeState<ApplicationInstance | null>();
  const selectedLocale = getLanguageCode(i18next.language);
  const getLocalizedString = resolvePotentiallyLocalizedString(selectedLocale);
  const location = useLocation();

  useEffect(() => {
    // Load the application instance
    if (!ownerSlug || !instanceSlug) return;
    setLoading(true);
    const getInstance = Api.getInstanceBySlugs(ownerSlug, instanceSlug);
    getInstance
      .fetch()
      .then(([data, error, source]) => {
        setInstance(data);
        if (error) {
          setError(error);
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
    return getInstance.abort;
  }, [ownerSlug, instanceSlug]);

  useEffect(() => {
    // Use this ApplicationInstance as the current context
    if (!instance) return;
    setCmsContextInstance(instance);
    return () => setCmsContextInstance(null);
  }, [instance, setCmsContextInstance]);

  if ((loading || !instance) && !error) {
    return <Loader loadingText="views.cms.loadingContent" />;
  }

  if (!!error) {
    return <PageNotFoundView />;
  }

  return (
    <>
      <BreadcrumbNode
        label={`${instance?.name} / ${getLocalizedString(instance?.owner_name)}`}
        absolutePath={`/${ownerSlug}/${instanceSlug}${
          location.search ||
          ((location?.state as { [key: string]: any })?.["search"] as string) ||
          instance?.default_action_params ||
          ""
        }`}
      />
      {children}
    </>
  );
};

export default ApplicationInstanceContextLoader;
