import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Locale from "../declarations/models/Locale";
import Api from "../services/Api";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import { useTranslation } from "react-i18next";
import { sortString } from "@/utils/sort";

export interface LocaleSelectorButtonProps {
  filterAvailableLocales?: Array<number | string>;
  onSelectionChanged: (locale: Locale | null) => void;
  value: string;
}

export const LocaleSelectorButton: FC<LocaleSelectorButtonProps> = memo(
  ({ onSelectionChanged, filterAvailableLocales, value }) => {
    const { t } = useTranslation("common");
    const onSelectionChangeRef = useRef(onSelectionChanged);
    onSelectionChangeRef.current = onSelectionChanged;

    const [selectedLocaleObj, setSelectedLocaleObj] = useState<Locale | null>(null);
    const selectedLocaleIdRef = useRef<number | null>(selectedLocaleObj?.id ?? null);
    selectedLocaleIdRef.current = selectedLocaleObj?.id ?? null;
    const [allLocales, setAllLocales] = useState<Locale[]>([]);

    const selectableLocales = useMemo<Array<Locale>>(() => {
      return !!filterAvailableLocales
        ? allLocales.filter(
            (l) => filterAvailableLocales.includes(l.language_code) || filterAvailableLocales.includes(l.id!)
          )
        : allLocales;
    }, [allLocales, filterAvailableLocales]);

    const handleLanguageChange = useCallback(
      (locale: Locale | null) => {
        if (selectedLocaleIdRef.current !== locale?.id) {
          setSelectedLocaleObj(locale);
          onSelectionChangeRef.current?.(locale);
        }
      },
      [setSelectedLocaleObj]
    );

    useEffect(() => {
      // Load all available locales on init
      const getLocales = Api.getAllLocales();
      getLocales.fetchDirect({ items: [] }).then(({ items }) => setAllLocales(items));
      return getLocales.abort;
    }, []);

    useEffect(() => {
      // Update selected locale if options change
      if (!!selectableLocales.length) {
        const preferredLocale =
          value && selectableLocales.find((l) => l.language_code.toLocaleLowerCase() === value.toLocaleLowerCase());
        if (preferredLocale) {
          handleLanguageChange(preferredLocale);
        } else if (
          !selectedLocaleIdRef.current ||
          !selectableLocales.find((l) => l.id === selectedLocaleIdRef.current)
        ) {
          handleLanguageChange(selectableLocales[0]);
        }
      } else {
        handleLanguageChange(null);
      }
    }, [selectableLocales, handleLanguageChange, value]);

    const getLabel = (langCode: string) => {
      const codeArray = langCode.split("-");
      if (codeArray.length === 1) return t(`languages.${langCode}`);
      else if (codeArray.length > 1) return `${t(`languages.${codeArray[0]}`)} (${codeArray.slice(1).join("-")})`;
      return langCode;
    };

    selectableLocales.sort((a, b) => {
      return sortString(getLabel(a?.language_code as string), getLabel(b?.language_code as string));
    });
    return (
      <span style={{ minWidth: "120px" }}>
        <KioSelect
          onChange={handleLanguageChange}
          options={selectableLocales.map((locale) => ({
            value: locale,
            label: getLabel(locale.language_code),
          }))}
          value={selectedLocaleObj}
          isHeaderVariant
        />
      </span>
    );
  }
);

export default LocaleSelectorButton;
