import React, { FC, useEffect, useState } from "react";
import { AdminResourcePath } from "@/declarations/AdminResourcePath";
import { DataListRow } from "@/components/DataList/DataListRow";
import Locale from "../../declarations/models/Locale";
import { schema } from "@/declarations/schemas/locale/schema";
import { uiSchema } from "@/declarations/schemas/locale/uiSchema";
import { DataListProps } from "@/components/DataList/DataList";
import Api from "../../services/Api";
import { CreateOrEditProps } from "@/components/CreateOrEdit";
import { RequestContext } from "@/declarations/RequestContext";
import CreateOrEditRoutes from "./CreateOrEditRoutes";
import BreadcrumbNode from "../../components/BreadcrumbNode";
import { useTranslation } from "react-i18next";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import { SearchProp } from "@/components/DataList/ListHeader/DataListHeader";
import { useDebounce } from "@/hooks/useDebounce";

const mapperFn = (localeItem: Locale): DataListRow => ({
  key: String(localeItem.id),
  title: localeItem.name,
  chipContent: localeItem.language_code,
  infoText: `ID: ${localeItem.id}`,
  updatedAt: localeItem.updated_at,
  updatedBy: localeItem.updated_by,
});

const getLocale: CreateOrEditProps<Locale>["getFormData"] = (id: number): RequestContext<Locale> => {
  return Api.getOneLocale(id);
};

const updateLocale: CreateOrEditProps<Locale>["onSubmit"] = (locale: Locale): RequestContext<Locale> => {
  return Api.updateLocale(locale);
};

const createLocale: CreateOrEditProps<Locale>["onSubmit"] = (locale: Locale): RequestContext<Locale> => {
  return Api.createLocale(locale);
};

export const LocaleView: FC = () => {
  const { t } = useTranslation("common");

  const [sortBy, setSortBy] = useState<string>("name");
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(Date.now());
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [searchInput]);

  const handleOnItemsChanged = async (sortProp?: string, sortDirection?: string) => {
    if (sortProp && sortProp !== sortBy) setSortBy(sortProp);
    if (sortDirection) setSortAscending(sortDirection === "asc");

    setLastFetchedTimestamp(Date.now());
  };

  const getLocales: DataListProps<Locale>["getItems"] = (page, page_size) => {
    return Api.getAllLocales({
      page,
      page_size,
      sort_by: sortBy,
      order_asc: sortAscending,
      search: searchTerms,
    }).fetchDirect({
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    });
  };

  return (
    <>
      <BreadcrumbNode label={`adminResourcePath.${AdminResourcePath.LOCALE}`} />
      <CreateOrEditRoutes
        schema={schema}
        uiSchema={uiSchema}
        goBackOnSubmit
        createFormData={createLocale}
        updateFormData={updateLocale}
        getFormData={getLocale}
        listProps={{
          listTitle: t("schemaTypes.locale_plural"),
          mapperFn,
          getItems: getLocales,
          hideImage: true,
          defaultViewType: ViewType.LIST,
          disableViewTypeSelection: true,
          handleOnItemsChanged: handleOnItemsChanged,
          externalDataChanged: lastFetchedTimestamp,
          resetPageDeps: [searchTerms],
          searchProp: {
            query: searchInput,
            updateQuery: setSearchInput,
            placeholder: t("search.inputLabel"),
          } as SearchProp,
        }}
      />
    </>
  );
};

export default LocaleView;
