import React, { FC, ReactNode } from "react";
import { styled } from "@mui/material";

export interface HoverToggleProps {
  children: ReactNode;
  toggle: ReactNode;
}

const Container = styled("span")({
  margin: "0 8px 8px 0",
  position: "relative",
  "&:focus": {
    outline: "none",
    border: "1px solid black",
  },
  "& .hidden, &:hover .visible, &:focus .visible": {
    visibility: "hidden",
  },
  "&:hover .hidden, &:focus .hidden": {
    visibility: "visible",
  },
  "& .hidden": {
    position: "absolute",
    inset: 0,
  },
});

export const HoverToggle: FC<HoverToggleProps> = ({ children, toggle }) => {
  return (
    <Container tabIndex={0}>
      <span className="visible">{children}</span>
      <span className="hidden" tabIndex={-1}>
        {toggle}
      </span>
    </Container>
  );
};

export default HoverToggle;
