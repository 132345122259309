export const uiSchema = {
  "ui:title": "schemaTypes.schema",
  id: {
    "ui:title": "generic.id",
  },
  version: {
    "ui:title": "generic.version",
  },
  created_at: {
    "ui:title": "generic.created_at",
    "ui:widget": "DateTimeWidget",
    "ui:colspan": 6,
  },
  created_by: {
    "ui:title": "generic.created_by",
    "ui:colspan": 6,
  },
  updated_at: {
    "ui:title": "generic.updated_at",
    "ui:widget": "DateTimeWidget",
    "ui:colspan": 6,
  },
  updated_by: {
    "ui:title": "generic.updated_by",
    "ui:colspan": 6,
  },
  json_schema: {
    "ui:title": "generic.json_schema",
    "ui:widget": "JsonEditorWidget",
    "ui:options": {
      type: "schema",
    },
  },
  ui_schema: {
    "ui:title": "generic.ui_schema",
    "ui:widget": "JsonEditorWidget",
    "ui:options": {
      type: "uiSchema",
    },
  },
  name: {
    "ui:title": "generic.name",
  },
  description: {
    "ui:title": "generic.description",
  },
  application_id: {
    "ui:title": "generic.application",
    "ui:widget": "EntityAutocompleteWidget",
    "ui:options": {
      type: "Application",
      valueField: "id",
      displayField: "name",
    },
  },
  searchable_fields: {
    "ui:title": "generic.searchable_fields",
    "ui:description":
      'Use jsonpath syntax, e.g. "$.field" or "$.myArray[*].nestedField". The first 3 fields that are "include_in_hit" will be presented when searching in the finder',
    items: {
      "ui:options": {
        titleProp: "jsonpath",
        compact: "true",
      },
      include_in_hit: {
        "ui:title": "generic.include_in_hit",
        "ui:widget": "SwitchWidget",
      },
      jsonpath: {
        "ui:title": "generic.jsonpath",
      },
    },
  },
  internal_title_field_json_path: {
    "ui:placeholder": "$.myField.internal_title",
    "ui:title": "generic.internal_title_field_json_path",
  },
  title_field_json_path: {
    "ui:placeholder": "$.myField.title",
    "ui:title": "generic.title_field_json_path",
  },
  description_field_json_path: {
    "ui:placeholder": "$.myField.description",
    "ui:title": "generic.description_field_json_path",
  },
  image_field_json_path: {
    "ui:placeholder": "$.myField.image",
    "ui:title": "generic.image_field_json_path",
  },
  preview_url: {
    "ui:title": "generic.preview_url",
  },
  description_field_override: {
    "ui:placeholder": `\${content.someKey.someList.length} £{db.someTranslationKey} sometext`,
    "ui:title": "generic.description_field_override",
  },
};
