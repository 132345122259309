import CustomIcons from "@/components/CustomIcons";
import { Icon, styled } from "@mui/material";
import React, { FC, memo, ReactNode } from "react";

export interface IconProps {
  children: string | ReactNode;
  onClick?: () => void;
  /** Rotate in degrees **/
  rotate?: string;
  className?: string;
}

const toSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (letter, index) => (index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`));
};

const Container = styled("div")<Omit<IconProps, "icon">>`
  transform: rotate(${(props) => props?.rotate || 0}deg);
  line-height: 0;
  svg {
    fill: currentColor;
  }
`;

export const MuiIcon: FC<IconProps> = ({ children, className, ...rest }) => {
  /** Custom Icon */
  if (typeof children === "string") {
    if (children.includes("Custom:")) {
      return (
        <Container {...rest}>
          <CustomIcons name={children.substring(8, children.length)} />
        </Container>
      );
    }
    return (
      <Container {...rest}>
        <Icon className={className || ""}>{toSnakeCase(children)}</Icon>
      </Container>
    );
  }
  return <Container {...rest}>{children}</Container>;
};

export default memo(MuiIcon);
