import React, { ChangeEvent, FC, useState } from "react";
import { Button, CircularProgress, styled, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLoadingState } from "@/hooks/useLoadingState";
import Api from "@/services/Api";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@/hooks/useDebounce";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "flex end",
  gap: theme.spacing(2),
  margin: theme.spacing(2),
}));

export const CreateTranslationView: FC = () => {
  const { t } = useTranslation("common");
  const history = useNavigate();
  const [value, setValue] = useState<string>("");
  const { isLoading, startLoading, stopLoading } = useLoadingState();

  const [existsError, setExistsError] = useState<boolean>(false);
  const isError = !value || existsError;

  const validate = useDebounce(500, async (updatedValue?: string) => {
    if (!updatedValue) return;
    startLoading();
    const [, error] = await Api.validateTranslationKey(updatedValue).fetch();
    stopLoading();
    if (error) {
      setExistsError(true);
    } else {
      setExistsError(false);
    }
  });

  const handleValueChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value || "";
    setValue(updatedValue);
    if (!updatedValue) {
      return;
    }
    await validate(updatedValue);
  };

  const handleSaveKeyClicked = async () => {
    await Api.createTranslationKey(value).fetchDirect(null);
    history("/admin/translation");
  };

  return (
    <Container>
      <TextField
        autoFocus
        label={t("generic.key")}
        value={value}
        error={isError}
        helperText={t(
          (!value ? "generic.required" : "") || (existsError ? "views.admin.translations.alreadyExists" : "")
        )}
        onChange={handleValueChanged}
        InputProps={{
          endAdornment: isLoading ? <CircularProgress variant="indeterminate" color="secondary" size="16px" /> : null,
        }}
        fullWidth
        required
      />
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleSaveKeyClicked}
        disabled={isLoading || isError}
        sx={{ marginLeft: "auto" }}
      >
        {t("generic.save")}
      </Button>
    </Container>
  );
};

export default CreateTranslationView;
